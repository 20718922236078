import { Badge,Select,Modal,Popconfirm } from 'antd';
import React, { Component,useState,useEffect }  from 'react';
import Icon,{ ExclamationCircleOutlined } from '@ant-design/icons';

import './index.css';
const { confirm } = Modal;

export default  function Index(props)  {
  const {columsName, text, onChangFun} = props;

  const listCol = (field) => {
    var valueEnum = columsName[field]['valueEnum'];
    var value = text[field];
    var allvalues = [];
    Object.keys(valueEnum).map((v, k) => (
      allvalues.push({label:valueEnum[v]['text'], value:v})
    ))

    return <Select key={text.id+field}
      className="def-select-noborder"
      // bordered={false}
      size='small'
      value={{label:valueEnum[value]['text'], value:value}}
      // defaultValue={{label:valueEnum[value]['text'], value:value}}
      onChange={(e)=>{
        if(e != value){
          confirm({
            title: '确定修改“'+text.customerName+'”为“'+valueEnum[e]['text']+'”吗?',
            icon: <ExclamationCircleOutlined />,
            // content: '',
            centered:true,
            onOk() {
              onChangFun[field](text.id, e);
            },
            onCancel() {
            },
          });
        }
        
      }}
      options={allvalues}
    />
  }


  return (
    <div key="id" style={{ display: 'flex', justifyContent: 'space-around' }}>
    {
      Object.keys(columsName).map((field, index) => (
        // console.log(field, text[field], columsName[field]),
        // console.log(columsName[field]['valueEnum'][text[field]]),
        // console.log(typeof(text[field])),
        <div key={index} style={{width:columsName[field]['width']}}>
          <div style={{ color: '#00000073' }}>{columsName[field]['title']}</div>
          <div style={{ color: '#000000D9',marginTop:6,marginRight:6,wordWrap:"break-word"}}>
            {
              columsName[field]['valueEnum'] ? 
              (
                columsName[field]['valueEnum'][text[field]] ?
                (
                  columsName[field]['valueEnum'][text[field]]['status'] ?
                  <Badge status={columsName[field]['valueEnum'][text[field]]['status']} text={columsName[field]['valueEnum'][text[field]]['text']} /> :
                  (
                    columsName[field]['edit'] ? 
                    listCol(field) :
                    columsName[field]['valueEnum'][text[field]]['text']
                  )
                ) :
                ""
              ) : 
              (
                columsName[field]['render'] ? columsName[field]['render'](text[field]) : text[field]
              )
            }
          </div>
        </div>
      ))
    }
  </div>
);
}
