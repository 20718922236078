import http from "../../request/http";
import CryptoJs from 'crypto-js';

// 注册
export const userRegister = (data) =>{
    const pwd = CryptoJs.MD5(data.password).toString();
    data.password = pwd;
    return http ({
        method:'POST',
        url:'user/register',
        data
    })
}
// 登录
export const userLogin = (data) =>{
    const pwd = CryptoJs.MD5(data.password).toString();
    data.password = pwd;
    // data.type = "account";
    return http ({
        method:'POST',
        url:'user/login',
        data
    })
}
// 获取用户信息
export const getUserinfo = (data) =>{
    return http ({
        method:'POST',
        url:'user/info',
        // data
    })
}
// 完善用户资料
export const editUserinfo = (data) =>{
    return http ({
        method:'POST',
        url:'user/perfect/info',
        data
    })
}
// 重置密码
export const ResetPassword = (data) =>{
    const pwd = CryptoJs.MD5(data.password).toString();
    data.password = pwd;
    return http ({
        method:'POST',
        url:'user/reset/password',
        data
    })
}
// 用户登出
export const userLoginout = (data) =>{
    return http ({
        method:'POST',
        url:'user/logout',
        data
    })
}
// 注册获取手机验证码
export const smsRegSend = (data) =>{
    return http ({
        method:'POST',
        url:'sms/register/send',
        data
    })
}
// 修改密码获取手机验证码
export const smsResetPwdSend = (data) =>{
    return http ({
        method:'POST',
        url:'sms/reset/password/send',
        data
    })
}

