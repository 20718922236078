import Icon,{ MenuOutlined,LoadingOutlined } from '@ant-design/icons';
import { Layout,Input,Select,Col, Row, Card,Button ,Tabs,Form,Modal,List, message,Switch,Tooltip } from 'antd';
import React, { useState,useRef,useEffect }  from 'react';
// import Zhilian from '../components/Zhilian';
import Touping from '../components/Touping';
import Updateparam from '../components/Updateparam';
import ParamDetail from '../components/ParamDetail';
// import Material from './components/material.jsx';
// import Addmaterial from './components/addmaterial.jsx';
import Synmaterial from './components/synmaterial.jsx';
import Applist from './components/applist.jsx';
import { ScreenIcon, DownloadIcon, AppIcon,EditIcon,ResourceIcon } from '../components/icons';
import checkLogin from '../../request/checkLogin.js'
import {getApiData} from '../../request/api.js'
import { BcroomAppUrl, BcPhoneList,NodeCityOptions,customerOptions,baseinfoUpdate,getDeviceInfo,phoneSyncMaterial,directConnect_stay,projectScreen} from './service';
import QRCode from 'qrcode.react';
import { ProList } from '@ant-design/pro-components';
import { buttonBlur,customTabinkbar } from '../../utils/global.js';
import { encrypt,decrypt,localStorageSet,localStorageGet,Sessionid,postMessage } from '../../utils/global.js';

import './index.css';
// import { render } from '@testing-library/react';

const { Header, Content, Sider } = Layout;
const { TabPane } = Tabs;
const { confirm } = Modal;

let waitFlushInterHandler = undefined;
let waitState = false;
let occupyDevices = [];

const clientHeight = document.body.clientHeight;
const clientWidth = document.body.clientWidth;

const allDeviceStatus = {
  1: {
    text: '空闲',
    status: 'Success',
  },
  2: {
    text: '占用中',
    status: 'Error',
  },
  4: {
    text: '离线',
    status: 'Default',
  },
  5: {
    text: '重启中',
    status: 'Default',
  },
  6: {
    text: '重置中',
    status: 'Default',
  },
};

var allDeviceStatus_select = [];
Object.keys(allDeviceStatus).map((v)=>{
  allDeviceStatus_select.push({value:v, label:allDeviceStatus[v].text})
});

var maxIndex = 2000;
var onetp = {show: false, id: 0, info: {}, zIndex:maxIndex};
var allToupingPhones = [onetp, onetp, onetp, onetp, onetp, onetp];

var curPhoneCount = 0;
var sessionid = "";

const App = props => {
  const actionRef_1 = useRef();
  const actionRef_2 = useRef();
  const actionRef_3 = useRef();
  const currentUserinfo = JSON.parse(decrypt(localStorageGet('UserInfo')));
  // console.log('-------stayroom---currentUserinfo-------', currentUserinfo, currentUserinfo.userType);
  // const [sessionid, setSessionid] = useState("");

  const [listDeviceStatus, setListDeviceStatus] = useState({});

  const [haveMaterialMenu, setHaveMaterialMenu] = useState(false);
  const [haveAppMenu, setHaveAppMenu] = useState(false);

  const [curTabPane, setCurTabPane] = useState(1);
  const [curClientid, setCurClientid] = useState("");
  const [curDeviceInfo, setCurDeviceInfo] = useState({});
  // 同步素材
  const [showModel,setShowModel] = useState(false);
  // 设备详情
  const [showParamDetail,setShowParamDetail] = useState(false);
  const close = () =>{
    setShowModel(false);
    setShowParamDetail(false);
  }

  const [showSelectClient, setShowSelectClient] = useState("none");
  const [showZhilianScreen, setZhilianScreenShow] = useState(false);
  const [connectModalOpen_bc, setConnectIsModalOpen] = useState(false);
  const showConnectModal = () => {
    setConnectIsModalOpen(true);
  };
  const handleConnectCancel = () => {
    setConnectIsModalOpen(false);
  };
  const [search_cloudPhoneNum, setSearchCloudPhoneNum] = useState(null);
  const [search_country, setSearchCourtry] = useState(null);
  const [search_deviceStatus, setSearchDeviceStatus] = useState(null);
  const onChang_cloudPhoneNum = (e) => {
    // console.log(e)
    setSearchCloudPhoneNum(e.target.value);
    // search_cloudPhoneNum = e.target.value;
  };
  const onChang_Courtry = (e) => {
    // console.log(e)
    setSearchCourtry(e);
    // search_country = e;
  };
  const onChang_DeviceStatus = (e) => {
    // console.log(e)
    setSearchDeviceStatus(e);
    // search_deviceStatus = e;
  };
  
  // 修改基本信息
  const [editModalOpen_bc, setIsModalOpen] = useState(false);
  const showModal = (item) => {
    // console.log('item------------',item)
    setCurDeviceInfo(item);
    form.setFieldValue("name", item.nodeName);
    form.setFieldValue("nodeGroup", item.nodeGroup);
    form.setFieldValue("remark", item.remark);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const editBasicInfo = (value) => {
    // console.log(value);
    value.nodeName = value.name;
    delete value.name;
    value.cloudPhoneNum = curDeviceInfo.cloudPhoneNum;
    try {
      baseinfoUpdate(value).then((res) => {
      // console.log('baseinfoUpdate-res-----------------', res);
      var flag = checkLogin(res.data)
      // console.log('flag: ', flag);
      if (flag) {
        actionRef_1.current.reload();
        message.success("修改成功");
        closeModal();
      }
      // else
      //   message.error(res.data.msg);
    });
    } catch (error) {
      // message.error(error);
    }
  }

  const [showCheckbox, setCheckboxShow] = useState(false);
  const [selectPhones, setSelectPhones] = useState([]);
  const [toupingPhones, setToupingPhones] = useState([]);
  const [toupingPhonesInfo, setToupingPhonesInfo] = useState({});

  const [allTouPingParams, setallTouPingParams] = useState([onetp,onetp,onetp,onetp,onetp,onetp]);

  const [form] = Form.useForm();

  const [countryList, setCountryList] = useState([]);
  const [clientList, setClientList] = useState([]);

  useEffect(() => {

    if(currentUserinfo.userType == 99){
      setShowSelectClient("block");
      setHaveMaterialMenu(true);
      setHaveAppMenu(true);
    }else{
      Object.keys(currentUserinfo.btnPerms).forEach((key)=>{
        if(key == 'stayroom:material:manage' && currentUserinfo.btnPerms['stayroom:material:manage'])
          setHaveMaterialMenu(true);
        else if(key == 'stayroom:app:manage' && currentUserinfo.btnPerms['stayroom:app:manage'])
          setHaveAppMenu(true);
      })
    }

    try {
      NodeCityOptions({}).then((res) => {
      // console.log('NodeCityOptions-res-----------------', res);
      var flag = checkLogin(res.data)
      // console.log('flag: ', flag);
      if (flag) {
        setCountryList(res.data.data)
      }
      // else
      //   message.error(res.data.msg);
    });
    } catch (error) {
      // message.error(error);
    }

    try {
      customerOptions({}).then((res) => {
      // console.log('customerOptions-res-----------------', res);
      var flag = checkLogin(res.data)
      // console.log('flag: ', flag);
      if (flag) {
        setClientList(res.data.data)
      }
      // else
      //   message.error(res.data.msg);
    });
    } catch (error) {
      // message.error(error);
    }

    // customTabinkbar();
  }, []);


  // useEffect(() => {
  //   console.log('showCheckbox-------', showCheckbox)
  //   if(!showCheckbox){
  //     allToupingPhones = [onetp, onetp, onetp, onetp, onetp, onetp];
  //   }
  // }, [showCheckbox]);

  const getDeviceList = async (params) =>{
    params.pageNo = params.current;
    delete params.current;
    if(curClientid)
      params.userId = curClientid;

    // console.log('search_cloudPhoneNum ----------- ', search_cloudPhoneNum);
    // console.log('search_country ----------- ', search_country);
    // console.log('search_deviceStatus ----------- ', search_deviceStatus);
    if(search_cloudPhoneNum)
      params.cloudPhoneNum = search_cloudPhoneNum;
    if(search_country)
      params.city = search_country;
    if(search_deviceStatus)
      params.deviceStatus = search_deviceStatus;

    var result = {};
    try {
      await BcPhoneList(params).then((res) => {
        // console.log('BcPhoneList-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          // console.log('222222222222222222222222');
          result = res.data.data;
          // console.log('result-----', result);
          return result;
        }
        else
          message.error(res.data.msg);
      });
    } catch (error) {
      message(error);
    }
    return result;
  }

  const [QRCodeUrl, setQRCodeUrl] = useState({domestic:"", oversea:""});
  const [QRCodeModalOpen, setQRCodeModalOpen] = useState(false);
  const showModal_QRCode = () => {
    try {
      BcroomAppUrl({}).then((res) => {
      // console.log('BcroomAppUrl-res-----------------', res);
      var flag = checkLogin(res.data)
      // console.log('flag: ', flag);
      if (flag) {
        setQRCodeUrl(res.data.data);
        setQRCodeModalOpen(true);
      }
      else
        message.error(res.data.msg);
    });
    } catch (error) {
      message.error(error);
      return {};
    }
  };
  const handleCancel_QRCode = () => {
    setQRCodeModalOpen(false);
  };

  const onChangClient = (e) => {
    // console.log(e);
    setCurClientid(e);
    if(curTabPane == 1){
      setSearchCloudPhoneNum(null);
      setSearchCourtry(null)
      setSearchDeviceStatus(null);
      actionRef_1.current.reload();
    } else if(curTabPane == 2){
      actionRef_2.current.reload();
    } else if(curTabPane == 3){
      actionRef_3.current.reload();
    }
  }

  const refresh = () => {
    setSearchCloudPhoneNum(null);
    setSearchCourtry(null)
    setSearchDeviceStatus(null);
    actionRef_1.current.reload();
  }

  const [exchangParam_bc, setExModalOpen] = useState(false);
  const [curCloudPhoneNum, setCurCloudPhoneNum] = useState("");
  const [deviceInfo,setDeviceInfo] = useState({});

  // 点击一键换参
  const onUpdateParam = async(id) => {
    try{
      await getDeviceInfo({cloudPhoneNum:id}).then((res) => {
        // console.log('getDeviceInfo---------', res);
        var flag = checkLogin(res.data)
        if (flag) {
          // console.log( '11111111111111111111');
          setDeviceInfo(res.data.data);
          setCurCloudPhoneNum(id);
          setExModalOpen(true);
        }
        return true;
      });
    }catch (error) {
      console.log(error)
      return true;
    }
  };

  // 获取直连/投屏云手机信息
  const getDirectInfo = async(id, deviceNum) => {
    var info = {};
    var traceInfo = {
      'corpkey': currentUserinfo.customerId ? currentUserinfo.customerId : curClientid,
      'userid': currentUserinfo.userName,
      'page': "stay",
      'sessionid': sessionid,
      'padcode': deviceNum,
    };
    try{
      await directConnect_stay({cloudPhoneNum:id}).then((res) => {
        // console.log('directConnect_stay---------', res);
        var flag = checkLogin(res.data)
        if (flag) {
          info = res.data.data;
          traceInfo['event'] = "SDK_DEVICE_APPLY_OK";
          postMessage("deviceresult","trace",traceInfo);
          return info;
        }
        else {
          // message.error(res.data.msg+" : "+id);
          traceInfo['event'] = "SDK_DEVICE_APPLY_ERROR";
          traceInfo['errorcode'] = res.data.code;
          traceInfo['errormsg'] = res.data.msg;
          postMessage("deviceresult","trace",traceInfo);
        }
      });
    }catch (error) {
      console.log(error)
    }
    return info;
  };

  const handleExCancel = () => {
    actionRef_1.current.reload();
    setExModalOpen(false);
  };


  const tabClicked = key => {
    for(var i=1; i<=3; i++)
    {
      var span = document.getElementById("tab_pane_"+i);
      var bar = document.getElementById("tab_bar_"+i);
      if(span){
        if(i == key){
          setCurTabPane(i);
          span.className = "tab_pane_active";
          bar.className = "cust_tab_bar_active";
          if(i == 1 && actionRef_1.current){
            actionRef_1.current.reload();
            setCheckboxShow(false);
            setSelectPhones([]);
          } else if(i == 2 && actionRef_2.current){
            actionRef_2.current.reload();
          } else if(i == 3 && actionRef_3.current){
            actionRef_3.current.reload();
          }
        }
        else{
          span.className = "tab_pane";
          bar.className = "cust_tab_bar";
        }
      }
    }

    customTabinkbar();
  };

  // 点击直连
  const onZhilian = async (id, deviceNum) => {
      if(!id)
        return;

      sessionid = Sessionid(4);
      postMessage("deviceresult","trace",{
        'corpkey': currentUserinfo.customerId ? currentUserinfo.customerId : curClientid,
        'userid': currentUserinfo.userName,
        'page': "stay",
        'sessionid': sessionid,
        'event': "SDK_DEVICE_APPLY_START",
        'padcode': deviceNum,
      });
    
      var info = await getDirectInfo(id, deviceNum);
      // console.log('--------info---------------',info);
      if(info.hasOwnProperty("cloudPhoneNum")){
        setZhilianScreenShow(true);
        var sinfo = {};
        sinfo[id] = info;
        allToupingPhones[0] = {
          id: id,
          show: true,
          info: sinfo,
          zIndex: 1000
        };
        setallTouPingParams([...allToupingPhones]);
        actionRef_1.current.reload();
      }
  };

  // 点击投屏
  const onTouping = (id) => {
    if(!id || !showCheckbox)
      return;

    var can = false;
    // console.log("allToupingPhones----11------", allToupingPhones)

    for(var i=0; i<6; i++){
      var item = allToupingPhones[i];
    // allToupingPhones.map(async(item, index)=>{
      if(item.id == 0){
        can = true;

        try{
          projectScreen({cloudPhoneNum:id}).then((res) => {
            // console.log('projectScreen---------', res);
            var flag = checkLogin(res.data)
            if (flag) {
              var info = {};
              maxIndex++;
              info[id] = res.data.data;
              allToupingPhones[i] = {
                id: id,
                show: true,
                info: info,
                zIndex: maxIndex
              };
              setallTouPingParams([...allToupingPhones]);
              actionRef_1.current.reload();
            }
            // else {
            //   message.error(res.data.msg+" : "+id);
            // }
          });
        }catch (error) {
          console.log(error)
        }
        // console.log( 'return')
        break;
      }
    }

    if(!can){
      Modal.warning({
        title: '提示',
        content: '最多投屏6台云手机',
        // maskStyle:{zIndex:9000},
        zIndex:9000,
        maskClosable:true
      });
    }

  };

  // 修改最大层index
  const updatemaxIndex = (topIndex) => {
    maxIndex = topIndex;
    // console.log(maxIndex)
  }

  // 关闭投屏手机
  const toupingClose = (id) => {
    // console.log('id----', id)

    var closenum = 0;
    allToupingPhones.map((item, index)=>{
      if(item.id == id){
        allToupingPhones[index] = onetp;
        setallTouPingParams([...allToupingPhones]);
        closenum++;
      } else if(item.id == 0){
        closenum++;
      }
    })

    if(closenum == 6){
      setCheckboxShow(false);
      setZhilianScreenShow(false);
    }
    actionRef_1.current.reload();
  }

  const onCheckPhone = (checkedValues) => {
    // console.log('checked = ', checkedValues, checkedValues.length);
    setSelectPhones(checkedValues);
    if(checkedValues.length == 2){
      confirm({
        title: '是否对选中云手机进行投屏？',
        icon: null,
        okText: '是',
        cancelText: '否',
        centered: true,
        width:280,
        onOk() {
          onTouping(checkedValues);
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
    }


    // console.log('selectPhones-------- ',selectPhones)
  };

  const zhilianClose = (id) => {
    actionRef_1.current.reload();
    setZhilianScreenShow(false);
    setCheckboxShow(false);
    setSelectPhones([]);
    setToupingPhones([]);
    setToupingPhonesInfo({});
  }
  
  // 同步素材功能
  const onSubmit = async (values,fileInfo) =>{
    if(!values['name'] || !fileInfo)
      return;
    var params = fileInfo;
    params.name = values.name;
    params.cloudPhoneNum = curDeviceInfo.cloudPhoneNum;
    if(curClientid)
      params.primaryAccount = curClientid;

    try {
      await phoneSyncMaterial(params).then((res) => {
        // console.log('phoneSyncMaterial-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          message.success("操作成功")
          if(actionRef_2.current){
            actionRef_2.current.reload();
          }
          close();
        }
        // else
        //   message.error(res.data.msg);
      });
    } catch (error) {
      message(error);
    }
  }

  
  /**
   * 定时获取设备状态
   * @returns {Promise<void>}
   */
   const startFlushStateTimer = () => {
    if(occupyDevices.length == 0)
      return;

    if (waitFlushInterHandler === undefined) {
      waitFlushInterHandler = setInterval(async () => {
        if (waitState) {
          waitState = false;
          clearInterval(waitFlushInterHandler);
          waitFlushInterHandler = undefined;
        } else {
          // actionRef_1.current.reload();
          console.log('run:' + new Date().toString());
          try {
            await getApiData('currency', 'deviceStatus', {cloudPhoneNums:occupyDevices}).then((res) => {
              // console.log('run: getApiData-res-----------------', res);
              var flag = checkLogin(res.data);
              if(flag){
                // var devicestatus = listDeviceStatus;
                var keys = Object.keys(res.data.data);
                console.log('run:',keys)
                var occupy = [];
                for(var i=0; i<keys.length; i++)
                {
                  listDeviceStatus[keys[i]] = res.data.data[keys[i]];
                  console.log('run:',keys[i], res.data.data[keys[i]])
                  if(res.data.data[keys[i]] == 2)
                    occupy.push(keys[i]);
                  // else if(res.data.data[keys[i]] == 1)
                  //   document.getElementById("btn_zhilian_"+keys[i]).setAttribute("disabled", false);
                }
                console.log('run: occupy - ', occupy)
                setListDeviceStatus({...listDeviceStatus});
                if(occupy.length == 0){
                  waitState = true;
                }else{
                  occupyDevices = occupy;
                }
              }
              // else
              //   message.error(res.data.msg);
            });
          } catch (error) {
            // message(error);
          }
        }
      }, 10000);
    }
  };


  // 修改是否显示
  const onChangeSwitch = (cloudPhoneNum, checked) => {
    console.log(` ${cloudPhoneNum} switch to ${checked}`);
    try {
      getApiData('currency', 'switchAppShow', {cloudPhoneNum:cloudPhoneNum, showApp:checked?1:0}).then((res) => {
      var flag = checkLogin(res.data)
      if (flag) {
        actionRef_1.current.reload();
        message.success("操作成功");
      }
    });
    } catch (error) {
      // message.error(error);
    }
  };

  return (
        <Content
          className="site-layout-background"
          style={{
            padding: 10,
            margin: 0,
            minHeight: "100%",
          }}
        >
      {/* <div className='titleClass' style={{width:100,minHeight: 30,marginBottom:10,fontSize:24,fontWeight:600,fontFamily:"PingFangSC-Regular"}}>养号舱</div> */}
      <Row align="middle">
        <Col span={16} className='titleClass'>
          养号舱
        </Col>
        <Col span={4} className='selectClient' style={{display:showSelectClient}}>
          选择客户 
        </Col>
        <Col span={4} style={{display:showSelectClient}}>
          <Select
            placeholder="请选择"
            className='ant-select-selector'
            options={clientList}
            onChange={onChangClient}
          />
        </Col>
      </Row>

      <Tabs defaultActiveKey={curTabPane} size='large' onTabClick={tabClicked} >
      <TabPane tab={<div><div id="tab_pane_1" className='tab_pane_active'><MenuOutlined />养号舱列表</div><div id="tab_bar_1" className='cust_tab_bar_active' ></div></div>} key="1" >
        <div className="site-card-wrapper">
        <Row gutter={[16,16]} justify="start" style={{marginBottom:10,textAlign:"left",fontWeight:550}}>
          <Col span={4}>
            <span>云手机序号</span>
          </Col>
          <Col span={4}>
            <span>国家/地区</span>
          </Col>
          <Col span={4}>
            <span>使用状态</span>
          </Col>
        </Row>
        <Row gutter={[16,16]} style={{marginBottom:10,textAlign:"left"}}>
          <Col span={4}>
            <Input id="cloudPhoneNum" className='ant-select-selector' allowClear value={search_cloudPhoneNum} onChange={onChang_cloudPhoneNum}/>
          </Col>
          <Col span={4}>
            <Select
              placeholder="请选择"
              className='ant-select-selector'
              options={countryList}
              value={search_country}
              onChange={onChang_Courtry}
              allowClear
            />
          </Col>
          <Col span={4}>
            <Select
              placeholder="请选择"
              className='ant-select-selector'
              value={search_deviceStatus}
              onChange={onChang_DeviceStatus}
              options={allDeviceStatus_select}
              allowClear
        />
          </Col>
          <Col span={2}>
          <Button type="primary" shape="round" style={{borderRadius:10}} onClick={()=>{actionRef_1.current.reload()}} >查询</Button>
          </Col>
          <Col span={2}>
          <Button shape="round"  className="m-btn-white" onClick={(event)=>{buttonBlur(event);refresh()}} >刷新</Button>
          </Col>
          <Col span={4} style={{textAlign:"right"}}>
          <Button className='download' style={{zIndex:500}} icon={<ScreenIcon />} onClick={()=>{
            if(curPhoneCount == 0)
              return;
            var opennum = 0;
            allToupingPhones.forEach((item)=>{
              if(item.id != 0)
                opennum++;
            })
            // console.log(opennum)
            if(opennum == 0)
              setCheckboxShow(!showCheckbox)
            }} >
            投屏
          </Button>
          </Col>
          <Col span={4} style={{textAlign:"right"}}>
          <Button type="default" shape="round"  className='download' icon={<DownloadIcon />}  onClick={()=>{showModal_QRCode()}} >
            扫码下载直播舱
          </Button>
          </Col>
        </Row>
      </div>
      {/* <div style={{display:showCheckbox?'block':'none',marginTop:15,padding:"5px 0",textAlign:"center",border:"1px solid #a8beed",borderRadius:10,color:"rgb(63,140,255)"}}>请点击手机下方复选框最多选择2台云手机进行投屏</div>
      <Checkbox.Group
        style={{
          width: '100%',
        }}
        onChange={onCheckPhone}
        value={selectPhones}
      > */}

    <ProList
        grid={{
          gutter: 50,
          // column: 4,
          // xs: 1,
          // sm: 1,
          // md: 2,
          // lg: 2,
          // xl: 3,
          // xxl: 4,
        }}
        // loading={false}
        pagination={{
          style:{zIndex:500,position:"relative"},
          onChange: (page) => {
            // console.log(page);
          },
          pageSize: 10,
        }}
        actionRef={actionRef_1}
        rowKey = "cloudPhoneNum"
        // dataSource={deviceList.rows}
        request={async (params = {})  => {
          // setClientlistloading(true);
          var list = [];
          if(currentUserinfo.userType!=99 || (currentUserinfo.userType==99 && curClientid)){
            // console.log('-----------------params----------------', params);
            const res = await getDeviceList(params);
            // console.log('------------------res--------------', res);
            if(res.rows){
              list = res.rows;
              curPhoneCount = list.length;
              var allstatus = {}
              occupyDevices = [];
              for(var i=0; i<list.length; i++){
                allstatus[list[i].cloudPhoneNum] = list[i].deviceStatus;
                if(list[i].deviceStatus == 2)
                  occupyDevices.push(list[i].cloudPhoneNum);
              }
              console.log('allstatus - ', showZhilianScreen,showCheckbox, occupyDevices,occupyDevices.length);
              setListDeviceStatus(allstatus);
              if(occupyDevices.length > 0 && !showZhilianScreen && !showCheckbox){
                waitState = false;
                clearInterval(waitFlushInterHandler);
                waitFlushInterHandler = undefined;
                startFlushStateTimer();
              }
              else
                waitState = true;
            }
          }
          // // setClientlistloading(false);
          return {
              data: list,
              // current: res.data.pageNo,
              // pageSize: res.data.pageSize,
              success: true,
              // total: res.data.totalRows,
          };
        }}
        renderItem={(item) => (
          <List.Item>

          <div className='phoneClass'>
              <div className='phoneSmarll' style={{zIndex:500,position:"relative"}} onClick={()=>{
                if(item.deviceStatus==1)
                  onTouping(item.cloudPhoneNum);
              }}>
                <div className='phoneHole'></div>
                <div className='phoneText' style={{display:showCheckbox?"none":"block"}}><img src={[require("../admin/img/plogo.png")]} width={"40%"}/></div>
                <div className='phoneText toupingText' style={{display:showCheckbox?"block":"none",color:item.deviceStatus==1?"rgb(21,72,152)":"#ccc"}}>点此投屏</div>
              </div>
              <div style={{position:"relative",top:"15px",left:"200px",width:20,zIndex:2}}>
                <EditIcon key="edit"
                    onClick={() => {showModal(item)}} />
              </div>
              <div style={{fontSize:6,lineHeight:"0.5rem",padding:"0 20px 10px 20px",zIndex:1}}>
              <p>{item.nodeName}</p>
              <p>云手机序号：{item.cloudPhoneNum}</p>
              <p>所属分组：{item.nodeGroup}</p>
              {/* <p><span style={{position:"relative",top:"1.5px"}}>是否展示 <Tooltip placement="top" title="开启后，该云手机才会在直播舱APP中显示" overlayStyle={{fontSize:6}}><InfoCircleOutlined style={{fontSize:10,color:"#00f"}} /></Tooltip>
                        ：</span><Switch size='small' defaultChecked={item.showApp==1?true:false} onChange={(checked)=>onChangeSwitch(item.cloudPhoneNum,checked)} /></p> */}
              <p>状态：{allDeviceStatus[item.deviceStatus].text}</p>
              {/* <p>机型：{item.brand}</p> */}
              <p>设备号：{item.deviceNum}</p>
              <p>虚拟IP：{item.innerIp}</p>
              {/* <p>IMEI：{item.imei}</p> */}
              <p>安卓版本：{item.androidVersion}</p>
              <p>备注：{item.remark}</p>
              </div>
              <div style={{padding:"0 30px",height:"30px"}}>
                <span style={{float:"left"}}><Button id={'btn_zhilian_'+item.cloudPhoneNum} type='primary' shape="round" size='small' style={{borderRadius:10,padding:"0px 17px",fontSize:10}} disabled={listDeviceStatus[item.cloudPhoneNum]!=1}  onClick={()=>onZhilian(item.cloudPhoneNum, item.deviceNum)} > 直　连 </Button></span>
                <span style={{float:"right"}}><Button type='primary' shape="round" size='small' disabled={item.uploadUrl?false:true} style={{borderRadius:10,fontSize:10}} onClick={()=>{setCurDeviceInfo(item);setShowModel(true);}} >同步素材</Button></span>
              </div>
              <div style={{padding:"0 30px"}}>
                <span style={{float:"left"}}><Button type='primary' shape="round" size='small' style={{borderRadius:10,fontSize:10}} onClick={()=>onUpdateParam(item.cloudPhoneNum)}>一键换参</Button></span>
                <span style={{float:"right"}}><Button type='primary' shape="round" size='small' style={{borderRadius:10,fontSize:10}} onClick={()=>{setCurDeviceInfo(item);setShowParamDetail(true)}}>参数详情</Button></span>
              </div>
            </div>
            {/* <div style={{marginTop:10,marginLeft:130,display:showCheckbox&&item.deviceStatus==1?'block':'none'}}><Checkbox value={item.cloudPhoneNum} /></div> */}
        </List.Item>
        )}
      />


      {/* </Checkbox.Group> */}

      </TabPane>
      {/* {haveMaterialMenu && (
      <TabPane tab={<div><div id="tab_pane_2" className='tab_pane'><ResourceIcon />素材管理</div><div id="tab_bar_2" className='cust_tab_bar' ></div></div>} key="2" >
        <div>

        <Material 
          currentUserinfo = {currentUserinfo}
          curClientid = {curClientid}
          actionRef_2 = {actionRef_2}
          from = {"stayroom"}
        />

        </div>
      </TabPane>
      )} */}
      {haveAppMenu && (
      <TabPane tab={<div><div id="tab_pane_3" className='tab_pane'><AppIcon />APP管理</div><div id="tab_bar_3" className='cust_tab_bar' ></div></div>} key="3">
        <div>
        <Applist
          currentUserinfo = {currentUserinfo}
          curClientid = {curClientid}
          actionRef_3 = {actionRef_3}
        />

        </div>
      </TabPane>
      )}
      
      </Tabs>  
    {customTabinkbar()}

    <div className='ant-modal-mask' id="mask" style={{display:showCheckbox?"block":"none",backgroundColor:"rgba(0,0,0,.04)",zIndex:200}}></div>
    <div className='ant-modal-mask' id="mask2" style={{display:showZhilianScreen?"block":"none"}}></div>
    <iframe key="deviceresult" id="deviceresult" style={{display:"none"}} width={clientWidth} height={clientHeight*0.6}
      sandbox="allow-forms allow-popups allow-scripts allow-same-origin allow-modals"
      src={"/html/deviceresult.html?a=11"}
      name='iframe'
      scrolling="no"
    ></iframe>

    <Modal 
        key="QRCode"
        title='扫码下载直播舱'
        open={QRCodeModalOpen}
        width="580px"
        centered
        footer={[] }// 设置footer为空，去掉 取消 确定默认按钮
        onCancel={handleCancel_QRCode}>
        
        <div className="site-card-wrapper">
        <Row gutter={24}>
          <Col span={12}>
            <Card title="国内版本" bordered={false}>
              {QRCodeUrl.domestic ? (<QRCode className="QRCode-qrCode" value={QRCodeUrl.domestic} size={200}/>) : (<LoadingOutlined />)}
            </Card>
          </Col>
          <Col span={12}>
            <Card title="海外版本" bordered={false}>
              {QRCodeUrl.oversea ? (<QRCode className="QRCode-qrCode" value={QRCodeUrl.oversea} size={200}/>) : (<LoadingOutlined />)}
            </Card>
          </Col>
        </Row>
      </div>
      
    </Modal>

    
    <Modal 
        key="basicinfo"
        title='基本信息'
        open={editModalOpen_bc}
        width="350px"
        centered
        footer={[<Button  className="m-btn-white" onClick={closeModal}>
        取消
        </Button>,
        <Button type="primary"  style={{ marginLeft:'20px'}} onClick={()=>{form.submit()}}>
        确认
        </Button>] }
        onCancel={closeModal}>
          <Form  layout="vertical" form={form} onFinish={editBasicInfo}>
            <Form.Item label='节点名称' name="name" rules={[
                  {required: true,message: '请输入节点名称',},
                ]} >
                <Input />
            </Form.Item>
            <Form.Item label='国家/地区' name="nodeGroup" >
              <Select
                placeholder="--请选择--"
                className='ant-select-selector'
                style={{width:"100%"}}
                options={countryList}
              />
            </Form.Item>
            <Form.Item label='备注' name="remark" >
                <Input />
            </Form.Item>
          </Form>   
    </Modal>


    {/* <Zhilian
      showZhilianScreen={showZhilianScreen}
      selectPhones={toupingPhones}
      selectPhonesInfo={toupingPhonesInfo}
      zhilianClose={zhilianClose}
      pageType={"stay"}
    /> */}

    <Updateparam
      exchangParam_bc={exchangParam_bc}
      curCloudPhoneNum={curCloudPhoneNum}
      deviceInfo={deviceInfo}
      handleExCancel={handleExCancel}
      pageType={"stay"}
    />

    <Synmaterial
        showModel = {showModel}
        curDeviceInfo = {curDeviceInfo}
        close = {close}
        edit = {true}
        // onSubmit = {onSubmit}
    />

    <ParamDetail
        showParamDetail = {showParamDetail}
        curDeviceInfo = {curDeviceInfo}
        close = {close}
        pageType={"stay"}
    />

    <Touping
      showZhilianScreen={allTouPingParams[0].show}
      zIndex={allTouPingParams[0].zIndex}
      selectPhones={[allTouPingParams[0].id]}
      selectPhonesInfo={allTouPingParams[0].info}
      toupingClose={toupingClose}
      updatemaxIndex={updatemaxIndex}
      pageType={"stay"}
      currentUserinfo={currentUserinfo}
      curClientid={curClientid}
      sessionid={sessionid}
    />

    <Touping
      showZhilianScreen={allTouPingParams[1].show}
      zIndex={allTouPingParams[1].zIndex}
      selectPhones={[allTouPingParams[1].id]}
      selectPhonesInfo={allTouPingParams[1].info}
      toupingClose={toupingClose}
      updatemaxIndex={updatemaxIndex}
      pageType={"stay"}
      currentUserinfo={currentUserinfo}
      curClientid={curClientid}
      sessionid={sessionid}
    />

    <Touping
      showZhilianScreen={allTouPingParams[2].show}
      zIndex={allTouPingParams[2].zIndex}
      selectPhones={[allTouPingParams[2].id]}
      selectPhonesInfo={allTouPingParams[2].info}
      toupingClose={toupingClose}
      updatemaxIndex={updatemaxIndex}
      pageType={"stay"}
      currentUserinfo={currentUserinfo}
      curClientid={curClientid}
      sessionid={sessionid}
    />

    <Touping
      showZhilianScreen={allTouPingParams[3].show}
      zIndex={allTouPingParams[3].zIndex}
      selectPhones={[allTouPingParams[3].id]}
      selectPhonesInfo={allTouPingParams[3].info}
      toupingClose={toupingClose}
      updatemaxIndex={updatemaxIndex}
      pageType={"stay"}
      currentUserinfo={currentUserinfo}
      curClientid={curClientid}
      sessionid={sessionid}
    />

    <Touping
      showZhilianScreen={allTouPingParams[4].show}
      zIndex={allTouPingParams[4].zIndex}
      selectPhones={[allTouPingParams[4].id]}
      selectPhonesInfo={allTouPingParams[4].info}
      toupingClose={toupingClose}
      updatemaxIndex={updatemaxIndex}
      pageType={"stay"}
      currentUserinfo={currentUserinfo}
      curClientid={curClientid}
      sessionid={sessionid}
    />

    <Touping
      showZhilianScreen={allTouPingParams[5].show}
      zIndex={allTouPingParams[5].zIndex}
      selectPhones={[allTouPingParams[5].id]}
      selectPhonesInfo={allTouPingParams[5].info}
      toupingClose={toupingClose}
      updatemaxIndex={updatemaxIndex}
      pageType={"stay"}
      currentUserinfo={currentUserinfo}
      curClientid={curClientid}
      sessionid={sessionid}
    />

  </Content>

);
}
export default App;