import './index.css';
import React , { useEffect, useState ,useRef} from 'react'
import { List, Button, message, Row, Col } from 'antd';
import Icon,{ CloseOutlined } from '@ant-design/icons';
import { HomeIcon, ReturnIcon, MenuIcon, SoundOpenIcon, SoundCloseIcon } from '../icons';
// import {getDeviceInfo, disconnectDevice} from './service';
import {directConnect,directDisconnect} from '../../bcroom/service';
import {directConnect_stay,directDisconnect_stay} from '../../stayroom/service';
import {directDisconnect_unm} from '../../unmroom/service';

const scrheight = document.body.clientHeight;
const scrWidth = document.body.clientWidth;
// console.log(scrWidth,scrheight);
const videoHeight = parseInt(scrheight*0.7);
const videoWidth = parseInt(videoHeight*0.65);
// console.log(videoWidth,videoHeight)
// var posPhoneTop = 10;
// if(scrheight > 600)
//   posPhoneTop = Math.ceil((scrheight-600)/2);
// // console.log(posPhoneTop);

var topIndex = 2000;


let draging = false;
let dragDom = HTMLElement | null;
let dragpoint = { x: 0, y: 0 };

export default  function Index(props) {
  const {showZhilianScreen,zIndex, selectPhones,selectPhonesInfo,toupingClose,updatemaxIndex,pageType,currentUserinfo,curClientid,sessionid} = props;
  // console.log('zhilian props ------------ ', props);
  if(!currentUserinfo.customerId)
    currentUserinfo.customerId = curClientid;
  topIndex = zIndex;
  const [currentPhones,setCurrentPhones] = useState(selectPhones);
  const [showPhones,setShowPhones] = useState({});
  const [showToolbar,setShowToolbar] = useState({});

  const [isSoundOpen,setIsSoundOpen] = useState({});
  const [isSuccess,setIsSuccess] = useState({});

  useEffect(() => {
    // console.log("挂载或者更新触发", "num更新后:", showDetailConnectHome);
    setCurrentPhones(selectPhones);

    if(showZhilianScreen && selectPhones.length > 0){
      var show = {};
      var showtoolbar = {};
      var success = {};
      var sound = {};
      selectPhones.map((item, index)=>{
        document.getElementById("gbox_"+item).src = "";
        document.getElementById("screen_"+item).style.marginTop = parseInt(scrheight*0.5-(videoHeight+137)*0.5)+"px";
        document.getElementById("screen_"+item).style.marginLeft = parseInt(scrWidth*0.5-videoWidth*0.5)+"px";
        sound[item] = true;
        show[item] = true;
        if(selectPhonesInfo[item]){
          // console.log('selectPhonesInfo----------- ', selectPhonesInfo[item]);
          if(selectPhonesInfo[item].phoneType == 3)
            showtoolbar[item] = false;
          else
            showtoolbar[item] = true;
          success[item] = true;
          setTimeout(() => {
            connectDevice(selectPhonesInfo[item]);
          }, 100);
        }else{
          success[item] = false;
        }
      });
      setShowPhones({...show});
      setShowToolbar({...showtoolbar});
      setIsSoundOpen({...sound});
      setIsSuccess({...success});
      // console.log('showToolbar--------', showToolbar);

      /*弹窗拖拽，适用于ant design */
      document.addEventListener('mousedown', mousedownFun);
      document.addEventListener('mousemove', mousemoveFun);
      document.addEventListener('mouseup', mouseupFun);

    } else {
      document.removeEventListener("mousedown", mousedownFun);
      document.removeEventListener("mousemove", mousemoveFun);
      document.removeEventListener("mouseup", mouseupFun);
    }
  }, [showZhilianScreen]);

  const mousedownFun = (ev) => {
    // console.log('mousedownFun ----------- ', ev)

    let target = ev.target;
    // 点住标题栏进行拖拽
    if (!target.classList.contains('zhilian_title')) {
        return;
    }
    //根据标题栏的dom找到控制弹窗组件布局位置的dom，ant-design的弹窗组件的外壳在标题栏的父级第3层
    dragDom = target.parentElement?.parentElement?.parentElement;
    //由于此拖拽法对所有弹窗有效，通过classname标注控制动作取消
    if (dragDom.parentElement?.classList.contains('nodrag')) {
        dragDom = null;
        return;
    }
    draging = true;
    dragpoint = {
        x: ev.clientX,
        y: ev.clientY
    }
    // 设置到顶层
    topIndex++;
    dragDom.style.zIndex = topIndex;
    updatemaxIndex(topIndex);
  }

  const mouseupFun = () => {
    // console.log('mouseupFun ----------- ')
    draging = false;
    dragDom = null;
  }

  const mousemoveFun = (ev) => {
    // console.log('mousemove ----------- ', ev.clientX,ev.clientY)
    if (draging) {
        let _dragdom = dragDom;
        let sty = window.getComputedStyle(_dragdom, null);
        // console.log(sty.marginLeft, ev.clientX, dragpoint.x)
        // console.log(sty.marginTop, ev.clientY, dragpoint.y)
        var newx = `${parseFloat(sty.marginLeft) + ev.clientX - dragpoint.x}px`;
        var newy = `${parseFloat(sty.marginTop) + ev.clientY - dragpoint.y}px`;
        // console.log("new: ", newx,newy)
        if(newx < 0)
          newx = 0;
        if(newy < 0)
          newy = 0;
        _dragdom.style.marginLeft = newx;
        _dragdom.style.marginTop = newy;
        dragpoint = {
            x: ev.clientX,
            y: ev.clientY
        }
    }
  }

  // 声音开关
  const setSoundFun = (id) => {
    isSoundOpen[id] = !isSoundOpen[id];
    setIsSoundOpen({...isSoundOpen});
    if(isSoundOpen[id])
      postMessage(id,'audioopen','开启声音')
    else
      postMessage(id,'audioclose','关闭声音')
  };
  
  // 关闭投屏手机
  const closeScreen = (id) => {
    // console.log(id)
    postMessage(id,'videoclose','关闭');
    // console.log(selectPhonesInfo)
    if(selectPhonesInfo[id]){
      setTimeout(()=>{
        // document.getElementById("screen_"+id).style.display = "none";
        // console.log(document.getElementById("screen_"+id))
        // console.log(currentPhones)
        var show = showPhones;
        show[id] = false;
        setShowPhones({...show});

        currentPhones.map((item, index)=>{
          // console.log(index,item)
          if(item == id)
            currentPhones.splice(index, 1);
          // console.log(currentPhones)
        });
          // console.log(currentPhones)
        setCurrentPhones([...currentPhones]);
        toupingClose(id);
        // if(currentPhones.length == 0)
        //   zhilianClose();
          // document.getElementById("screen12").style.display = "none";
        if(selectPhonesInfo[id].phoneType != 3)
          disconnectDevice(id);
      },500)
    }
  }

  // 直连手机功能
  const connectDevice = async(deviceInfo) => {
    // 获取设备信息
    // try {
    //   const param = {
    //     "cloudPhoneNum":deviceNum,
    //   };
    //   var fun = pageType=='stay'?directConnect_stay:directConnect;
    //   return await fun(param).then((res) => {
    //     console.log(fun+'-----------------', res);
    //     const msg = res.data;
    //     if(msg.code != 0){
    //       alert(msg.msg);
    //       return;
    //     }
        var resp = deviceInfo.resource;
        resp['pkgName'] = deviceInfo.pkgName;
        resp['phoneType'] = deviceInfo.phoneType;
        resp['corpkey'] = currentUserinfo.customerId;
        resp['userid'] = currentUserinfo.userName;
        resp['page'] = pageType;
        resp['sessionid'] = sessionid;
        console.log("------resp--------",resp);
        var devicetype = "";
        if(deviceInfo.phoneType == 1){
            devicetype = 'bdai';
        }
        else if(deviceInfo.phoneType == 2){
            devicetype = 'microcloud';
        }
        else if(deviceInfo.phoneType == 3){
            devicetype = 'volc';
        }
        else if(deviceInfo.phoneType == 4){
            devicetype = 'volc2';
        }
        if(!devicetype)
          message.error("该云手机暂不支持直连");
        else{
            var cloudPhoneNum = deviceInfo.cloudPhoneNum;
            window.parent._DEVICEINFO[cloudPhoneNum] = resp;
            var url = "/html/"+devicetype + "/video.html?devicenum="+cloudPhoneNum+"&a=11";
            // console.log(url);
            // console.log(deviceInfo.cloudPhoneNum);
            // console.log(document.getElementById("gbox_"+deviceInfo.cloudPhoneNum));
            document.getElementById("gbox_"+deviceInfo.cloudPhoneNum).src = url;
            postMessage(deviceInfo.cloudPhoneNum,'audioclose','关闭声音')
          // }
        }
    //   });
    // } catch (error) {
      
    //   return false;
    // }
  };

  // 断开直连手机功能
  const disconnectDevice = async(deviceNum) => {
    // 获取设备信息
    try {
      const param = {
        "cloudPhoneNum":deviceNum,
      };
      var fun = pageType=='stay'?directDisconnect_stay:(pageType=='bc'?directDisconnect:directDisconnect_unm);
      return await fun(param).then((res) => {
        // console.log(fun+'-----------------', res);
        const msg = res.data;
        if(msg.code != 0){
          message.error(msg.msg);
          return;
        }
      });
    } catch (error) {
      
      return false;
    }
  };

  // 控制手机菜单
  const postMessage = (id,code,msg) => {
    // if(statue){
      let iFrame = document.getElementById('gbox_'+id)
      let token =  localStorage.getItem("token");
      // console.log(code)
      // console.log(msg)
      // if(appId){
      //   iFrame.contentWindow.postMessage({statue:code,msg:msg,token:token,appId:appId},'*');
      // }else{
        iFrame.contentWindow.postMessage({statue:code,msg:msg,token:token},'*');
        // iFrame.contentWindow.postMessage({statue:code,msg:msg,token:token});
      // }
    // }else{
    //   message.warn('视频流未加载完成！')
    // }
  }
  
  return (

      <div id={"screen_"+selectPhones[0]} role="dialog" aria-modal="true" className="ant-modal zhilian_modal ant-modal-centered toupingwin" style={{zIndex:zIndex,width: videoWidth,display:showPhones[selectPhones[0]]?"block":"none"}}>
        <div className="ant-modal-content" style={{float:"right",width: videoWidth}}>
          {/* <span style={{color:"rgb(63,140,255)",fontSize:'1.5em',paddingTop:10}}>{item}</span> */}
          <div className="ant-modal-header" style={{padding:"0 24px 10px"}}>
              <div className="ant-modal-title zhilian_title">{selectPhones[0]}</div>
          </div>
          <Button style={{boxShadow:"none",paddingTop:10,marginRight:5}} className="ant-modal-close " icon={<CloseOutlined />} onClick={()=>closeScreen(selectPhones[0])} />
        <div className="ant-modal-body">
          <div style={{textAlign:"center"}}>
            {/* <div style={{border:"none",height:videoHeight,width:videoWidth-40,display:isSuccess[selectPhones[0]]?"none":"block",paddingTop:200}}>连接失败！</div> */}
            <iframe key="zhilianphone" id={"gbox_"+selectPhones[0]} style={{border:"none",height:videoHeight,width:"100%",display:isSuccess[selectPhones[0]]?"block":"none"}}
              sandbox="allow-forms allow-popups allow-scripts allow-same-origin allow-modals"
              src=""
              name='iframe'
              scrolling="no"
            ></iframe>
          </div>
        </div>
        <div className="ant-modal-footer">
          <div style={{display:showToolbar[selectPhones[0]]?"block":"none",background: "rgb(63, 140, 255)", borderRadius: 10, textAlign: "center", position: "relative", top: 10, boxShadow: "rgba(63, 140, 255, 0.263) 0px 6px 12px 0px", padding: "6px 0"}}>
            <Row>
            <Col span={6}><Button type="primary" icon={<ReturnIcon />} onClick={ () => {postMessage(selectPhones[0],'back','返回')} } /></Col>
            <Col span={6}><Button type="primary" icon={<HomeIcon />} onClick={ () => {postMessage(selectPhones[0],'home','home键')} } /></Col>
            <Col span={6}><Button type="primary" icon={<MenuIcon />} onClick={ () => {postMessage(selectPhones[0],'menu','菜单')} } /></Col>
            <Col span={6}><Button type="primary" icon={isSoundOpen[selectPhones[0]]?<SoundOpenIcon />:<SoundCloseIcon />} onClick={ () => {setSoundFun(selectPhones[0])} } /></Col>
          </Row>
        </div>
      </div>
    </div>
    </div>
      

  );
}


