import React,{useState, useEffect} from 'react';
import { Button,  Form, Input,message,Layout,Row,Col,Checkbox, Space } from 'antd';
import './styles.css';
import {ResetPassword} from './service';
import checkLogin from '../../request/checkLogin.js'
import Timebutton from "./timebutton.jsx"

const { Header, Content, Footer, Sider } = Layout;

const Login =(props)=>{
  const [form] = Form.useForm();
  const mobile = Form.useWatch('mobile', form);
  const password = Form.useWatch('password', form);
  const [mobileStatus,setMobileStatus] = useState(false);

  const validateMobile = (rule, value, callback) => {
    // console.log(rule)
    // console.log(value)
    // console.log(callback)
    setMobileStatus(false)
    if (value === '' || value==undefined) {
        callback(new Error('请输入手机号'));
    } else {
       const reg = /^1[3-9]\d{9}$/; //正则校验
      if(reg.test(value)){
        setMobileStatus(true)
        callback();
      } else{
        callback('手机号格式错误');
      } 
   }
  };

  const validatePass = (rule, value, callback)=>{
    if (value === '' || value==undefined) {
        // callback(new Error('请再次输入密码'));
        return new Promise((resolve, reject) => {
            reject('请再次输入密码')
          });
      } else if (value !== password) {
        // callback(new Error('两次输入密码不一致!'));
        return new Promise((resolve, reject) => {
            reject('两次输入密码不一致!')
          });
      } else {
        return Promise.resolve()
      }
  }

  
    const onFinish = async(value)=>{
      console.log(value)
      localStorage.removeItem('token');
      if(value.password != value.password2)
        message.error("2次密码输入不一致。请重新输入密码")
      else{
        try {
            delete value.password2;
            return await ResetPassword(value).then((res) => {
              console.log('ResetPassword-res-----------------', res);
              if(res.data.code == 0){
                message.success('重置密码成功，请重新登录');
                window.location.href='/#/login'
              }
              else 
                message.error(res.data.msg)
            });
          } catch (error) {
            
            return false;
          }
        }
    }
  return (
  
    <div  id="box" >
      <Row style={{width:"100%",height:"100%", borderRadius:20}}>
        <Col span={12} style={{background:"rgb(63,140,255)", borderRadius:"20px 0 0 20px"}}>
          <div style={{textAlign:"left", paddingLeft:50,paddingTop:40}}><img src={[require("../admin/img/logo.jpg")]} alt="" width="30%" /></div>
          <img src={[require("./img/login_left.png")]} alt="" width="70%" style={{marginTop:100}} />
        </Col>
        <Col span={12} style={{background:"white", borderRadius:"0 20px 20px 0"}}>
            <h1 id= 'title'>找回密码</h1>
            <Form form={form}
            className='loginForm'
            name="basic"
            // labelCol={{span: 5}}
            // wrapperCol={{span: 16}}
            initialValues={{remember: true,}}
            onFinish={onFinish}
            layout="vertical"
            // onFinishFailed={this.onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="手机号"
                name="mobile"
                rules={[
                  { validator: validateMobile, trigger: 'blur' },
                  // {required: true, message: '请输入手机号',},
                  // {pattern: /^1\d{10}$/, message: '手机号格式错误'},
                ]}
            >
            <Input placeholder="请输入手机号" />
            </Form.Item>
            <Form.Item
                label="验证码"
            >
            <Form.Item 
                name="code"
                rules={[
                    {
                    required: true,
                    message: '请输入验证码',
                    },
                ]}
            
            style={{width:"60%",float:"left",marginBottom:0}}>
              <Input placeholder="请输入验证码" />
            </Form.Item>
            {/* <Button type='link' style={{width:"30%",float:"right",boxShadow:"none",background:"rgb(244,249,253)"}}>获取验证码</Button> */}
            <Timebutton
              mobile={mobile}
              status={mobileStatus}
              type="resetpwd"
            />
            </Form.Item>
            <Form.Item
                label="新密码"
                name="password"
                rules={[
                  {required: true,message: '请输入密码',},
                  {pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,message: '密码为必须包含字母和数字的6-20位字符',},
                ]}
            >
            <Input.Password  placeholder="请输入新密码"/>
            </Form.Item>
            <Form.Item
                label="再次输入新密码"
                name="password2"
                rules={[
                  { validator: validatePass, trigger: 'blur' }
                  // {required: true,message: '请输入密码',},
                  // {pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,message: '密码为必须包含字母和数字的6-20位字符',},
                ]}
            >
            <Input.Password  placeholder="请再次输入新密码"/>
            </Form.Item>
            <Form.Item>
            <div>
            <Button type="primary" htmlType="submit" style={{padding:"0 30px"}}>
                重置密码
            </Button>
            </div>
            <div style={{marginTop:20}}><a href="/#/login">已有账号，马上登录</a></div>
            </Form.Item>
        </Form>
        </Col>
        </Row>

    </div>
  )
}
export default Login
