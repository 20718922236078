import React, { useState,useEffect }  from 'react';
import Icon, {UserOutlined,DownOutlined} from '@ant-design/icons';
import { Layout, Menu,Image,Dropdown, Button,Avatar,Modal,Form,Input, message,Space } from 'antd';
import {Route, Routes, Navigate, useHistory,NavLink,useNavigate} from "react-router-dom"
// import { withRouter} from "react-router-dom"
// import './styles.less';
import './index.css';
import menuList from '../../menuRoute/index';
import memoryUtils from '../../utils/memoryUtils';
import { getUserinfo, userLoginout} from '../login/service';
import checkLogin from '../../request/checkLogin.js'
import { useLocation } from 'react-router'
import { render } from '@testing-library/react';
import { encrypt,decrypt,localStorageSet,localStorageGet } from '../../utils/global.js';

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;

const PersonSvg = () => (
  <svg t="1672718332518" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="29424" width="16" height="16"><path d="M512.0512 563.8912c143.0016 0 259.08736-118.55872 259.08736-264.87296 0-146.31936-116.08576-264.87808-259.09248-264.87808s-259.08736 119.1424-259.08736 264.87808c0 145.73568 116.08064 264.87296 259.08736 264.87296z m494.24384 324.77696l-18.54976-86.8864c-17.95072-83.29216-86.1696-155.264-170.53184-167.24992l-41.88672-5.9904a1885.87008 1885.87008 0 0 0-525.96224 0l-41.8816 5.9904c-84.37248 11.9808-153.18016 83.95776-170.53696 167.24992l-18.54976 86.8864c-5.38112 25.1648 0.59904 50.3296 16.75776 70.10816 16.1536 19.77344 39.49056 31.16032 65.21856 31.16032h824.54528c25.12896 0 49.06496-11.38688 65.21856-31.16032 15.55968-19.77344 21.53984-44.94336 16.15872-70.10816z" p-id="29425" fill="currentColor"></path></svg>);
  
const PersonIcon = props => (
  <Icon component={PersonSvg} {...props} />
  );

const ExitSvg = () => (
  <svg t="1672717209068" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="13765" width="16" height="16"><path d="M951.737186 488.212224 802.424532 301.56936c-7.222495-9.027607-18.034748-14.011108-29.157064-14.011108-4.131087 0-8.300037 0.688685-12.349259 2.106987-14.957667 5.246491-24.970718 19.371186-24.970718 35.223223l0 111.98756-298.631448 0c-41.232077 0-74.656327 33.42425-74.656327 74.656327 0 41.2331 33.42425 74.656327 74.656327 74.656327l298.631448 0 0 111.98756c0 15.852036 10.013051 29.977755 24.970718 35.223223 4.049223 1.424442 8.218172 2.108011 12.349259 2.108011 11.123338 0 21.934568-4.978385 29.157064-14.013155l149.311631-186.643887C962.64563 521.221012 962.64563 501.848803 951.737186 488.212224L951.737186 488.212224zM586.628698 810.162774 362.66074 810.162774l-74.656327 0 0-0.011256c-0.199545 0-0.393973 0.011256-0.587378 0.011256-40.906665 0-74.076112-33.42425-74.076112-74.656327l0-74.656327 0-298.631448 0-74.656327 0.011256 0c0-0.199545-0.011256-0.393973-0.011256-0.587378 0-40.906665 33.429367-74.076112 74.66349-74.076112l74.656327 0 223.967958 0c41.2331 0 74.66349-33.422204 74.66349-74.656327 0-41.232077-33.429367-74.656327-74.66349-74.656327L213.340923 63.586201c-82.459037 0-149.311631 66.853617-149.311631 149.311631l0 597.262896c0 82.4662 66.853617 149.311631 149.311631 149.311631l373.286752 0c41.2331 0 74.66349-33.422204 74.66349-74.656327C661.291165 843.586001 627.861798 810.162774 586.628698 810.162774L586.628698 810.162774zM586.628698 810.162774" fill="currentColor" p-id="13766"></path></svg>);
  
const ExitIcon = props => (
  <Icon component={ExitSvg} {...props} />
  );

// function getItem (list){
//     let arr = [];
//     list.map((item)=>{
//         if(item.hideInMenu){
//             return
//         }else{
//             let obj = {};
//             obj['key'] = item.path;
//             obj['label'] = item.title;
//             obj['icon'] = item.icon;
//             if(item.children){
//                 obj['children']= getItem(item.children)
//             }
//             arr.push(obj)
//         } 
//     })
//     return arr;
// }
// const items = getItem(menuList)


const getRoutesNodes=(menuList)=>{
    return menuList.map(item=>{        
        if(!item.children){
            return <Route path={item.path} key={item.path+1}  element={item.component}></Route>
        }else{
            return  getRoutesNodes(item.children)
        } 
    })
}

const getMenuaNodes=(menuList, currentUserinfo)=>{
  // console.log(menuList)
  // console.log("menuList - currentUserinfo---------", currentUserinfo);

  return menuList.map(item=>{
      // console.log('item----------', item);
      //如果当前用户有item权限，才需要显示对应菜单项
      // if(item.show ){
      if(item.show && (currentUserinfo.userType==99 || currentUserinfo.menuPerm.includes(item.path))){
        if(!item.children){
                return(<Menu.Item key={item.path} icon={item.icon}>
                    <NavLink to={item.path}> {item.title}</NavLink>                      
                    </Menu.Item>)
        }else{
            return(
                <SubMenu key={item.path} icon={item.icon} title={item.title}>
                    {getMenuaNodes(item.children, currentUserinfo)}
                </SubMenu>
            )
        }
      }
  })
}

const scrheight = document.body.clientHeight;
// console.log(scrheight)

const Admin =(props)=>{

  const navigate = useNavigate();
  const [curMenu, setCurMenu] = useState("");

  const token = localStorage.getItem('token');
  var UserInfo = localStorageGet('UserInfo');
  // console.log("UserInfo---------", UserInfo);

  if(!token || !UserInfo)
    window.location.href='/#/login';

  const currentUserinfo = JSON.parse(decrypt(UserInfo));
  // console.log("currentUserinfo---------", currentUserinfo);
  if(currentUserinfo.menuPerm.lengh == 0)
    window.location.href='/#/login';

  const location = useLocation();

  useEffect(() => {

    setCurMenu(location.pathname);
    if(currentUserinfo.userType==99 && location.pathname=="/"){
      window.location.href='/#/bcroom';
      setCurMenu("/bcroom");
    }
    else if(currentUserinfo.userType!=99 &&  !currentUserinfo.menuPerm.includes(location.pathname) && location.pathname!="/maccount"){
      window.location.href='/#'+currentUserinfo.menuPerm[0];
      setCurMenu(currentUserinfo.menuPerm[0]);
    }
  
  //   // 获取用户信息
  //   try {
  //     getUserinfo({}).then((res) => {
  //       console.log('getUserinfo-res-----------------', res);
  //       console.log('res.data-----------------', res.data);
  //       console.log('res.data.data-----------------', res.data.data);
  //       if(res.data.code == 0){
  //         getCurrentUserinfo(res.data.data);
  //         console.log('currentUserinfo-----------', currentUserinfo);

  //       }
  //       else
  //         window.location.href='/';
  //     });
  //   } catch (error) {
  //     message(error);
  //   }
  }, [location.pathname]);

  const onClick = ({ key }) => {
    // message.info(`Click on item ${key}`);
    if(key == "accmanage")
      window.location.href='/#/maccount';
    else if(key == "logout")
      Logout();
  };
  const items = [
    {
      label: <Space align="center" size={12}><PersonIcon />账号管理</Space>,
      key: 'accmanage',
      // icon: <PersonIcon />
    },
    {
      label: <Space align="center" size={12}><ExitIcon />退出登录</Space>,
      key: 'logout',
      // icon: <ExitIcon />
    },
  ];

  const Logout = (e) => {
    try {
      userLoginout({}).then((res) => {
      console.log('userLoginout-res-----------------', res);
      var flag = checkLogin(res.data)
      console.log('flag: ', flag);
      if (flag) {
        localStorage.removeItem('token');
        localStorage.removeItem("UserInfo");
        message.success("退出成功");
        window.location.href='/#/login';
      }
      // else
      //   message.error(res.data.msg);
    });
    } catch (error) {
      // message.error(error);
    }
  }
  
  
  return (
    <Layout>
    <Header 
    style={{
        position: 'fixed',
        top: 0,
        zIndex: 800,
        width: '100%',background:"#3f8cff",height:60,borderRadius:5
      }}
       className="header" theme="light" >
      <div style={{margin: '-5px -25px',minHeight: 50,position:"absolute"}}><img src={[require("./img/logo.jpg")]} width={150}/></div>
      <div  className="top-right" >
      {/* <Button type="normal"  shape="round" className='msgbutton' icon={<BellOutlined style={{ fontSize: '20px'}}/>} ></Button> */}
      <Dropdown className='userSuper'
        menu={{
          items,
          onClick,
          style:{borderRadius:10,textAlign:"center"}
        }}
      >
        <a onClick={(e) => e.preventDefault()}>
          {/* <Space align="center" style={{color:"black"}}> */}
            <Avatar size="middle" icon={<UserOutlined />} />
            <span style={{marginLeft:10,marginRight:15}}>{currentUserinfo.userName}</span>
            <DownOutlined style={{fontSize:10}} />    
          {/* </Space> */}
        </a>
      </Dropdown>
    </div>
    </Header>
    <Layout theme="light">
      <Sider width={200} className="site-layout-background" 
        style={{
          padding: '10px 10px',background:'#f1f6fa',
          overflow: 'auto',
          // height: '90vh',
          position: 'fixed',
          left: 0,
          top: 65,
          bottom: 5,
        }}>
      <div style={{borderRadius:15,fontWeight:500,background:"white",height:"100%"}}>
        <div style={{paddingTop:20, paddingBottom:20, paddingLeft:20,textAlign:"left"}}>
        <img src={[require("./img/menutop.png")]} width={35}/>
        </div>
        <Menu theme="light" mode="inline" defaultSelectedKeys={['home']} selectedKeys={[curMenu]}>
          {getMenuaNodes(menuList, currentUserinfo)}
        </Menu>
        {/* <div style={{position:"relative",}}> */}
        <div style={{textAlign: 'center',display:"inline-block",position: "absolute",bottom:25,left:0,zIndex:2}}><img src={[require("./img/10.png")]} width={"70%"}/></div>
        <div style={{width:"80%",position:"absolute", bottom: 15,left: "50%", transform: "translateX(-50%)", height:100, textAlign: 'center', borderRadius: 20, backgroundColor: '#ebf3fe'}}>
        {/* <div style={{marginTop:20}}><Button type="primary" size='large' shape="round" style={{borderRadius:10}} icon={<ServiceIcon />} >服务与支持</Button></div> */}
        </div>
        {/* </div> */}
      </div>
      </Sider>
      <Layout
        style={{
          paddingTop:70,background:'#f1f6fa',marginLeft: 200,minHeight: scrheight-60,
        }}
      >
        <Content
          className="site-layout-background"
          style={{
            padding: 0,
            margin: 0,
            // minHeight: scrheight-60,
          }}
        >
         <div
            className="site-layout-background"
            style={{
              padding: 0,
              textAlign: 'left',
            }}
          >
                <Routes>
                    {
                        getRoutesNodes(menuList)
                    }
                </Routes>
          </div>
        </Content>
      </Layout>
    </Layout>
  </Layout>

  )
}

export default Admin