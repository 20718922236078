import React, { Component,useState,useEffect }  from 'react';
import { Button,  Form, Input,message,Layout,Row,Col,Checkbox, Space,Radio,Steps,Upload,Divider,Progress,Modal  } from 'antd';
import { PlusOutlined,ArrowLeftOutlined,ArrowRightOutlined } from '@ant-design/icons';

import './styles.css';
import { getUserinfo, editUserinfo} from './service';
import checkLogin from '../../request/checkLogin.js'
import { uploadTmpUrl } from '../stayroom/service';
import axios from 'axios';
import CryptoJs from 'crypto-js';

const { Header, Content, Footer, Sider } = Layout;
const { Dragger } = Upload;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });


const RegisterInfo = (props)=>{
    const [form] = Form.useForm();
    const [bChecked,setChecked] = useState(false);
    const [showAgreement,setShowAgreement] = useState(false);

    const [step, setStep] = useState('0');
    const [userType, setUserType] = useState('0');
    const [corpInfo, setCorpInfo] = useState({});

    const [fileUrl, setFileUrl] = useState("");
    const [percent, setpercent] = useState(0);
    const [percentShow,setPercentShow] = useState(false)

    const [previewWidth, setPreviewWidth] = useState("100%");
    const [previewHeigh, setPreviewHeigh] = useState("100%");

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
      console.log('-------handlePreview----------', file);
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    
  useEffect(() => {
    // 获取用户信息
    try {
      getUserinfo({}).then((res) => {
        console.log('getUserinfo-res-----------------', res);
        if(res.data.code == 10008){
          setStep(res.data.data);
          setUserType(res.data.data);
        }
        else
          window.location.href='/';
      });
    } catch (error) {
      message(error);
    }
  }, []);

  const onCheck = (e) => {
    setChecked(e.target.checked);
  };

  const onCorpInfo = async(value)=>{
      console.log('onCorpInfo-------', value)
      setCorpInfo({...value});
      setStep(8);
  }

  const onFinish = async(value)=>{
      console.log(value)
      console.log("bChecked----", bChecked)
      if(!bChecked){
        message.error("请先查看《快盘平台开放协议》");
        return ;
      }

      if(userType == 9){
        value.enterpriseName = corpInfo.enterpriseName;
        value.licenseNum = corpInfo.licenseNum;
        value.licenseUrl = corpInfo.licenseUrl;
      }
      
      try {
          return await editUserinfo(value).then((res) => {
            console.log('editUserinfo-res-----------------', res);
            var flag = checkLogin(res.data)
            console.log('flag: ', flag);
            if (flag) {
              localStorage.removeItem("token");
              if(userType == 8){
                message.success("注册成功。请重新登录");
                window.location.href='/#/login';
                // window.location.href='/#/bcroom';
              }
              else
                window.location.href='/#/register/ok';
            }
          });
        } catch (error) {
          
          return false;
        }
  }
    

     // 上传前的检验,指定上传类型，判断大小等
     const beforeUpload = (file, fileList) => {
      // console.log(file?.name)
      let str = file?.name
      // console.log(str.substring(str.lastIndexOf(".")+1))
      return new Promise((resolve, reject) => {
        if (file?.size > 1024 * 1024 * 5) {
            message.error("请上传5M以内的文件");
            return reject(false);
        }else{
         const ext = str.substring(str.lastIndexOf(".")+1);
         if( ext!='png' && ext!='jpg'){
          message.error("请上传jpg或png的文件");
          return reject(false);
         }
        }
        return resolve(true);
      });
    };
    // 重点-上传方法
    const uploadFile = async (res,data) => {
      console.log('res ----------',res);
      console.log('data ----------',data);
  
      var reopt = {
        method : 'PUT',
        url : res.signatureUrl,
        withCredentials: false, 
        headers : res.requestHeaders || {},
        // headers: {
        //   'content-Type': '',
        //   // 'content-Type': 'multipart/form-data ',
        // }, 
  
        validateStatus: function(status){
                return status >= 200;
        },
        maxRedirects : 0,
        responseType : 'text',
        data : data.file,
        onUploadProgress: (data) => {
            let { total, loaded } = data;
            let percent = parseInt(loaded / total * 100) > 99 ? 100 : parseInt(loaded / total * 100);
            setpercent(percent)
        }
  
      };
      console.log('reopt --------- ', reopt)
  
      axios.request(reopt).then(function (response) {
          if(response.status < 300){                     
                console.log('Creating object using temporary signature succeed.');
                setFileUrl(res.fileUrl);
                form.setFieldValue('licenseUrl', res.fileUrl);
          }else{                     
            message.error("上传失败");
                console.log('Creating object using temporary signature failed!');                     
                console.log('status:' + response.status);                     
                console.log('\n');              
          }              
          console.log(response.data);              
          console.log('\n');
      }).catch(function (err) {
        message.error("上传失败！");
            console.log('Creating object using temporary signature failed22222222222222!');       
            console.log(err);       
            // console.log('\n');
      });
    }
  
    const upload = async (data) => {
      console.log('------data--------------', data)
      const file = data.file;
        setPercentShow(true)
        const { size, type, name ,lastModified} = file 
        const strfile = name+size+type+lastModified;
        // const spark = new SparkMD5()
        // spark.append(strfile)
        // const hash = spark.end() // 获取到的文件md5值
        const hash = CryptoJs.MD5(strfile).toString();
        var durl = hash+name.substring(name.lastIndexOf("."));
        console.log(durl);
  
        try {
          uploadTmpUrl({objectKey:durl}).then((res) => {
            console.log('uploadTmpUrl---------', res);
          var flag = checkLogin(res.data)
          if (flag) {
            uploadFile(res.data.data, data);
  
            // setFileInfo({hash:hash,name:name,size:size,durl:res.data.data.fileUrl});
          }
          else
            message.error(res.data.msg);
            
        });
        } catch (error) {
          // message.error(error);
        }
  
  
  
        // let lodeapp=await axios({
        //     method: 'POST',
        //     url: params.endpoint,
        //     headers: {
        //       'content-Type': 'multipart/form-data ',
        //     }, 
        //     data: fd,
        //     onUploadProgress: (data) => {
        //         let { total, loaded } = data;
        //         let percent = parseInt(loaded / total * 100) > 99 ? 100 : parseInt(loaded / total * 100);
        //         setpercent(percent)
        //     }
        // });
    }
  
      const uploadProps = {
        name: "file",
        accept: '.png, .jpg',
        beforeUpload: beforeUpload,
        onPreview: {handlePreview},
        multiple: false, 
        customRequest: upload,  //  重新定义上传方法
        onChange: (info) => {
          console.log('onChange------------', info)
          if (info.file.status === "done") {
            
          } else if (info.file.status === "error") {
            message.error(`${info.file.name} 上传失败.`);
          } else if (info.file.status === "removed") {
           
          }
          
        },
        onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
        },
      };
  
  

    
  return (
    console.log('1111111111111111111111111111-', step, userType),
    <div id='box_register'>
      <div style={{width:"100%",height:"100%"}} >
        <div style={{background:"rgb(63,140,255)", borderRadius:20,width:"21%",height:"100%",float:"left"}}>
          <div style={{textAlign:"left", paddingLeft:20,paddingTop:30}}><img src={[require("../admin/img/logo.jpg")]} alt="" width="60%" /></div>
          <div style={{color:"white", fontSize:"1.6em",fontWeight:550, marginTop:50, marginLeft:30, textAlign:"left"}}>注册账号</div>
          <Steps style={{marginTop:50,paddingLeft:20,color:"white"}}
            direction="vertical"
            size='small'
            // progressDot
            // current={1}
            items={[
              {
                title: '验证手机号',
                status: 'finish'
              },
              {
                title: '企业入驻信息',
                status: userType==9 ? ( step==9 ? 'process' : 'finish') : 'wait'
              },
              {
                title: '个人信息',
                status: step==8 ? 'process' : 'wait'
              },
              {
                title: '提交成功',
                status: 'wait'
              },
            ]}
          />
        </div>
        <Layout style={{background:"white", borderRadius:20,width:"77%",height:"100%",float:"right", display:step==9?'flex':'none'}} >
          <Layout style={{background:"transparent"}}>
            <h1 id= 'title' style={{marginBottom:0}}>企业入驻信息</h1>
            <div style={{color:"rgb(63,140,255)",marginBottom:20,fontWeight:550}}>基本信息</div>
            <Form  form={form}
            className='loginForm'
            name="basic"
            id="form_corpinfo"
            // labelCol={{span: 5}}
            // wrapperCol={{span: 16}}
            initialValues={{remember: true,}}
            onFinish={onCorpInfo}
            layout="vertical"
            // onFinishFailed={this.onFinishFailed}
            autoComplete="off"
            >
            <Form.Item
                label="单位名称（营业执照上的单位名称）"
                name="enterpriseName"
                rules={[
                    {
                    required: true,
                    message: '请输入单位名称！',
                    },
                ]}
            >
            <Input  />
            </Form.Item>
            <Form.Item
                label="统一社会信用代码"
                name="licenseNum"
                rules={[
                    {
                    required: true,
                    message: '请输入统一社会信用代码',
                    },
                ]}
            >
            <Input  />
            </Form.Item>
            <Form.Item label="营业执照"
                name="licenseUrl"
                rules={[
                    {
                    required: true,
                    message: '请上传营业执照！',
                    },
                ]} 
            >
              {/* <img src={"fileUrl"} width="100%" /> */}
                {/* <div style={{backgroundImage:"https://kptest.obs.cn-north-4.myhuaweicloud.com/3b44af6411e34fc3a4cccf43c12d578b.jpg"}}></div> */}
              <Dragger {...uploadProps} style={{borderRadius:10, }}>
              <div style={{display:fileUrl?"block":"none",padding:"0 10px", height:120}}>
                <img title="点击重新上传(5M以内的jpg/png文件)" src={fileUrl} style={{width:"100%", height:"100%", objectFit:"contain"}} />
              </div>
              <div style={{display:fileUrl?"none":"block",padding:10,height:120}}>
                <p className="ant-upload-drag-icon">
                  <PlusOutlined />
                </p>
                <p className="ant-upload-text">上传营业执照盖章扫描件</p>
                <p className="ant-upload-hint">
                  不超过5M（格式必须为jpg或png，参考范例）
                </p>
               </div>
              </Dragger>
            </Form.Item>
            <Progress percent={percent} style={{width:'100%',display:percentShow?'block':'none'}}/>
        </Form>
        </Layout>
        <Footer style={{background:"transparent",borderTop: "1px solid rgba(0, 0, 0, 0.06)",paddingTop:12,paddingBottom:12}}>
          {/* <Button type='link' size='small' style={{float:"left",boxShadow:"none"}}><ArrowLeftOutlined />上一步</Button> */}
          <Button type='primary' form='form_corpinfo' htmlType="submit" size='small' style={{float:"right"}} > 下一步<ArrowRightOutlined /></Button>
        </Footer>
        </Layout>

        <Modal open={previewOpen} title={previewTitle} centered footer={null} onCancel={handleCancel}>
          <img
            alt="example"
            style={{
              width: '100%',
            }}
            src={previewImage}
          />
        </Modal>

        <Layout style={{background:"white", borderRadius:20,width:"77%",height:"100%",float:"right", display:step==8?'flex':'none'}} >
          <Layout style={{background:"transparent"}}>
            <h1 id= 'title' style={{marginBottom:0}}>个人信息</h1>
            <div style={{color:"rgb(63,140,255)",marginBottom:20,fontWeight:550}}>联系人信息</div>
            <Form
            className='loginForm'
            name="basic"
            // labelCol={{span: 5}}
            // wrapperCol={{span: 16}}
            initialValues={{remember: true,}}
            onFinish={onFinish}
            layout="vertical"
            // onFinishFailed={this.onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="联系人姓名"
                name="contacts"
                rules={[
                    {
                    required: true,
                    message: '请输入联系人姓名',
                    },
                ]}
            >
            <Input  />
            </Form.Item>
            <Form.Item
                label="联系人邮箱"
                name="email"
                rules={[
                    {required: true, message: '请输入联系人邮箱',},
                    {pattern: /^[a-zA-Z0-9_-]+@([a-zA-Z0-9]+\.)+(com|cn|net|org)$/, message: '邮箱格式错误'},
                ]}
            >
            <Input  />
            </Form.Item>
            <Form.Item
                label="联系人手机号"
                name="mobile"
                rules={[
                    {required: true, message: '请输入联系人手机号',},
                    {pattern: /^1\d{10}$/, message: '手机号格式错误'},
                ]}
            >
            <Input  />
            </Form.Item>
            <Form.Item >
            <Checkbox style={{fontSize:13}} name="agree" checked={bChecked} onChange={onCheck} >我同意</Checkbox>
            <Button size='small' style={{ boxShadow:"none",fontSize:13}} type="link" 
              onClick={()=>{
                setChecked(true);
                setShowAgreement(true);
              }}>《快盘平台开放协议》</Button>
            </Form.Item>
            <Form.Item style={{display:userType==8 ? 'block' : 'none'}}>
              <Button type='primary' htmlType="submit" style={{padding:"0 20px"}} > 提交 </Button>
            </Form.Item>
            <Form.Item style={{display:userType==9 ? 'block' : 'none'}}>
              <Space size={50} style={{marginTop:25}}>
              <Button type='link' style={{padding:"0 20px"}} onClick={()=>{setStep(9)}} >上一步</Button>
              <Button type='primary' htmlType="submit" style={{padding:"0 20px"}} > 提交 </Button>
              </Space>
            </Form.Item>
        </Form>
        </Layout>
        <Footer style={{background:"transparent",borderTop: "1px solid rgba(0, 0, 0, 0.06)"}}>
          {/* <Divider style={{width:"100%",color:"red"}} /> */}
        </Footer>
        </Layout>


        </div>

        <Modal
            open={showAgreement}
            title="快盘平台开放协议"
            centered
            onCancel={()=>{setShowAgreement(false)}}
            destroyOnClose={true}
            footer={[]}
        >
        <div>快盘平台开放协议内容</div>
        </Modal>
    </div>
  )
}
export default RegisterInfo
