import { LaptopOutlined, NotificationOutlined, UserOutlined,DownloadOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu,Input,Select,Card,Space,Col, Row ,Button,Form,message  } from 'antd';
import React, {useState} from 'react';
import CryptoJs from 'crypto-js';
import checkLogin from '../../request/checkLogin.js'
import {updatePassword} from './service.js'
import { encrypt,decrypt,localStorageSet,localStorageGet } from '../../utils/global.js';

import './index.css';

const { Header, Content, Sider } = Layout;

const Account = (props) => {

  const [form] = Form.useForm();
  const {currentUser} =props;
  const [password,setpassword] = useState('');

  const currentUserinfo = JSON.parse(decrypt(localStorageGet('UserInfo')));
  console.log('-------maccount---currentUserinfo-------', currentUserinfo);

  const onFinish =async (values) => {
      if(!values) return false;
      console.log(values);
      try {
          await updatePassword({
          oldPassword: values.oldPassword,
          password: values.password
          }).then((res) => {
            var flag = checkLogin(res.data)
            if (flag) {
              localStorage.removeItem('token');
              localStorage.removeItem("UserInfo");
              message.success('操作成功，请重新登陆！');
              window.location.href='/#/login';
            }
            return true;
          });
        } catch (error) {
          message.error('操作失败，请重试');
          return false;
        }
    };
  
    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };

    const passwordSetting =(e)=>{
      setpassword(e.target.value)
    }
    const validatePassword = (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入密码'));
      } else {
      callback();
      }
    };

    const validatePass = (rule, value, callback)=>{
      if (value === '') {
          // callback(new Error('请再次输入密码'));
          return new Promise((resolve, reject) => {
              reject('请再次输入密码')
            });
        } else if (value !== password) {
          // callback(new Error('两次输入密码不一致!'));
          return new Promise((resolve, reject) => {
              reject('两次输入密码不一致!')
            });
        } else {
          return Promise.resolve()
        }
    }

    const resetForm =()=>{
      form.resetFields();
    }
   

  return (
        <Content
          className="site-layout-background"
          style={{
            padding: 10,
            margin: 0,
            minHeight: "100%",
          }}
        >
      <div className='titleClass' style={{width:100,minHeight: 30,marginBottom:10,fontSize:24,fontWeight:600,fontFamily:"PingFangSC-Regular"}}>账号管理</div>
      <div style={{padding:"20px 0 0 110px"}}>
        <div style={{marginBottom:10,textAlign:"left",fontWeight:550}}>账户信息</div>
        <span>用户名：{currentUserinfo.userName}</span>
        <div style={{marginTop:30,marginBottom:10,textAlign:"left",fontWeight:550}}>密码设置</div>
        <div style={{display:'flex',alignItems: 'center',justifyContent: 'center',width:'100%'}}>
        <div style={{width:'400px',height:'400px'}}>
        <Form
            name="basic"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            style={{marginTop:'15px'}}
            labelCol={{
                span: 4,
              }}
            >
            <Form.Item
                label="旧密码"
                name="oldPassword"
                rules={[
                {
                    required: true,
                    message: '请输入旧密码',
                },
                ]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item
                label="新密码"
                name="password"
                rules={[
                { validator: validatePassword, trigger: 'blur' },
                {required: true,message: '请输入新密码',},
                {pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,message: '密码为必须包含字母和数字的6-20位字符',},
                // {min:8,max:20,message:'密码长度在8-20位',trigger:'blur'},
                // {
                //     pattern:/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,.\/]).{7,19}$/,
                //     message:'密码必须包含字母，数字，特殊字符',
                // }
                ]}
            >
                <Input.Password onChange={passwordSetting}/>
            </Form.Item>
            <Form.Item
                label="确认密码"
                name="passwordNew"
                rules={[
                    { validator: validatePass, trigger: 'blur' }
                ]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item
                wrapperCol={{
                span: 16,
                }}
            >
              <div style={{marginTop:50,textAlign:"center",width:400}}>
                <Button type="primary" htmlType="submit" shape='round' >
                确认
                </Button>
                <Button  shape='round' htmlType="button"  onClick={resetForm} className="m-btn-white" style={{ marginLeft:'50px'}}>
                重置
                </Button>
              </div>
            </Form.Item>
            </Form>
        </div>
        </div>
      </div>

      </Content>);

};
export default Account;