import { Input,Select,Button,Modal,Form,message,Checkbox,Drawer,Table  } from 'antd';
import React, { useState,useEffect }  from 'react';
import {memberScene,menuOptions,memberPhone,memberAdd,memberDetail,memberUpdate} from '../service';
import checkLogin from '../../../request/checkLogin.js'

var apiReqBody = {
  // "id": curClientid,
  // "domesticEnterprise": {
  //     "enterpriseId": "",
  //     "enterpriseName": ""
  // },
  // "overseaEnterprise": {
  //     "enterpriseId": "",
  //     "enterpriseName": ""
  // },
  // "cloudPhones": {}
};
var allAuthoritys = {};

// import './index.css';
const App = (props) => {
  const {primaryAccount, curClientid, showEditclient, closEditclient} = props;
  const [form] = Form.useForm();

  const columns = [
    {
      title: '云手机序号',
      dataIndex: 'cloudPhoneNum',
    },
    {
      title: '状态',
      dataIndex: 'deviceStatus',
      valueType: 'select',
      valueEnum: {
        1: {
          text: ('空闲'),
          status: 'Processing',
        },
        2: {
          text: ('占用中'),
          status: 'Success',
        },
        // 3: {
        //   text: ('锁定'),
        //   status: 'Default',
        // },  
        4: {
          text: ('离线'),
          status: 'Default',
        },
        5: {
          text: ('重启中'),
          status: 'Warning',
        },
        7: {
          text: ('重置中'),
          status: 'Warning',
        }, 
      },

    },
    {
      title: '节点名称',
      dataIndex: 'nodeName',
    },
    {
      title: '国家',
      dataIndex: 'icon',
      render: (text) => <img src={text} width={30} />,
    },
  ];

  const [sceneList,setSceneList] = useState([]);
  const [curScene,setCurScene] = useState("");
  const [showAssCorp,setShowAssCorp] = useState(false);
  const [showAssPhone,setShowAssPhone] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [phoneList, setPhoneList] = useState([]);
  const [sceneChecked, setSceneChecked] = useState({});
  const [selectedRowKeys,setSelectedPhoneKeys] = useState([]);

  const [authority,setAuthority] = useState([]);
  // const [curAuthority,setCurAuthority] = useState([]);
  const [menuIds,setMenuIds] = useState([]);
  const [authorityDisable,setAuthorityDisable] = useState(true);

  const onClose = () => {
    setShowAssCorp(false)
  }

  const onCloseAssPhone = () => {
    setShowAssPhone(false)
  }
  // 获取使用场景Options
  const getScene = async () => {
    if(!showEditclient)
      return;
    var params = {};
    if(primaryAccount)
      params.primaryAccount = primaryAccount;
    try {
      await memberScene(params).then((res) => {
        // console.log('memberScene---------', res);
      var flag = checkLogin(res.data)
      if (flag) {
        var list = [];
        res.data.data.forEach((item)=>{
          list.push({value:item.sceneKey, label:item.sceneName})
        })
        setSceneList(list);
        // console.log('-------------11111111111111---------', sceneList)
      }
      else
        message.error(res.data.msg);
    });
    } catch (error) {
      message.error(error);
    }
  };

  // 获取菜单Options
  const getMenusList = async () => {
    var params = {};
    if(primaryAccount)
      params.primaryAccount = primaryAccount;

    try {
      await menuOptions(params).then((res) => {
        // console.log('menuOptions---------', res);
      var flag = checkLogin(res.data)
      if (flag) {
        // setAuthority(res.data.data);
        allAuthoritys = res.data.data;
        if(curClientid)
          getMemberInfo();
        else
          form.resetFields();
      }
      else
        message.error(res.data.msg);
    });
    } catch (error) {
      message.error(error);
    }
  };

  // 获取成员详情
  const getMemberInfo = async () => {
    if(!curClientid)
      return;
    try {
      await memberDetail({id:curClientid}).then((res) => {
      // console.log('memberDetail---------', res);
      var flag = checkLogin(res.data)
      if (flag) {
        apiReqBody = res.data.data;
        // console.log('apiReqBody----1111111111111-----', apiReqBody);

        form.setFieldValue("mobile", apiReqBody.mobile);
        form.setFieldValue("password", "");
        form.setFieldValue("sceneKey", apiReqBody.sceneKey);
        onCheckScene(apiReqBody.sceneKey);
        form.setFieldValue("menuIds", apiReqBody.menuIds);
        onChangeAuthority(apiReqBody.menuIds);
        setSelectedPhoneKeys(apiReqBody.cloudPhoneNums);
      }
      else
        message.error(res.data.msg);
    });
    } catch (error) {
      message.error(error);
    }
  };

  // 页面初始化
  useEffect(() => {
    if(showEditclient){
      getScene();
      getMenusList();
      // if(curClientid)
      //   getMemberInfo();
      // else
      //   form.resetFields();
    }else{
      apiReqBody = {};
      setSceneList([]);
      setCurScene("");
      setShowAssCorp(false);
      setShowAssPhone(false);
      setCheckedKeys([]);
      setPhoneList([]);
      setSceneChecked({});
      setSelectedPhoneKeys([]);
      setAuthorityDisable(true);
    }

  }, [showEditclient]);

  // 获取关联云手机列表
  const getAssPhones = (scene) => {
    if(!scene)
      return;
    var params = {sceneKey:scene};
    if(primaryAccount)
      params.primaryAccount = primaryAccount;
  
    try {
      memberPhone(params).then((res) => {
        // console.log('memberPhone---------', res);
      var flag = checkLogin(res.data)
      if (flag) {
        setPhoneList(res.data.data);
      }
      // else
        // message.error(res.data.msg);
    });
    } catch (error) {
      // message.error(error);
    }
  };

  // 选择场景
  const onCheckScene = (checkedValues) => {
    console.log("onCheckScene onCheckScene---------",checkedValues);
    console.log("onCheckScene allAuthoritys---------",allAuthoritys);
    setSelectedPhoneKeys([]);
    form.setFieldValue("menuIds", []);
    onChangeAuthority([]);
    if(allAuthoritys[checkedValues]){
      setAuthority(allAuthoritys[checkedValues]);
      setAuthorityDisable(false);
    }
    else{
      setAuthorityDisable(true);
    }
    
    // if(checkedValues == "BREEDING_BOARD"){
    //   setAuthorityDisable(false);
    // }
    // else{
    //   form.setFieldValue("menuIds", []);
    //   onChangeAuthority([]);
    //   setAuthorityDisable(true);
    // }
  };

  // 点击分配云手机
  const funAssPhone = () => {
    var scene = form.getFieldValue("sceneKey");
    // console.log(scene);
    if(!scene){
      message.error("请先选择使用场景");
      return;
    }
    getAssPhones(scene);
    setShowAssPhone(true);
  };

  // 选择云手机
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedPhoneKeys(selectedRowKeys);
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      // apiReqBody['cloudPhones'][curScene] = selectedRows;
      // console.log('apiReqBody----444444444444444444-------', apiReqBody)
    },

    getCheckboxProps: (record) => ({
      // disabled: record.name === 'Disabled User',
      // // Column configuration not to be checked
      // name: record.name,
    }),
  };
  // 点击权限配置
  const onChangeAuthority = (checkedValues) => {
    // console.log('checked = ', checkedValues);
    setMenuIds(checkedValues);
  };

  // 添加/修改成员
  const onFinish = (value) => {
    // console.log('value------',value);
    value.cloudPhoneNums = selectedRowKeys;
    if(primaryAccount)
      value.primaryAccount = primaryAccount;

    var fun = ""
    if(curClientid){
      fun = memberUpdate;
      value.id = curClientid;
      delete value.mobile;
      delete value.password;
    }
    else{
      fun = memberAdd;
    }

    try {
      // apiReqBody['id'] = curClientid;
      fun(value).then((res) => {
        // console.log(fun+'---------', res);
      var flag = checkLogin(res.data)
      if (flag) {
        closEditclient();
        message.success("操作成功");
      }
      // else
        // message.error(res.data.msg);
    });
    } catch (error) {
      // message.error(error);
    }
  };

  return (
    <div>
    <Modal 
        // key="changeparam"
        title={curClientid?'修改成员':'新增成员'}
        open={showEditclient}
        // open={showEditclient}
        width="400px"
        // style={{top:50}}
        centered
        footer={[<Button htmlType="button"  onClick={closEditclient} className="m-btn-white">
        关闭
        </Button>,
        <Button type="primary"  style={{ marginLeft:'20px'}} onClick={()=>{form.submit()}}>
        确认
        </Button>] }// 设置footer为空，去掉 取消 确定默认按钮
        onCancel={closEditclient}>   
          <Form form={form}
            // labelCol={{
            //   span: 5,
            // }}
            initialValues={{
            }}
            layout="vertical"
            onFinish={onFinish}
          >
              <Form.Item
                label="手机号"
                name="mobile"
                rules={[
                  {required: true, message: '请输入手机号',},
                  {pattern: /^1[3-9]\d{9}$/, message: '手机号格式错误'},
                ]}
                style={{marginBottom:10}}
            >
            <Input placeholder="" disabled={curClientid?true:false} />
            </Form.Item>
            <Form.Item
                label="密码"
                name="password"
                rules={[
                  {required: true,message: '请输入密码',},
                  {pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,message: '密码为必须包含字母和数字的6-20位字符',},
                ]}
                style={{marginBottom:10,display:curClientid?"none":"block"}}
            >
            <Input.Password  placeholder="" />
            </Form.Item>
            <Form.Item 
              label='选择使用场景' 
              name="sceneKey"  
              style={{marginBottom:10}}
              rules={[
                {required: true,message: '请选择使用场景',},
              ]}
            >
              <Select
                className='ant-select-selector'
                placeholder="请选择使用场景"
                options={sceneList}
                // defaultValue={apiReqBody.sceneKey}
                onChange={onCheckScene}
              />
            </Form.Item>
            <Form.Item
                label="权限配置" 
                name="menuIds"
                style={{marginBottom:10, display:authorityDisable?"none":"block"}}
            >
              <Checkbox.Group
                options={authority}
                // value={menuIds}
                onChange={onChangeAuthority}
              />
            </Form.Item>
            <Button className='m-button-blue'  onClick={()=>{funAssPhone()}}>分配云手机</Button>

          </Form>   
    </Modal>

    <Drawer
        title="分配云手机"
        placement="right"
        width={500}
        closable={false}
        onClose={onCloseAssPhone}
        // visible={visibleTree}
        open={showAssPhone}
    >
      <Table
        rowSelection={{
          type: "Checkbox",
          ...rowSelection,
        }}
        columns={columns}
        dataSource={phoneList}
        rowKey="cloudPhoneNum"
      />
        
    </Drawer>
  </div>
);
}
export default App;