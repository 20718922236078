import http from "../../request/http";
import CryptoJs from 'crypto-js';

// 客户管理列表
export const getCustomerList = (data) =>{
    return http ({
        method:'POST',
        url:'operate/customer/page',
        data
    })
}
// 启用客户
export const customerEnable = (data) =>{
    return http ({
        method:'POST',
        url:'operate/customer/enable',
        data
    })
}
// 禁用客户
export const customerDisable = (data) =>{
    return http ({
        method:'POST',
        url:'operate/customer/disable',
        data
    })
}
// 删除客户
export const customerDelete = (data) =>{
    return http ({
        method:'POST',
        url:'operate/customer/delete',
        data
    })
}
// 审核管理列表
export const getReviewedList = (data) =>{
    return http ({
        method:'POST',
        url:'operate/reviewed/page',
        data
    })
}
// 审核详情
export const getReviewDetail = (data) =>{
    return http ({
        method:'POST',
        url:'operate/review/detail',
        data
    })
}
// 审核通过
export const reviewPass = (data) =>{
    return http ({
        method:'POST',
        url:'operate/review/paas',
        data
    })
}
// 审核不通过
export const reviewNopass = (data) =>{
    return http ({
        method:'POST',
        url:'operate/review/no/paas',
        data
    })
}
// 获取使用场景Options
export const clientScene = (data) =>{
    return http ({
        method:'POST',
        url:'operate/customer/scene/options',
        data
    })
}
// 查询客户企业options
export const enterpriseOptions = (data) =>{
    return http ({
        method:'POST',
        url:'operate/customer/enterprise/options',
        data
    })
}
// 查询客户云手机options
export const CloudPhonesOptions = (data) =>{
    return http ({
        method:'POST',
        url:'operate/customer/enterprise/cloud/phones',
        data
    })
}
// 查询客户详情
export const customerDetail = (data) =>{
    return http ({
        method:'POST',
        url:'operate/customer/detail',
        data
    })
}
// 客户修改
export const customerUpdate = (data) =>{
    return http ({
        method:'POST',
        url:'operate/customer/update',
        data
    })
}
// 新增客户
export const customerAdd = (data) =>{
    if(data.password)
        data.password = CryptoJs.MD5(data.password).toString();
    return http ({
        method:'POST',
        url:'operate/customer/create',
        data
    })
}
// 修改客户类型
export const customerChangetype = (data) =>{
    return http ({
        method:'POST',
        url:'operate/customer/type/update',
        data
    })
}
