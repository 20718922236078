import Icon,{ LaptopOutlined, NotificationOutlined, UserOutlined,DownloadOutlined,DesktopOutlined,MenuOutlined,PictureOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu,Input,Select,Card,Space,Col, Row ,Button ,Table } from 'antd';
import React , { useRef, useState,useEffect }from 'react'
import { ProList } from '@ant-design/pro-components';
import './index.css';
import {operateLog} from './service.js';
import {customerOptions} from '../bcroom/service.js';
import checkLogin from "../../request/checkLogin.js"
import CustomProList from '../components/CustomProList';
import { encrypt,decrypt,localStorageSet,localStorageGet } from '../../utils/global.js';

const { Header, Content, Sider } = Layout;

const App = props => {
  const currentUserinfo = JSON.parse(decrypt(localStorageGet('UserInfo')));
  const actionRef = useRef();

  const [loading_checklist,setChecklistloading] = useState(false);
  const [showSelectClient, setShowSelectClient] = useState("none");
  const [clientList, setClientList] = useState([]);
  const [primaryAccount,setPrimaryAccount] = useState("")

  const columsName = {
    'operName' : {
      title:'操作人员', 
      width:'16%'
    },
    'title' : {
      title:'系统模块', 
      width:'13%'
    },
    'businessType' : {
      title:'操作', 
      width:'13%'
    },
    'operLocation' : {
      title:'操作地点', 
      width:'16%'
    },
    'status' : {
      title:'操作状态', 
      width:'10%',
      valueEnum: {
        0: {
          text: '正常',
          status: 'success',
        },
        1: {
          text: '异常',
          status: 'error',
        },
      },
    },
    'toName' : {
      title:'操作对象', 
      width:'16%'
    },
    'operTime' : {
      title:'操作时间', 
      width:'16%'
    },
    // 'op' : '操作',
  };

  // 页面初始化
  useEffect(() => {
    if(currentUserinfo.userType == 99)
      setShowSelectClient("block");
    // else
    //   setCanAddmember(true);

    // 获取客户列表
    try {
      customerOptions({}).then((res) => {
      // console.log('customerOptions-res-----------------', res);
      var flag = checkLogin(res.data)
      // console.log('flag: ', flag);
      if (flag) {
        setClientList(res.data.data)
      }
      // else
      //   message.error(res.data.msg);
    });
    } catch (error) {
      // message.error(error);
    }

  }, []);
  
  const getOpList = async (params) =>{
    params.pageNo = params.current;
    delete params.current;
    if(params.status)
      params.status = parseInt(params.status);
    var result = {};
    if(primaryAccount)
      params.primaryAccount = primaryAccount;
    try {
      await operateLog(params).then((res) => {
        // console.log('operateLog-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          result = res.data.data;
          // console.log('result-----', result);
          return result;
        }
        // else
        //   message.error(res.data.msg);
      });
    } catch (error) {
      // message(error);
    }
    return result;
  }
  
  const onChangClient = (e) => {
    // console.log(e);
    // getDeviceList({userId:e});
    setPrimaryAccount(e);
    // setCanAddmember(true);
    actionRef.current.reload();
  }

  return (
    <Content
      className="site-layout-background"
      style={{
        padding: 10,
        margin: 0,
        minHeight: "100%",
      }}
    >
      <Row align="middle">
      <Col span={16} className='titleClass'>
        操作记录
      </Col>
      <Col span={4} className='selectClient' style={{display:showSelectClient}}>
        选择客户 
      </Col>
      <Col span={4} style={{display:showSelectClient}}>
        <Select
          placeholder="请选择"
          className='ant-select-selector'
          options={clientList}
          onChange={onChangClient}
          allowClear
        />
    </Col>
    </Row>

    <div>
          
    <ProList rowKey="id" 
        search={{
          filterType: 'light',
        }}
        pagination={{ defaultPageSize: 10, showSizeChanger: true, }}
        loading={loading_checklist}
        actionRef={actionRef}
        // dataSource={dataSource} 
        request={async (params = {})  => {
          setChecklistloading(true);
          var tableData = [];
          // console.log('params----------------', params);
          const res = await getOpList(params);
          // console.log('res--------------', res);
          if(res.rows){
            res.rows.map((v,k)=>{
              // console.log('v--------------', v);
              tableData[k] = v;
              tableData[k]['content'] = {...v};
            })
          }
          // console.log('tableData--------------', tableData);
          setChecklistloading(false);
          return {
              data: tableData,
              // current: res.data.pageNo,
              // pageSize: res.data.pageSize,
              success: true,
              // total: res.data.totalRows,
          };
        }}
        metas={{
          operName: {
              // dataIndex: 'name',
              title: '操作人员',
          },
          title: {
            // 自己扩展的字段，主要用于筛选，不在列表中显示
            title: '系统模块',
            // dataIndex: 'scene',
            valueType: 'select',
            valueEnum: {
              "直播舱": {
                text: '直播舱',
                status: 'success',
              },
              "养号舱": {
                text: '养号舱',
                status: 'error',
              },
              "无人直播舱": {
                text: '无人直播舱',
                status: 'success',
              },
              // "素材管理": {
              //   text: '素材管理',
              //   status: 'error',
              // },
              "APP管理": {
                text: 'APP管理',
                status: 'error',
              },
              "成员管理": {
                text: '成员管理',
                status: 'error',
              },
              "客户管理": {
                text: '客户管理',
                status: 'error',
              },
              "审核管理": {
                text: '审核管理',
                status: 'error',
              },
            },
          },
          status: {
            // 自己扩展的字段，主要用于筛选，不在列表中显示
            title: '操作状态',
            // dataIndex: 'status',
            valueType: 'select',
            valueEnum: {
              0: {
                text: '正常',
                status: 'success',
              },
              1: {
                text: '异常',
                status: 'error',
              },
            },
          },

          content: {
              dataIndex: 'content',
              search: false,
              render: (text) => (
                <CustomProList 
                  columsName = {columsName}
                  text = {text}
                />
              ),
          },

        }} 
        />

        {/* <Table dataSource={dataSource} columns={columns} />; */}

        </div>
        </Content>

);
        }
export default App;