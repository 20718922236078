import Icon,{ LoadingOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu,Input,Select,Card,Space,Col, Row ,Button,Modal,Form,Radio,message,Checkbox,Drawer,Tree,Table  } from 'antd';
import React, { Component,useState,useEffect }  from 'react';
import {clientScene,enterpriseOptions,CloudPhonesOptions,customerDetail,customerUpdate} from '../service';
import checkLogin from '../../../request/checkLogin.js'
import { render } from '@testing-library/react';

const { TextArea,Search  } = Input;

var apiReqBody = {
  // "id": curClientid,
  // "domesticEnterprise": {
  //     "enterpriseId": "",
  //     "enterpriseName": ""
  // },
  // "overseaEnterprise": {
  //     "enterpriseId": "",
  //     "enterpriseName": ""
  // },
  // "cloudPhones": {}
};
var allCorInfo = {};

// import './index.css';
var phonelist = {"code":0,"msg":"成功","ts":1675146638931,"data":[{"deviceId":"96508F6B44FCEF184153C395EE30B888","deviceNum":"VM010166140094","nodeName":"001","source":0,"city":"香港(地区)","icon":"https://kp-photo.obs.cn-east-3.myhuaweicloud.com/node/city/5aaab17bd70c4c4a8856ae4c4badcb4b.png","enterpriseId":"F3DB457B87044E08BCDF67423D653F4D"},{"deviceId":"1A5CF92609A1C39DD49D4128C61E73A0","deviceNum":"VM010166140097","nodeName":"001","source":0,"city":"新加坡","icon":"https://kp-photo.obs.cn-east-3.myhuaweicloud.com/node/city/25764f338fd44ee79bc3c467878bde61.png","enterpriseId":"F3DB457B87044E08BCDF67423D653F4D"},{"deviceId":"8166C7E587E21EA2462346BD665146EB","deviceNum":"VM010166140098","nodeName":"001","source":0,"city":"日本","icon":"https://kp-photo.obs.cn-east-3.myhuaweicloud.com/node/city/364864f897dd40fbb7a0bff07fc18c9f.png","enterpriseId":"F3DB457B87044E08BCDF67423D653F4D"}]}
const App = (props) => {
  const {curClientid, curClientInfo, showEditclient, closEditclient} = props;
  const [form] = Form.useForm();

  const corpType = ["domesticEnterprises", "overseaEnterprises"];

  const initCorp = [
    {
      "key": corpType[0],
      "title": "国内",
      "children": [],
      disableCheckbox: true,
    },
    {
      "key": corpType[1],
      "title": "海外",
      "children": [],
      // "children": [{key:'test', title:'testcorp'},{key:'test2', title:'testcorp2'}],
      disableCheckbox: true,
    },
  ];

  const columns = [
    {
      title: '云手机编号',
      dataIndex: 'deviceNum',
    },
    {
      title: '节点名称',
      dataIndex: 'city',
    },
    {
      title: '国家',
      dataIndex: 'icon',
      render: (text) => <img src={text} width={30} />,
    },
  ];

  const [sceneList,setSceneList] = useState([]);
  const [curScene,setCurScene] = useState("");
  const [showAssCorp,setShowAssCorp] = useState(false);
  const [showAssPhone,setShowAssPhone] = useState(false);
  const [corpList,setCorpList] = useState(initCorp);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [phoneList, setPhoneList] = useState([]);
  const [sceneChecked, setSceneChecked] = useState({});
  const [selectedRowKeys,setSelectedPhoneKeys] = useState([]);

  const onClose = () => {
    setShowAssCorp(false)
  }

  const onCloseAssPhone = () => {
    setShowAssPhone(false)
  }
  // 获取使用场景Options
  const getScene = async () => {
    if(!showEditclient)
      return;
    try {
      await clientScene({}).then((res) => {
        // console.log('clientScene---------', res);
      var flag = checkLogin(res.data)
      if (flag) {
        setSceneList(res.data.data);
        var check = {};
        res.data.data.forEach((item)=>{
          check[item.sceneKey] = false;
          if(curClientInfo.hasOwnProperty("cloudPhones"))
          {
            if(curClientInfo.cloudPhones.hasOwnProperty(item.sceneKey))
              check[item.sceneKey] = true;
          }
        })
        setSceneChecked({...check});
        // console.log('-------------11111111111111---------', sceneChecked)
      }
      // else
      //   message.error(res.data.msg);
    });
    } catch (error) {
      message.error(error);
    }
  };

  // 获取关联企业列表
  const getAssCorp = async() => {
    if(!curClientid)
      return;
    try {
      await enterpriseOptions({id:curClientid}).then((res) => {
        // console.log('enterpriseOptions---------', res);
      var flag = checkLogin(res.data)
      if (flag) {
        // setSceneList(res.data.data);
        var rlist = initCorp;
        initCorp.map((item, index)=>{
          res.data.data[item['key']].map((v,k)=>{
            rlist[index].children.push({"key":v.enterpriseId,"title":v.enterpriseName})
            allCorInfo[v.enterpriseId] = {"corpKey":v.corpKey,"enterpriseName":v.enterpriseName};
          })
        })
        // console.log('rlist----111----------', rlist)
        //添加当前企业并选中
        var select = [];
        corpType.map((item, key)=>{
          if(curClientInfo.hasOwnProperty(item)){
            if(curClientInfo[item].enterpriseId){
              select.push(curClientInfo[item].enterpriseId);
              rlist[key].children.push({"key":curClientInfo[item].enterpriseId,"title":curClientInfo[item].enterpriseName});
            }
          }
        })
        // console.log('rlist----222----------', rlist)
        setCheckedKeys(select);
        setCorpList(rlist);
      }
      // else
      //   message.error(res.data.msg);
    });
    } catch (error) {
      message.error(error);
    }
  };

  // 初始显示客户详情
  const showClientInfo = async () => {
    apiReqBody = curClientInfo;

    // console.log('apiReqBody----1111111111111-----', apiReqBody);
    // setClientInfo(apiReqBody);
    // 显示企业名称
    var corpName = "";
    Object.keys(apiReqBody).forEach((item)=>{
      if(apiReqBody[item]['enterpriseName']){
        if(corpName)
          corpName += "; ";
        corpName += apiReqBody[item]['enterpriseName'];
      }
    })
    // console.log('corpName---------', corpName);
    form.setFieldValue("corpName", corpName);
  };

  // 页面初始化
  useEffect(() => {
    if(showEditclient){
      getScene();
      getAssCorp();
      showClientInfo();
    }else{
      apiReqBody = {};
      setSceneList([]);
      setCurScene("");
      setShowAssCorp(false);
      setShowAssPhone(false);
      setCorpList(initCorp);
      setCheckedKeys([]);
      setPhoneList([]);
      setSceneChecked({});
      setSelectedPhoneKeys([]);
    }

  }, [showEditclient]);

  // 获取关联云手机列表
  const getAssPhones = () => {
    if(!curClientid)
      return;
    var param = {};
    Object.keys(apiReqBody).forEach((item)=>{
      if(apiReqBody[item]['enterpriseId'])
        param[item] = apiReqBody[item];
    })
    try {
      CloudPhonesOptions(param).then((res) => {
        // console.log('CloudPhonesOptions---------', res);
      var flag = checkLogin(res.data)
      if (flag) {
        setPhoneList(res.data.data);
      }
      // else
        // message.error(res.data.msg);
    });
    } catch (error) {
      // message.error(error);
    }
  };

  // 选择企业
  const onCheckCorp = (checkedKeysValue, event) => {
    // console.log('onCheckCorp', checkedKeysValue, event);
    var select_pos = {};
    var select = [];
    var corpName = "";

    corpType.forEach((item)=>{
      apiReqBody[item].enterpriseId = null;
      apiReqBody[item].enterpriseName = null;
      apiReqBody[item].corpKey = null;
    })

    event.checkedNodesPositions.forEach((item)=>{
      var posArr = item.pos.split('-');
      // console.log(posArr);
      select_pos[posArr[1]] = item.node.key;
      apiReqBody[corpType[posArr[1]]] = {"enterpriseId":item.node.key, "enterpriseName":item.node.title, "corpKey":allCorInfo[item.node.key].corpKey};
    });
    // console.log('select_pos-----------',select_pos);
    // console.log('apiReqBody-----33333333333333------',apiReqBody);
    Object.keys(select_pos).map((val, index) => {
      select.push(select_pos[val]);
    })
    // console.log('select-------------------', select);
    setCheckedKeys(select);

    // 企业名称显示
    corpType.forEach((item)=>{
      if(apiReqBody[item].enterpriseName){
        if(corpName)
          corpName += "; ";
        corpName += apiReqBody[item].enterpriseName
      }
    })
    form.setFieldValue("corpName", corpName);

    // getAssPhones();
    // 清空权限
    var scene = {};
    sceneList.forEach((item)=>{
      if(apiReqBody.cloudPhones[item.sceneKey])
        scene[item.sceneKey] = [];
    });
    apiReqBody.cloudPhones = scene;
  };

  // 选择场景
  const onCheckScene = (e) => {
    // console.log("sceneChecked---------",sceneChecked)
    // console.log("onCheckScene---------",e)
    var check = sceneChecked;
    check[e.target.value] = e.target.checked;
    setSceneChecked({...check});
    // console.log("sceneChecked---9999999999999------",sceneChecked)
    
    if(!apiReqBody['cloudPhones'])
      apiReqBody['cloudPhones'] = {}
    if(e.target.checked)
      apiReqBody['cloudPhones'][e.target.value] = [];
    else
      delete apiReqBody['cloudPhones'][e.target.value];
    // console.log("apiReqBody---22222222222222222222------",apiReqBody)
  };

  // 点击分配云手机
  const funAssPhone = (scene) => {
    var haveCorp = false;
    // console.log('------------aaaaaaaaaaaaaaaaaaaaaaa-',apiReqBody);
    Object.keys(apiReqBody).forEach((item)=>{
      if(apiReqBody[item]['enterpriseId'])
        haveCorp = true;
    })
    if(!haveCorp)
      message.error("请先关联企业");
    else{
      getAssPhones();
      setCurScene(scene);
      // console.log(scene);
      // console.log('---------dddddddddddddddddddddddd-',apiReqBody);
      // console.log(apiReqBody['cloudPhones'][scene]);
      if(apiReqBody['cloudPhones']){
        if(apiReqBody['cloudPhones'][scene]){
          var list = [];
          apiReqBody['cloudPhones'][scene].forEach((item)=>{
            list.push(item.deviceId);
          })
          setSelectedPhoneKeys(list);
        }
        else
          setSelectedPhoneKeys([]);
      }
      setShowAssPhone(true);
      // console.log(selectedRowKeys);
    }
  };

  // 选择云手机
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedPhoneKeys(selectedRowKeys);
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      apiReqBody['cloudPhones'][curScene] = selectedRows;
      // console.log('apiReqBody----444444444444444444-------', apiReqBody)
    },

    getCheckboxProps: (record) => ({
      // disabled: record.name === 'Disabled User',
      // // Column configuration not to be checked
      // name: record.name,
    }),
  };

  // 修改客户信息
  const onFinish = () => {
    if(!curClientid)
      return;
    // console.log('finish---------', apiReqBody);
    var arr = Object.keys(apiReqBody.cloudPhones);
    if(arr.length == 0){
      message.error("请至少选择一个场景");
      return;
    }
    try {
      // apiReqBody['id'] = curClientid;
      customerUpdate(apiReqBody).then((res) => {
        // console.log('customerUpdate---------', res);
      var flag = checkLogin(res.data)
      if (flag) {
        closEditclient();
        message.success("修改成功");
      }
      // else
        // message.error(res.data.msg);
    });
    } catch (error) {
      // message.error(error);
    }
  };

  return (
    <div>
    <Modal 
        // key="changeparam"
        title='修改'
        open={showEditclient}
        centered
        // width="350px"
        footer={[<Button htmlType="button"  onClick={closEditclient} className="m-btn-white">
        关闭
        </Button>,
        <Button type="primary"  style={{ marginLeft:'20px'}} onClick={()=>onFinish()}>
        确认
        </Button>] }// 设置footer为空，去掉 取消 确定默认按钮
        onCancel={closEditclient}>   
          <Form form={form}
            labelCol={{
              span: 5,
            }}
            initialValues={{
            }}
          >
            <Form.Item
                label="企业"
            >
            <Form.Item
                name="corpName"
                 style={{width:"40%",float:"left",marginBottom:0}} 
            >
            <Input bordered={false} readOnly placeholder="请点击关联企业"/>
            </Form.Item>
            {/* <Timebutton type='link' /> */}
            <Button className='m-button-blue' style={{width:"25%",float:"left",}} onClick={()=>{setShowAssCorp(true)}}>关联企业</Button>
            </Form.Item>
            <Form.Item
                label="权限配置"
            >
              {
                sceneList.map((item, index) => (
                  // console.log('sceneChecked------333333333333333---------',sceneChecked),
                  // console.log(item,sceneChecked[item.sceneKey]),
                  <Row style={{marginTop:10}}>
                    <Col span={12}>
                      <Checkbox value={item.sceneKey} checked={sceneChecked[item.sceneKey]} onChange={onCheckScene} >{item.sceneName}</Checkbox>
                    </Col>
                    <Col span={12} style={{textAlign:"right"}}>
                    <Button type='primary' size='small' disabled={!sceneChecked[item.sceneKey]} onClick={()=>{funAssPhone(item.sceneKey)}}>分配云手机</Button>
                    </Col>
                    
                  </Row>
                ))
              }
            </Form.Item>


          </Form>   
    </Modal>

    <Drawer
        title="关联企业"
        placement="right"
        width={300}
        closable={false}
        onClose={onClose}
        // visible={visibleTree}
        open={showAssCorp}
      >
        {/* <Search style={{ marginBottom: 8 }} placeholder="Search" /> */}
        {/* <Search style={{ marginBottom: 8 }} placeholder="Search" onChange={changeTree}/> */}
        {corpList[0].children.length || corpList[1].children.length  ? 
          (<Tree
            checkable
            onCheck={onCheckCorp}
            checkedKeys={checkedKeys}
            treeData={corpList}
          />)
        : (<LoadingOutlined />)}
      </Drawer>

    <Drawer
        title="分配云手机"
        placement="right"
        width={500}
        closable={false}
        onClose={onCloseAssPhone}
        // visible={visibleTree}
        open={showAssPhone}
    >
      <Table
        rowSelection={{
          type: "Checkbox",
          ...rowSelection,
        }}
        columns={columns}
        dataSource={phoneList}
        rowKey="deviceId"
      />
        
    </Drawer>
  </div>
);
}
export default App;