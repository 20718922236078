import http from "../../request/http";
import CryptoJs from 'crypto-js';

// 修改密码
export const updatePassword = (data) =>{
    data.oldPassword = CryptoJs.MD5(data.oldPassword).toString();
    data.password = CryptoJs.MD5(data.password).toString();
    return http ({
        method:'POST',
        url:'user/update/password',
        data
    })
}
