
import './App.css';
import {Route, Routes, HashRouter} from "react-router-dom"
import Login from "./page/login/index.jsx"
import Forgetpwd from "./page/login/forgetpwd.jsx"
import Register from "./page/login/register.jsx"
import RegisterInfo from "./page/login/registerInfo.jsx"
// import RegisterCorp from "./page/login/registerCorp.jsx"
// import RegisterPerson from "./page/login/registerPerson.jsx"
import RegisterOk from "./page/login/registerOk.jsx"
import Admin from "./page/admin/index.jsx"
// import Timebutton from "./page/login/timebutton.jsx"
// import Testupload from "./page/login/testupload.jsx"
// import Timetest from "./page/testTable/timertest.jsx"
function App() {
  return (
    <div className="App">
      
      <HashRouter>
        <Routes >
          {/* <Route path="/testupload" element={<Testupload/>}></Route>
          <Route path="/timebutton" element={<Timebutton/>}></Route>
          <Route path="/timetest" element={<Timetest/>}></Route> */}
          <Route path="/login" element={<Login/>}></Route>
          <Route path="/login/forgetpwd" element={<Forgetpwd/>}></Route>
          <Route path="/register" element={<Register/>}></Route>
          {/* <Route path="/register/corp" element={<RegisterCorp/>}></Route> */}
          {/* <Route path="/register/person" element={<RegisterPerson/>}></Route> */}
          <Route path="/register/info" element={<RegisterInfo/>}></Route>
          <Route path="/register/ok" element={<RegisterOk/>}></Route>
          <Route path="*" element={<Admin/>}></Route>
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
