import { Space, Button, message,Modal,Popconfirm } from 'antd';
import ProTable from '@ant-design/pro-table';
import React , { useRef, useState,useEffect }from 'react'
import checkLogin from "../../../request/checkLogin.js"
import { ProList } from '@ant-design/pro-components';
import '../index.css';
import { appInstalledPhones, appAgainInstall, appUninstall } from '../service';
import { EditIcon } from '../../components/icons';

const App = props => {
  const {phoneListShow, closePhoneList, curappId} = props;
  const actionRef = useRef();

  const [selectedRowKeys,setSelectedRowKeys] = useState([])

  // 获取已安装手机列表
  const getInstalledPhoneList = async (params) =>{
    // console.log('-----curappId---------------', curappId)
    var result = {};
    if(!curappId)
      return result;
    params.pageNo = params.current;
    delete params.current;
    params.id = curappId;
    // console.log('------getPhoneList---params--------', params);
    try {
      await appInstalledPhones(params).then((res) => {
        // console.log('appInstalledPhones-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          result = res.data.data;
          return result;
        }
      });
    } catch (error) {
      message(error);
    }
    return result;
  }

  // 重新安装功能
  const installAgainFun = async (data) =>{
    if(!data || !curappId) return;
    try {
      return await appAgainInstall({appId:curappId, cloudPhoneNum:data.cloudPhoneNum}).then((res) => {
        // console.log('appAgainInstall-res-----------------', res);
        var flag = checkLogin(res.data)
        if (flag) {
          actionRef.current.reload()
          message.success('操作成功');
        }
      });
    } catch (error) {
      return false;
    }
  }

  // 卸载功能
  const unintallFun = async () =>{
    if(!curappId)
     return ;

    // console.log(selectedRowKeys);
    if(selectedRowKeys?.length === 0 || !selectedRowKeys) {
      message.warn('请选择可卸载云手机');
      return;
    };
    try {
      return await appUninstall({appId:curappId, cloudPhoneNums:selectedRowKeys}).then((res) => {
        // console.log('appUninstall-res-----------------', res);
        var flag = checkLogin(res.data)
        if (flag) {
          closePhoneList();
          // actionRef.current.reload()
          message.success('操作成功');
        }
      });
    } catch (error) {
      return false;
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys)
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.status !== 1,
      // Column configuration not to be checked
      // name: record.name,
    }),
  };

  // useEffect(() => {
  //   console.log('1111111111111111111')
  //   // 获取用户信息
    
  // }, []);

  const columnsPlay = [
    {
      title: '云手机序号',
      dataIndex: 'cloudPhoneNum',
      hideInSearch: true,
    },
    {
      title: '状态',
      dataIndex: 'deviceStatus',
      valueType: 'select',
      hideInSearch: true,
      valueEnum: {
        1: {
          text: ('空闲'),
          status: 'Success',
        },
        2: {
          text: ('占用中'),
          status: 'Processing',
        },
        // 3: {
        //   text: ('锁定'),
        //   status: 'Default',
        // },  
        4: {
          text: ('离线'),
          status: 'Default',
        },
        5: {
          text: ('重启中'),
          status: 'Warning',
        },
        7: {
          text: ('重置中'),
          status: 'Warning',
        }, 
      },

    },
    {
      title: '节点名称',
      dataIndex: 'nodeName',
      hideInSearch: true,
    },
    {
      title: '国家',
      dataIndex: 'icon',
      hideInSearch: true,
      render: (text) => <img src={text} width={30} />,
    },
    {
      title: '安装状态',
      dataIndex: 'status',
      hideInSearch: true,
      valueEnum: {
        1: {
          text: ('安装完成'),
          status: 'success',
        },
        2: {
          text: ('安装中'),
          status: 'Processing',
        },
        3: {
          text: ('卸载中'),
          status: 'Processing',
        },
        4: {
          text: ('安装失败'),
          status: 'error',
        },
      },
    },
    {
      title: '操作',
      hideInSearch: true,
      render:(_,data)=>{
        return [
          <Popconfirm
                key='againFrame'
                title="确认重新安装？"
                onConfirm={()=>{
                  installAgainFun(data)
                }}
                okText="Yes"
                cancelText="No"
                disabled={data.status !== 4}
            >
            <Button type="primary" size="small" 
            disabled={data.status !== 4}
          >重新安装</Button>
        </Popconfirm>,

        ]
      }
      
    }]

  return (
    <Modal
    visible={phoneListShow}
    title="云手机列表"
    onCancel={closePhoneList}
    width={700}
    centered
    destroyOnClose={true}
    footer={[
      <Button key="no" onClick={closePhoneList}  className="m-btn-white">
        关闭
      </Button >,
      <Popconfirm
          key='unintallpop'
          title="确认卸载？"
          onConfirm={()=>{
            unintallFun()
          }}
          okText="Yes"
          cancelText="No"
      >
      <Button key="ok" type='primary' >
        卸载
      </Button >
      </Popconfirm>,

    
    ]}
>

      <ProTable
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        pagination={{ defaultPageSize: 10 }}
        rowKey="cloudPhoneNum"
        search={{
          filterType: 'light',
        }}
        size="small"
        actionRef={actionRef}
        request={async (params = {}) => {
          const res = await getInstalledPhoneList(params);
        //   const res = {
        //         "pageNo": 1,
        //         "pageSize": 10,
        //         "totalPage": 0,
        //         "totalRows": 0,
        //         "rows": [
        //             {
        //                 "resourceId": "0171ea5bc18a4de7bb6a6d9dadce7fa9",
        //                 "cloudPhoneNum": 21,
        //                 "deviceStatus": 1,
        //                 "nodeName": "新加坡 021",
        //                 "icon": "https://kp-photo.obs.cn-east-3.myhuaweicloud.com/node/city/25764f338fd44ee79bc3c467878bde61.png",
        //                 "status": 1
        //             },
        //             {
        //                 "resourceId": "0171ea5bc18a4de7bb6a6d9dadce7fa9",
        //                 "cloudPhoneNum": 24,
        //                 "deviceStatus": 2,
        //                 "nodeName": "日本001",
        //                 "icon": "https://kp-photo.obs.cn-east-3.myhuaweicloud.com/node/city/364864f897dd40fbb7a0bff07fc18c9f.png",
        //                 "status": 2
        //             },
        //             {
        //                 "resourceId": "0171ea5bc18a4de7bb6a6d9dadce7fa9",
        //                 "cloudPhoneNum": 100,
        //                 "deviceStatus": 5,
        //                 "nodeName": "新加坡 022",
        //                 "icon": "https://kp-photo.obs.cn-east-3.myhuaweicloud.com/node/city/25764f338fd44ee79bc3c467878bde61.png",
        //                 "status": 1
        //             },
        //             {
        //                 "resourceId": "0171ea5bc18a4de7bb6a6d9dadce7fa9",
        //                 "cloudPhoneNum": 110,
        //                 "deviceStatus": 4,
        //                 "nodeName": "日本002",
        //                 "icon": "https://kp-photo.obs.cn-east-3.myhuaweicloud.com/node/city/364864f897dd40fbb7a0bff07fc18c9f.png",
        //                 "status": 4
        //             },
        //         ]
        // };
          return {
            data: res.rows,
            current: res.pageNo,
            pageSize: res.pageSize,
            success: true,
            total: res.totalRows,
          };
        }}
        columns={columnsPlay}
        // onRow={ (record) => {
        //   return {
        //     onClick: async(event) => { await setDeviceInfo(record)}, // 点击行
        //   };
        // }}
        options={false}
        // options={{ density: false, fullScreen: false }}
      />
      
</Modal>
);
        }
export default App;