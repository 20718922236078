import axios from  'axios';

// import {message} from 'antd';

const baseURL = (val) =>{
    // console.log(val)
    const baseVal = {
        // 'development':'http://192.168.1.20:8081/api/tk/',
        // 'production':'http://192.168.1.20:8081/api/tk/',
        'development':'http://tiktok.kuaipantech.com/api/tk/',
        'production':'https://tiktok.kuaipantech.com/api/tk/',
        // 'development':'http://dev.paascmw.kuaipantech.com/api/tk/',
        // 'production':'http://dev.paascmw.kuaipantech.com/api/tk/',
        // 'development':'https://paascmw.kuaipantech.com/api/',
        // 'production':'https://paascmw.kuaipantech.com/api/'
    }
    return baseVal[val]
}

const http = axios.create({
    timeout:30000,
    baseURL:baseURL(process.env.NODE_ENV)
})

http.interceptors.request.use(config =>{
    // console.log('config---------------', config);

    config.headers['Content-Type'] = 'application/json';

    let token = localStorage.getItem('token');
    if(token) config.headers['Authorization'] = token;
    // if(token) config.headers['Authorization'] = `Bearer ${token}`;

    return config
}, err =>{
    return Promise.reject(err)
});

http.interceptors.response.use(res =>{
    if(res){
        // console.log('--------http-------------', res)
        return res
    }
},err =>{
    return Promise.reject(err)
})

export default http;
