import Icon,{ LaptopOutlined, NotificationOutlined, UserOutlined,DownloadOutlined,DesktopOutlined,MenuOutlined,PictureOutlined,EllipsisOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu,Input,Select,Card,Space,Col, Row ,Button ,Table,Tabs,Dropdown,Popconfirm,Modal,Form,Divider,message } from 'antd';
import React , { useRef, useState,useEffect }from 'react'
import checkLogin from "../../request/checkLogin.js"
import { ProList, arrayMoveImmutable } from '@ant-design/pro-components';
import './index.css';
import { getCustomerList,customerEnable,customerDisable,customerDelete,getReviewedList,customerDetail,customerAdd,customerChangetype } from './service';
import { AppIcon } from '../components/icons';
import Addclient from './components/addclient.jsx';
import Editclient from './components/editclient.jsx';
import Checklist from './components/checklist.jsx';
import Bcapplist from './components/bcapplist.jsx';
import CustomProList from '../components/CustomProList';
import { buttonBlur,customTabinkbar } from '../../utils/global.js';

const { Header, Content, Sider } = Layout;
const { TabPane } = Tabs;
const ClientSvg = () => (
  <svg t="1672205728506" className="icon" viewBox="64 64 896 896" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5427" width="1em" height="1em"><path d="M504.2 618c127.6-0.2 212.6-167.3 212.6-257.2 0-89.9-0.4-249.8-212.6-249.8S291.6 270.9 291.6 360.8s85 257.4 212.6 257.2z m361.9 126.8C812 703.3 655.8 634.3 655.8 634.3L554.4 817.9l-15-43.7 27.7-55.2-55.5-55.2-55.5 55.2 27.8 55.2L466 819l-98.6-184.6s-156.1 69-210.3 110.5C102.9 786.3 79 912.3 79 912.3h866.7s-15.8-118.6-79.6-167.5z" fill="currentColor" p-id="5428"></path></svg>
      );
const ClientIcon = props => (
    <Icon component={ClientSvg} {...props} />
    );

const CheckSvg = () => (
  <svg width="1em" height="1em" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.1163 22.6789L20.0594 19.6184C20.8542 18.5373 21.3247 17.203 21.3247 15.7607C21.3247 12.1648 18.4026 9.23914 14.811 9.23914C11.2194 9.23914 8.29736 12.1648 8.29736 15.7607C8.29736 19.3567 11.2194 22.2823 14.811 22.2823C16.2515 22.2823 17.5842 21.8113 18.664 21.0155L21.7209 24.076C21.9137 24.2688 22.1661 24.3653 22.4186 24.3653C22.6712 24.3653 22.9236 24.2688 23.1163 24.076C23.5016 23.6901 23.5016 23.0646 23.1163 22.6789ZM14.811 20.6771C12.1034 20.6771 9.90067 18.4715 9.90067 15.7607C9.90067 13.0498 12.1034 10.8444 14.811 10.8444C17.5185 10.8444 19.7214 13.0498 19.7214 15.7607C19.7214 18.4716 17.5185 20.6771 14.811 20.6771Z" fill="currentColor"/>
<path d="M6.37843 16.0308C6.37843 11.4277 10.1053 7.69624 14.7026 7.69624C17.5179 7.69624 20.0068 9.0956 21.5133 11.2375V3.15263C21.5133 2.31505 20.8344 1.63477 19.9971 1.63477H4.11093C3.27361 1.63477 2.59473 2.31432 2.59473 3.1526V22.8474C2.59473 23.685 3.27355 24.3653 4.11093 24.3653H14.7026C10.1053 24.3653 6.37843 20.6338 6.37843 16.0308ZM5.6217 4.6655C5.6217 4.24704 5.96051 3.90782 6.37845 3.90782H17.7296C18.1475 3.90782 18.4863 4.24704 18.4863 4.6655C18.4863 5.08394 18.1475 5.42319 17.7296 5.42319H6.37843C5.96051 5.42319 5.6217 5.08394 5.6217 4.6655Z" fill="currentColor"/>
</svg>
      );
const CheckIcon = props => (
    <Icon component={CheckSvg} {...props} />
    );

const App = props => {
  const actionRef = useRef();
  const actionRef_2 = useRef();
  const actionRef_3 = useRef();

  const [showAddclient,setShowAddclient] = useState(false)
  const [showEditclient,setShowEditclient] = useState(false)
  const [curClientid,setCurClientid] = useState("")
  const [curClientInfo,setCurClientInfo] = useState("")
  const [userInfo,setUserInfo] = useState({})
  
  const [exchangParam_bc, setExModalOpen] = useState(false);
  const showExModal = () => {
    setExModalOpen(true);
  };
  const handleExCancel = () => {
    setExModalOpen(false);
  };

  const closEditclient = () => {
    setShowEditclient(false);
  };

  const closAddclient = () => {
    setShowAddclient(false);
  };

  const [loading_clientlist,setClientlistloading] = useState(false);
  const [loading_checklist,setChecklistloading] = useState(false);

// createDt: "2023-01-18 10:28:36"
// customerName: "ld"
// id: 11
// loginFlag: 1
// mobile: "13811111111"
// type: 8

  const columsName = {
    'customerName' : {
      title:'客户', 
      width:'20%'
    },
    'mobile' : {
      title:'手机号', 
      width:'20%'
    },
    'type' : {
      title:'客户类型', 
      width:'20%',
      valueEnum: {
        8: {
          text: '个人客户',
        },
        9: {
          text: '企业客户',
        },
      },
      edit:true,
    },
    'loginFlag' : {
      title:'状态', 
      width:'20%',
      valueEnum: {
        "-1": {
          text: '禁用',
          status: 'error',
        },
        0: {
          text: '待审核',
          status: 'processing',
        },
        1: {
          text: '启用',
          status: 'success',
        },
        2: {
          text: '不通过',
          status: 'error',
        },
      },
    },
    'createDt' : {
      title:'创建时间', 
      width:'20%'
    },
    // 'op' : '操作',
  };

  // 获取客户列表
  const getClientList = async (params) =>{
    params.pageNo = params.current;
    delete params.current;
    var result = {};
    try {
      await getCustomerList(params).then((res) => {
        // console.log('getClientList-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          // console.log('222222222222222222222222');
          result = res.data.data;
          // console.log('result-----', result);
          return result;
        }
        else
          message.error(res.data.msg);
      });
    } catch (error) {
      message(error);
    }
    return result;
  }

  // 启用/禁用客户
  const setClientStatus = async (id,status) => {
    if(!id)
      return;
    const fun = status==1 ? customerDisable : customerEnable;
    try {
      await fun({id:id}).then((res) => {
        // console.log('setClientStatus-res-----------------', fun, res);
        var flag = checkLogin(res.data);
        if(flag){
          actionRef.current.reload();
          message.success("操作成功");
        }
        else
          message.error(res.data.msg);
      });
    } catch (error) {
      message(error);
    }
  }
  
  // 删除客户
  const delClient = async (id) => {
    if(!id)
      return;
    try {
      await customerDelete({id:id}).then((res) => {
        // console.log('customerDelete-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          actionRef.current.reload();
          message.success("删除成功");
        }
        else
          message.error(res.data.msg);
      });
    } catch (error) {
      message(error);
    }
  }
  
  // 获取客户详情
  const getClientInfo = async (id) => {
    if(!id)
      return;
    try {
      await customerDetail({id:id}).then((res) => {
      // console.log('customerDetail---------', res);
      var flag = checkLogin(res.data)
      if (flag) {
        setCurClientInfo(res.data.data);
        setShowEditclient(true);
      }
      // else
      //   message.error(res.data.msg);
    });
    } catch (error) {
      message.error(error);
    }
  };

  // 新增客户
  const addClient = async (values) => {
    // console.log(values);
    try {
      customerAdd(values).then((res) => {
        // console.log('customerAdd---------', res);
      var flag = checkLogin(res.data)
      if (flag) {
        closAddclient();
        actionRef.current.reload();
        message.success("新增成功");
      }
      // else
        // message.error(res.data.msg);
    });
    } catch (error) {
      message.error(error);
    }
  };

  // 修改客户类型
  const changClientType = (id,type) => {
    // console.log(id,type)
    try {
      customerChangetype({id:id,userType:type}).then((res) => {
        // console.log('customerChangetype---------', res);
      var flag = checkLogin(res.data)
      if (flag) {
        actionRef.current.reload();
        message.success("修改成功");
      }
      // else
        // message.error(res.data.msg);
    });
    } catch (error) {
      message.error(error);
    }
  };


  const tabClicked = key => {
    // console.log(key);
    for(var i=1; i<=3; i++)
    {
      var span = document.getElementById("tab_pane_"+i);
      var bar = document.getElementById("tab_bar_"+i);
      if(i == key){
        span.className = "tab_pane_active";
        bar.className = "cust_tab_bar_active";
        if(i == 1 && actionRef.current){
          actionRef.current.reload();
        } else if(i == 2 && actionRef_2.current){
          actionRef_2.current.reload();
        } else if(i == 3 && actionRef_3.current){
          actionRef_2.current.reload();
        }
      }
      else{
        span.className = "tab_pane";
        bar.className = "cust_tab_bar";
      }
    }
    customTabinkbar();
  };
  return (
    <Content
      className="site-layout-background"
      style={{
        padding: 10,
        margin: 0,
        minHeight: "100%",
      }}
    >
      <div className='titleClass' style={{width:100,minHeight: 30,marginBottom:10,fontSize:24,fontWeight:600,fontFamily:"PingFangSC-Regular"}}>运营管理</div>
      
      <Tabs defaultActiveKey="1" size='large' onTabClick={tabClicked} >
      <TabPane tab={<div><div id="tab_pane_1" className='tab_pane_active'><ClientIcon />客户管理</div><div id="tab_bar_1" className='cust_tab_bar_active' ></div></div>} key="1" >

        <div>

        <ProList rowKey="id" 
        toolBarRender={() => {
          return [
              <Button type="primary" shape="round" onClick={()=>{setShowAddclient(true)}}>新增客户</Button>,
          ];
        }} 
        search={{
          filterType: 'light',
        }}
        pagination={{ defaultPageSize: 10, showSizeChanger: true, }}
        loading = {loading_clientlist}
        actionRef={actionRef}
        // dataSource={dataSource} 
        request={async (params = {})  => {
          setClientlistloading(true);
          // console.log('params----------------', params);
          const res = await getClientList(params);
          // console.log('res--------------', res);
          var tableData = [];
          if(res.rows){
            res.rows.map((v,k)=>{
              // console.log('v--------------', v);
              tableData[k] = v;
              tableData[k]['content'] = {...v};
            })
          }
            // console.log('tableData--------------', tableData);
          setClientlistloading(false);
        
          return {
              data: tableData,
              // current: res.data.pageNo,
              // pageSize: res.data.pageSize,
              success: true,
              // total: res.data.totalRows,
          };
        }}
        metas={{
          customerName: {
              // dataIndex: 'name',
              title: '客户名称',
          },
          type: {
            // 自己扩展的字段，主要用于筛选，不在列表中显示
            title: '客户类型',
            dataIndex: 'type',
            valueType: 'select',
            valueEnum: {
              8: {
                text: ('个人客户'),
                status: 'Default',
              },
              9: {
                text: ('企业客户'),
                status: 'Success',
              },
            },
          },

          content: {
              dataIndex: 'content',
              search: false,
              render: (text) => (
                <CustomProList 
                  columsName = {columsName}
                  text = {text}
                  onChangFun = {{"type":changClientType}}
                />
              ),
          },
          actions: {
              render: (text, data) => [
                <div>
                <div style={{ color: '#00000073',textAlign:"left" }}>操作</div>
                <div style={{ color: '#000000D9',marginTop:6 }}>
                <Space>
                <Button size="small"  className='m-button-blue'
                onClick={(event) => {
                  buttonBlur(event);
                  setCurClientid(data.id);
                  getClientInfo(data.id);
                }}
                > 修改 </Button>
                <Dropdown  overlay={()=>{
                  return(
                  <Menu style={{borderRadius:8}} >
                      <Menu.Item  onClick={()=>setClientStatus(data.id, data.loginFlag)}
                          // style={{display:isShow('del')?'block':'none'}}
                      >{data.loginFlag==1?"禁用":"启用"}</Menu.Item> 

                       <Popconfirm
                              title="确认删除该成员？"
                              onConfirm={()=>{
                                  delClient(data.id);
                              }}
                              okText="Yes"
                              cancelText="No"
                          >
                          <Menu.Item 
                              // style={{display:isShow('del')?'block':'none'}}
                          >删除</Menu.Item> 
                      </Popconfirm>
                    
                  </Menu>
                  )}}>
                  <EllipsisOutlined  style={{color:"rgb(63,140,255)",fontSize:20}}/>
                </Dropdown>
                </Space>
                </div>
                </div>
              ],
          },
        }} 
        />


        </div>
        </TabPane>
      <TabPane tab={<div><div id="tab_pane_2" className='tab_pane'><CheckIcon />审核管理</div><div id="tab_bar_2" className='cust_tab_bar' ></div></div>} key="2" >
        <Checklist 
          actionRef = {actionRef_2}
        />
      </TabPane>
      <TabPane tab={<div><div id="tab_pane_3" className='tab_pane'><AppIcon />直播舱应用管理</div><div id="tab_bar_3" className='cust_tab_bar' ></div></div>} key="3" >
        <Bcapplist 
          actionRef = {actionRef_3}
        />
      </TabPane>
      
      </Tabs>  
      {customTabinkbar()}
 
    <Editclient
      curClientid = {curClientid}
      curClientInfo = {curClientInfo}
      showEditclient = {showEditclient}
      closEditclient = {closEditclient}
    />
  
    <Addclient
      showAddclient = {showAddclient}
      closAddclient = {closAddclient}
      addClient = {addClient}
    />
  
 </Content>

);
        }
export default App;