import { Space, Button, message,Modal,Popconfirm,Radio } from 'antd';
import ProTable from '@ant-design/pro-table';
import React , { useRef, useState,useEffect }from 'react'
import checkLogin from "../../../request/checkLogin.js"
// import { ProList } from '@ant-design/pro-components';
import '../index.css';
// import { syncedCloudPhonesList,againSyncPhone } from '../service';
import { getApiData } from '../../../request/api';
// import { EditIcon } from '../../components/icons';

let repeatType = "" ;

const App = props => {
  const {phoneListShow, closePhoneList, resourceId, from} = props;
  const actionRef = useRef();

  const [selectedRowKeys,setSelectedRowKeys] = useState([])

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [phoneListShow]);

  const columnsPlay = [
    {
      title: '云手机序号',
      dataIndex: 'cloudPhoneNum',
      hideInSearch: true,
    },
    {
      title: '状态',
      dataIndex: 'deviceStatus',
      valueType: 'select',
      hideInSearch: true,
      valueEnum: {
        1: {
          text: ('空闲'),
          status: 'Processing',
        },
        2: {
          text: ('占用中'),
          status: 'Success',
        },
        // 3: {
        //   text: ('锁定'),
        //   status: 'Default',
        // },  
        4: {
          text: ('离线'),
          status: 'Default',
        },
        5: {
          text: ('重启中'),
          status: 'Warning',
        },
        7: {
          text: ('重置中'),
          status: 'Warning',
        }, 
      },

    },
    {
      title: '节点名称',
      dataIndex: 'nodeName',
      hideInSearch: true,
    },
    {
      title: '国家',
      dataIndex: 'icon',
      hideInSearch: true,
      render: (text) => <img src={text} width={30} />,
    },
    {
      title: '同步状态',
      dataIndex: 'status',
      hideInSearch: true,
      valueEnum: {
        // 0: {
        //   text: ('待同步'),
        //   status: 'Default',
        // },
        1: {
          text: ('同步中'),
          status: 'Processing',
        },
        2: {
          text: ('同步成功'),
          status: 'success',
        },
        3: {
          text: ('同步失败'),
          status: 'error',
        },
      },
    },
    {
      title: '最近操作',
      dataIndex: 'currentTask',
      hideInSearch: true,
    },
    {
      title: '操作',
      hideInSearch: true,
      render:(_,data)=>{
        return [
          <Popconfirm
                key='againFrame'
                title="确认重新同步？"
                onConfirm={()=>{
                  resourceSyncAgainFun(data)
                }}
                okText="Yes"
                cancelText="No"
            >
            <Button type="primary" size="small"  key="again"
            disabled={data.status !== 3}
          >重新同步</Button>
        </Popconfirm>,

        ]
      }
      
    }]

  // 获取已同步手机列表
  const getSyncedPhoneList = async (params) =>{
    console.log('-----resourceId---------------', resourceId)
    var result = {};
    if(!resourceId)
      return result;
    params.pageNo = params.current;
    delete params.current;
    params.resourceId = resourceId;
    console.log('------getPhoneList---params--------', params);
    try {
      await getApiData(from, 'syncedCloudPhonesList', params).then((res) => {
        console.log(from+'-syncedCloudPhonesList-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          result = res.data.data;
          return result;
        }
      });
    } catch (error) {
      message(error);
    }
    return result;
  }

  // 重新同步功能
  const resourceSyncAgainFun = async (data) =>{
    if(!data || !resourceId) return;
    try {
      return await getApiData(from, 'againSyncPhone', {resourceId:resourceId, cloudPhoneNum:data.cloudPhoneNum}).then((res) => {
        console.log(from+'-againSyncPhone-res-----------------', res);
        var flag = checkLogin(res.data)
        if (flag) {
          actionRef.current.reload()
          message.success('操作成功');
        }
      });
    } catch (error) {
      return false;
    }
  }

  const onChange =  (e) => {
    // console.log('radio checked', e.target.value);
    repeatType= e.target.value
    // setTimeout(()=>{
    //   setValue(e.target.value);
    // })
    
  };

  // 开始直播
  const onStartBroadcast = async () =>{
    if(!resourceId)
     return ;

    console.log(selectedRowKeys);
    if(selectedRowKeys?.length === 0 || !selectedRowKeys) {
      message.error('请选择云手机');
      return;
    };
    repeatType= "";
    Modal.confirm({
      title: '请选择是否重复播放',
      // closable:true,
      centered:true,
      content: <span>
         <Radio.Group onChange={onChange} >
          <Space direction="vertical">
            <Radio value={0}>单次播放</Radio>
            <Radio value={1}>重复播放</Radio>
          </Space>
        </Radio.Group>
      </span>,
      okText:"确认",
      // okType: 'danger',
      cancelText: '取消',
      onOk: async() => {
        if( typeof repeatType != "number"){
          message.error('请选择播放类型');
          // return;
        }else{
          try {
            return await getApiData(from, 'materialPlay', {resourceId:resourceId, cloudPhoneNums:selectedRowKeys, repeat:repeatType}).then((res) => {
              console.log(from+'-materialPlay-res-----------------', res);
              var flag = checkLogin(res.data)
              if (flag) {
                // closePhoneList();
                actionRef.current.reload()
                message.success('操作成功');
              }
            });
          } catch (error) {
            return false;
          }
           
        }
      }
      ,
      onCancel() {
        
      },
    });
  }

  // 终止直播
  const onStopBroadcast = async () =>{
    if(!resourceId)
     return ;

    console.log(selectedRowKeys);
    if(selectedRowKeys?.length === 0 || !selectedRowKeys) {
      message.error('请选择云手机');
      return;
    };

    try {
      return await getApiData(from, 'materialStop', {resourceId:resourceId, cloudPhoneNums:selectedRowKeys, repeat:repeatType}).then((res) => {
        console.log(from+'-materialStop-res-----------------', res);
        var flag = checkLogin(res.data)
        if (flag) {
          // closePhoneList();
          actionRef.current.reload()
          message.success('操作成功');
        }
      });
    } catch (error) {
      return false;
    }
      
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys)
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.status === 0,
      // Column configuration not to be checked
      // name: record.name,
    }),
  };

  return (
    <Modal
    visible={phoneListShow}
    title="云手机列表"
    onCancel={closePhoneList}
    width={700}
    centered
    destroyOnClose={true}
    footer={[
      <Button key="no" onClick={closePhoneList}  className="m-btn-white">
        关闭
      </Button >,
      <Button key="ok" type='primary' onClick={()=>{
        setTimeout(()=>{
          onStartBroadcast();
        },100)
      }} >
        开始直播
      </Button >,
      <Button key="ok" type='primary' danger style={{boxShadow: "0px 6px 12px 0px #fc6d7543"}} onClick={onStopBroadcast} >
        终止直播
      </Button >
    ]}
>

      <ProTable
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        pagination={{ defaultPageSize: 10 }}
        rowKey="cloudPhoneNum"
        search={{
          filterType: 'light',
        }}
        size="small"
        actionRef={actionRef}
        request={async (params = {}) => {
          const res = await getSyncedPhoneList(params);
          return {
            data: res.rows,
            current: res.pageNo,
            pageSize: res.pageSize,
            success: true,
            total: res.totalRows,
          };
        }}
        columns={columnsPlay}
        // onRow={ (record) => {
        //   return {
        //     onClick: async(event) => { await setDeviceInfo(record)}, // 点击行
        //   };
        // }}
        options={false}
        // options={{ density: false, fullScreen: false }}
      />
      
</Modal>
);
        }
export default App;