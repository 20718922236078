import http from "../../request/http";
import CryptoJs from 'crypto-js';

export const getCountryList = (data) =>{
    return http ({
        method:'POST',
        url:'currency/sim/country/options',
        // data
    })
}

export const getZoneList = (data) =>{
    return http ({
        method:'POST',
        url:'currency/time/zone/options',
        // data
    })
}

export const BcroomAppUrl = (data) =>{
    return http ({
        method:'POST',
        url:'live/broadcast/app/url',
        data
    })
}

export const BcPhoneList = (data) =>{
    return http ({
        method:'POST',
        url:'nobody/live/broadcast/page',
        data
    })
}
// 获取云手机节点国家地区Options
export const NodeCityOptions = (data) =>{
    return http ({
        method:'POST',
        url:'currency/node/city/options',
        data
    })
}

// 查询客户Options
export const customerOptions = (data) =>{
    return http ({
        method:'POST',
        url:'currency/customer/options',
        data
    })
}

// 云手机基本信息修改
export const baseinfoUpdate = (data) =>{
    return http ({
        method:'POST',
        url:'nobody/live/broadcast/cloudphone/baseinfo/update',
        data
    })
}

// 直连
export const directConnect = (data) =>{
    return http ({
        method:'POST',
        url:'nobody/live/broadcast/direct/connect/'+data.cloudPhoneNum,
        data
    })
}

// 断开直连
export const directDisconnect_unm = (data) =>{
    return http ({
        method:'POST',
        url:'nobody/live/broadcast/direct/disconnect/'+data.cloudPhoneNum,
        data
    })
}

// 获取云手机参数信息
export const getDeviceInfo = (data) =>{
    return http ({
        method:'POST',
        url:'nobody/live/broadcast/cloudphone/info/search/'+data.cloudPhoneNum,
        data
    })
}
