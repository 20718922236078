import Icon,{ LoadingOutlined } from '@ant-design/icons';
import { Layout, Input,Select,Space,Col, Row, Card,Button,Modal,Form,message,List,Switch,Tooltip } from 'antd';
import React, { useState,useEffect,useRef }  from 'react';
// import Zhilian from '../components/Zhilian';
import Touping from '../components/Touping';
import Updateparam from '../components/Updateparam';
import ParamDetail from '../components/ParamDetail';
import { BcroomAppUrl, BcPhoneList,NodeCityOptions,customerOptions,baseinfoUpdate,getDeviceInfo,directConnect} from './service';
import checkLogin from '../../request/checkLogin.js'
import {getApiData} from '../../request/api.js'
// import {getApiData} from '../../request/requestData'
import QRCode from 'qrcode.react';
import { EditIcon,DownloadIcon  } from '../components/icons';
import { ProList } from '@ant-design/pro-components';
import { buttonBlur } from '../../utils/global.js';
import { encrypt,decrypt,localStorageSet,localStorageGet,Sessionid,postMessage } from '../../utils/global.js';

import './index.css';

const { Content } = Layout;

let waitFlushInterHandler = undefined;
let waitState = false;
let occupyDevices = [];

const clientHeight = document.body.clientHeight;
const clientWidth = document.body.clientWidth;
// console.log('clientWidth-----: ', clientWidth);

const allDeviceStatus = {
    1: {
      text: '空闲',
      status: 'Success',
    },
    2: {
      text: '占用中',
      status: 'Error',
    },
    4: {
      text: '离线',
      status: 'Default',
    },
    5: {
      text: '重启中',
      status: 'Default',
    },
    6: {
      text: '重置中',
      status: 'Default',
    },
};

var allDeviceStatus_select = [];
Object.keys(allDeviceStatus).map((v)=>{
  allDeviceStatus_select.push({value:v, label:allDeviceStatus[v].text})
});

var maxIndex = 2000;
var onetp = {show: false, id: 0, info: {}, zIndex:maxIndex};
var allToupingPhones = [onetp, onetp, onetp, onetp, onetp, onetp];

var sessionid = "";

const App = (props) => {
  // console.log('props', props);

  const actionRef = useRef();
  const [listDeviceStatus, setListDeviceStatus] = useState({});

  const currentUserinfo = JSON.parse(decrypt(localStorageGet('UserInfo')));
  // console.log('-------bcroom---currentUserinfo-------', currentUserinfo, currentUserinfo.userType);
  // const [sessionid, setSessionid] = useState("");

  const [allTouPingParams, setallTouPingParams] = useState([onetp,onetp,onetp,onetp,onetp,onetp]);

  const [showSelectClient, setShowSelectClient] = useState("none");
  const [curClientid, setCurClientid] = useState("");
  const [curDeviceInfo, setCurDeviceInfo] = useState({});
  // 设备详情
  const [showParamDetail,setShowParamDetail] = useState(false);
  const close = () =>{
    setShowParamDetail(false);
  }

  // var search_cloudPhoneNum = "";
  // var search_country = "";
  // var search_deviceStatus = 0;
  const [search_cloudPhoneNum, setSearchCloudPhoneNum] = useState(null);
  const [search_country, setSearchCourtry] = useState(null);
  const [search_deviceStatus, setSearchDeviceStatus] = useState(null);
  const onChang_cloudPhoneNum = (e) => {
    // console.log(e)
    setSearchCloudPhoneNum(e.target.value);
    // search_cloudPhoneNum = e.target.value;
  };
  const onChang_Courtry = (e) => {
    // console.log(e)
    setSearchCourtry(e);
    // search_country = e;
  };
  const onChang_DeviceStatus = (e) => {
    // console.log(e)
    setSearchDeviceStatus(e);
    // search_deviceStatus = e;
  };
  
  const [editModalOpen_bc, setIsModalOpen] = useState(false);
  const showModal = (item) => {
    // console.log('item------------',item)
    setCurDeviceInfo(item);
    form.setFieldValue("name", item.nodeName);
    form.setFieldValue("nodeGroup", item.nodeGroup);
    form.setFieldValue("remark", item.remark);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const [QRCodeUrl, setQRCodeUrl] = useState({domestic:"", oversea:""});
  const [QRCodeModalOpen, setQRCodeModalOpen] = useState(false);
  const showModal_QRCode = () => {
    try {
      BcroomAppUrl({}).then((res) => {
      // console.log('BcroomAppUrl-res-----------------', res);
      var flag = checkLogin(res.data)
      // console.log('flag: ', flag);
      if (flag) {
        setQRCodeUrl(res.data.data);
        setQRCodeModalOpen(true);
      }
      else
        message.error(res.data.msg);
    });
    } catch (error) {
      message.error(error);
      return {};
    }
  };
  const handleCancel_QRCode = () => {
    setQRCodeModalOpen(false);
  };

  const [exchangParam_bc, setExModalOpen] = useState(false);
  const [curCloudPhoneNum, setCurCloudPhoneNum] = useState("");
  const [deviceInfo,setDeviceInfo] = useState({});

  // 点击一键换参
  const onUpdateParam = async(id) => {
    try{
      await getDeviceInfo({cloudPhoneNum:id}).then((res) => {
        // console.log('getDeviceInfo---------', res);
        var flag = checkLogin(res.data)
        if (flag) {
          // console.log( '11111111111111111111');
          setDeviceInfo(res.data.data);
          setCurCloudPhoneNum(id);
          setExModalOpen(true);
        }
        return true;
      });
    }catch (error) {
      console.log(error)
      return true;
    }
  };

  const handleExCancel = () => {
    setExModalOpen(false);
    actionRef.current.reload();
  };

  const [form] = Form.useForm();

  const [countryList, setCountryList] = useState([]);
  const [clientList, setClientList] = useState([]);
  useEffect(() => {
    if(currentUserinfo.userType == 99)
      setShowSelectClient("block");

    try {
      NodeCityOptions({}).then((res) => {
      // console.log('NodeCityOptions-res-----------------', res);
      var flag = checkLogin(res.data)
      // console.log('flag: ', flag);
      if (flag) {
        setCountryList(res.data.data)
      }
      // else
      //   message.error(res.data.msg);
    });
    } catch (error) {
      // message.error(error);
    }

    try {
      customerOptions({}).then((res) => {
      // console.log('customerOptions-res-----------------', res);
      var flag = checkLogin(res.data)
      // console.log('flag: ', flag);
      if (flag) {
        setClientList(res.data.data)
      }
      // else
      //   message.error(res.data.msg);
    });
    } catch (error) {
      // message.error(error);
    }

  }, []);
  // var countryList = await getApiData("/currency/sim/country/options", "POST", {}, []);
  // console.log('countryList--------', countryList);

  const getDeviceList = async (params) =>{
    params.pageNo = params.current;
    delete params.current;
    if(curClientid)
      params.userId = curClientid;

    // console.log('search_cloudPhoneNum ----------- ', search_cloudPhoneNum);
    // console.log('search_country ----------- ', search_country);
    // console.log('search_deviceStatus ----------- ', search_deviceStatus);
    if(search_cloudPhoneNum)
      params.cloudPhoneNum = search_cloudPhoneNum;
    if(search_country)
      params.city = search_country;
    if(search_deviceStatus)
      params.deviceStatus = search_deviceStatus;

    var result = {};
    try {
      await BcPhoneList(params).then((res) => {
        // console.log('BcPhoneList-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          result = res.data.data;
          // console.log('result-----', result);
          return result;
        }
        // else
        //   message.error(res.data.msg);
      });
    } catch (error) {
      message(error);
    }
    return result;
  }

  // 获取直连/投屏云手机信息
  const getDirectInfo = async(id, deviceNum) => {
    var info = {};
    var traceInfo = {
      'corpkey': currentUserinfo.customerId ? currentUserinfo.customerId : curClientid,
      'userid': currentUserinfo.userName,
      'page': "bc",
      'sessionid': sessionid,
      'padcode': deviceNum,
    };
    try{
      await directConnect({cloudPhoneNum:id}).then((res) => {
        // console.log('directConnect---------', res);
        var flag = checkLogin(res.data)
        if (flag) {
          info = res.data.data;
          traceInfo['event'] = "SDK_DEVICE_APPLY_OK";
          postMessage("deviceresult","trace",traceInfo);
          return info;
        }
        else {
          // message.error(res.data.msg+" : "+id);
          traceInfo['event'] = "SDK_DEVICE_APPLY_ERROR";
          traceInfo['errorcode'] = res.data.code;
          traceInfo['errormsg'] = res.data.msg;
          postMessage("deviceresult","trace",traceInfo);
        }
      });
    }catch (error) {
      console.log(error)
    }
    return info;
  };

  const [showZhilianScreen, setZhilianScreenShow] = useState(false);
  const [selectPhones, setSelectPhones] = useState([]);
  const [toupingPhones, setToupingPhones] = useState([]);
  const [toupingPhonesInfo, setToupingPhonesInfo] = useState([]);

  // 点击直连
  const onZhilian = async (id, deviceNum) => {
    if(!id)
      return;

    sessionid = Sessionid(4);
    postMessage("deviceresult","trace",{
      'corpkey': currentUserinfo.customerId ? currentUserinfo.customerId : curClientid,
      'userid': currentUserinfo.userName,
      'page': "bc",
      'sessionid': sessionid,
      'event': "SDK_DEVICE_APPLY_START",
      'padcode': deviceNum,
    });

    var info = await getDirectInfo(id, deviceNum);
    // console.log('--------info---------------',info);
    if(info.hasOwnProperty("cloudPhoneNum")){
      setZhilianScreenShow(true);
      var sinfo = {};
      sinfo[id] = info;
      allToupingPhones[0] = {
        id: id,
        show: true,
        info: sinfo,
        zIndex: 1000
      };
      setallTouPingParams([...allToupingPhones]);
      actionRef.current.reload();
    }
  };

  // 修改最大层index
  const updatemaxIndex = (topIndex) => {
    maxIndex = topIndex;
    // console.log(maxIndex)
  }

  // 关闭投屏手机
  const toupingClose = (id) => {
    // console.log('id----', id)

    var closenum = 0;
    allToupingPhones.map((item, index)=>{
      if(item.id == id){
        allToupingPhones[index] = onetp;
        setallTouPingParams([...allToupingPhones]);
        closenum++;
      } else if(item.id == 0){
        closenum++;
      }
    })

    if(closenum == 6){
      // setCheckboxShow(false);
      setZhilianScreenShow(false);
    }
    actionRef.current.reload();
  }

  const zhilianClose = (id) => {
    actionRef.current.reload();
    setZhilianScreenShow(false);
    setSelectPhones([]);
  }

  const onChangClient = (e) => {
    // console.log(e);
    // getDeviceList({userId:e});
    setCurClientid(e);
    setSearchCloudPhoneNum(null);
    setSearchCourtry(null)
    setSearchDeviceStatus(null);
    actionRef.current.reload();
  }

  const refresh = () => {
    setSearchCloudPhoneNum(null);
    setSearchCourtry(null)
    setSearchDeviceStatus(null);
    actionRef.current.reload();
  }

  const editBasicInfo = (value) => {
    // console.log(value);
    value.nodeName = value.name;
    delete value.name;
    value.cloudPhoneNum = curDeviceInfo.cloudPhoneNum;
    try {
      baseinfoUpdate(value).then((res) => {
      // console.log('baseinfoUpdate-res-----------------', res);
      var flag = checkLogin(res.data)
      // console.log('flag: ', flag);
      if (flag) {
        closeModal();
        actionRef.current.reload();
        message.success("修改成功");
      }
      // else
      //   message.error(res.data.msg);
    });
    } catch (error) {
      // message.error(error);
    }
  }

  /**
   * 定时获取设备状态
   * @returns {Promise<void>}
   */
   const startFlushStateTimer = () => {
    if(occupyDevices.length == 0)
      return;

    if (waitFlushInterHandler === undefined) {
      waitFlushInterHandler = setInterval(async () => {
        if (waitState) {
          waitState = false;
          clearInterval(waitFlushInterHandler);
          waitFlushInterHandler = undefined;
        } else {
          // actionRef_1.current.reload();
          console.log('run:' + new Date().toString());
          try {
            await getApiData('currency', 'deviceStatus', {cloudPhoneNums:occupyDevices}).then((res) => {
              // console.log('run: getApiData-res-----------------', res);
              var flag = checkLogin(res.data);
              if(flag){
                // var devicestatus = listDeviceStatus;
                var keys = Object.keys(res.data.data);
                console.log('run:',keys)
                var occupy = [];
                for(var i=0; i<keys.length; i++)
                {
                  listDeviceStatus[keys[i]] = res.data.data[keys[i]];
                  console.log('run:',keys[i], res.data.data[keys[i]])
                  if(res.data.data[keys[i]] == 2)
                    occupy.push(keys[i]);
                  // else if(res.data.data[keys[i]] == 1)
                  //   document.getElementById("btn_zhilian_"+keys[i]).setAttribute("disabled", false);
                }
                console.log('run: occupy - ', occupy)
                setListDeviceStatus({...listDeviceStatus});
                if(occupy.length == 0){
                  waitState = true;
                }else{
                  occupyDevices = occupy;
                }
              }
              // else
              //   message.error(res.data.msg);
            });
          } catch (error) {
            // message(error);
          }
        }
      }, 10000);
    }
  };

  // 修改是否显示
  const onChangeSwitch = (cloudPhoneNum, checked) => {
    console.log(` ${cloudPhoneNum} switch to ${checked}`);
    try {
      getApiData('currency', 'switchAppShow', {cloudPhoneNum:cloudPhoneNum, showApp:checked?1:0}).then((res) => {
      var flag = checkLogin(res.data)
      if (flag) {
        actionRef.current.reload();
        message.success("操作成功");
      }
    });
    } catch (error) {
      // message.error(error);
    }
  };

  return (
    <Content
      className="site-layout-background"
      style={{
        padding: 10,
        margin: 0,
        // minHeight: "100%",
      }}
    >
    <Row align="middle">
      <Col span={16} className='titleClass'>
        直播舱
      </Col>
      <Col span={4} className='selectClient' style={{display:showSelectClient}}>
        选择客户 
      </Col>
      <Col span={4} style={{display:showSelectClient}}>
        <Select
          placeholder="请选择"
          className='ant-select-selector'
          options={clientList}
          onChange={onChangClient}
        />
    </Col>
    </Row>
    <div className="site-card-wrapper">
    <Row gutter={[16,16]} justify="start" style={{marginBottom:10,textAlign:"left",fontWeight:550}}>
      <Col span={4}>
        <span>云手机序号</span>
      </Col>
      <Col span={4}>
        <span>国家/地区</span>
      </Col>
      <Col span={4}>
        <span>使用状态</span>
      </Col>
    </Row>
    <Row gutter={[16,16]} style={{marginBottom:10,textAlign:"left"}}>
      <Col span={4}>
        <Input id="cloudPhoneNum" className='ant-select-selector' allowClear value={search_cloudPhoneNum} onChange={onChang_cloudPhoneNum}/>
      </Col>
      <Col span={4}>
        <Select
      placeholder="请选择"
      className='ant-select-selector'
      options={countryList}
      value={search_country}
      onChange={onChang_Courtry}
      allowClear
    />
      </Col>
      <Col span={4}>
        <Select
      placeholder="请选择"
      className='ant-select-selector'
      value={search_deviceStatus}
      onChange={onChang_DeviceStatus}
      options={allDeviceStatus_select}
      allowClear
    />
      </Col>
      <Col span={2}>
      <Button type="primary" shape="round" onClick={()=>{actionRef.current.reload()}} >查询</Button>
      </Col>
      <Col span={2}>
      <Button shape="round" className="m-btn-white" onClick={(event)=>{buttonBlur(event);refresh()}} >刷新</Button>
      </Col>
      <Col span={8} style={{textAlign:"right"}}>
      <Button type="default" shape="round" className='download' icon={<DownloadIcon />} onClick={()=>{showModal_QRCode()}} >
        扫码下载直播舱
      </Button>
      </Col>
    </Row>
  </div>

  <ProList
        grid={{
          gutter: 50,
          // column: 4,
          // xs: 1,
          // sm: 1,
          // md: 2,
          // lg: 2,
          // xl: 3,
          // xxl: 4,
        }}
        pagination={{
          onChange: (page) => {
            // console.log(page);
          },
          pageSize: 10,
        }}
        actionRef={actionRef}
        rowKey = "cloudPhoneNum"
        // dataSource={deviceList.rows}
        request={async (params = {})  => {
          // setClientlistloading(true);
          var list = [];
          if(currentUserinfo.userType!=99 || (currentUserinfo.userType==99 && curClientid)){
            // console.log('-----------------params----------------', params);
            const res = await getDeviceList(params);
            // console.log('------------------res--------------', res);
            if(res.rows){
              list = res.rows;
              var allstatus = {}
              occupyDevices = [];
              for(var i=0; i<list.length; i++){
                allstatus[list[i].cloudPhoneNum] = list[i].deviceStatus;
                if(list[i].deviceStatus == 2)
                  occupyDevices.push(list[i].cloudPhoneNum);
              }
              console.log('allstatus - ', showZhilianScreen, occupyDevices,occupyDevices.length);
              setListDeviceStatus(allstatus);
              if(occupyDevices.length > 0 && !showZhilianScreen){
                waitState = false;
                clearInterval(waitFlushInterHandler);
                waitFlushInterHandler = undefined;
                startFlushStateTimer();
              }
              else
                waitState = true;

            }
          }
          // // setClientlistloading(false);
          return {
              data: list,
              // current: res.data.pageNo,
              // pageSize: res.data.pageSize,
              success: true,
              // total: res.data.totalRows,
          };
        }}
        renderItem={(item) => (
          <List.Item>

        <div className='phoneClass' style={{paddingBottom:15}}>
          <div className='phoneSmarll'>
            <div className='phoneHole'></div>
            <div className='phoneText'><img src={[require("../admin/img/plogo.png")]} width={"40%"}/></div>
          </div>
          <div style={{padding:"8px 20px 5px"}}>
            <div style={{position:"relative",top:"5px",left:"180px",width:20,zIndex:2}}>
              <EditIcon key="edit"
                  onClick={() => {showModal(item)}} />
            </div>
            <div style={{position:"relative",top:"-10px",fontSize:6,lineHeight:"0.5rem",zIndex:1}}>
              <p>{item.nodeName}</p>
              <p>云手机序号：{item.cloudPhoneNum}</p>
              <p>所属分组：{item.nodeGroup}</p>
              {/* <p><span style={{position:"relative",top:"1.5px"}}>是否展示 <Tooltip placement="top" title="开启后，该云手机才会在直播舱APP中显示" overlayStyle={{fontSize:6}}><InfoCircleOutlined style={{fontSize:10,color:"#00f"}} /></Tooltip>
                        ：</span><Switch size='small' defaultChecked={item.showApp==1?true:false} onChange={(checked)=>onChangeSwitch(item.cloudPhoneNum,checked)} /></p> */}
              <p>状态：{allDeviceStatus[item.deviceStatus].text}</p>
              {/* <p>机型：{item.brand}</p> */}
              <p>设备号：{item.deviceNum}</p>
              <p>虚拟IP：{item.innerIp}</p>
              {/* <p>IMEI：{item.imei}</p> */}
              <p>安卓版本：{item.androidVersion}</p>
              <p>备注：{item.remark}</p>
            </div>
          </div>
          <div style={{width:"100%",textAlign:"center"}}>
            <Space>
              <Button type='primary' shape="round" size='small' style={{borderRadius:10,fontSize:10}} disabled={listDeviceStatus[item.cloudPhoneNum]!=1}  onClick={()=>onZhilian(item.cloudPhoneNum, item.deviceNum)} >直连</Button>
              <Button type='primary' shape="round" size='small' style={{borderRadius:10,fontSize:10}} onClick={()=>onUpdateParam(item.cloudPhoneNum)} >一键换参</Button>
              <Button type='primary' shape="round" size='small' style={{borderRadius:10,fontSize:10}} onClick={()=>{setCurDeviceInfo(item);setShowParamDetail(true)}}>参数详情</Button>
            </Space>
          </div>
        </div>
        </List.Item>
        )}
      />

    <div className='ant-modal-mask' id="mask2" style={{display:showZhilianScreen?"block":"none"}}></div>
    <iframe key="deviceresult" id="deviceresult" style={{display:"none"}} width={clientWidth} height={clientHeight*0.6}
      sandbox="allow-forms allow-popups allow-scripts allow-same-origin allow-modals"
      src={"/html/deviceresult.html?a=11"}
      name='iframe'
      scrolling="no"
    ></iframe>

    <Modal 
        key="basicinfo"
        title='基本信息'
        open={editModalOpen_bc}
        width="350px"
        centered
        footer={[<Button  className="m-btn-white" onClick={closeModal}>
        取消
        </Button>,
        <Button type="primary"  style={{ marginLeft:'20px'}} onClick={()=>{form.submit()}}>
        确认
        </Button>] }
        onCancel={closeModal}>
          <Form  layout="vertical" form={form} onFinish={editBasicInfo}>
            <Form.Item label='节点名称' name="name" rules={[
                  {required: true,message: '请输入节点名称',},
                ]} >
                <Input />
            </Form.Item>
            <Form.Item label='国家/地区' name="nodeGroup" >
              <Select
                placeholder="--请选择--"
                className='ant-select-selector'
                style={{width:"100%"}}
                options={countryList}
              />
            </Form.Item>
            <Form.Item label='备注' name="remark" >
                <Input />
            </Form.Item>
          </Form>   
    </Modal>

    <Modal 
        key="QRCode"
        title='扫码下载直播舱'
        open={QRCodeModalOpen}
        width="580px"
        centered
        footer={[] }// 设置footer为空，去掉 取消 确定默认按钮
        onCancel={handleCancel_QRCode}>

      <div className="site-card-wrapper">
        <Row gutter={24}>
          <Col span={12}>
            <Card title="国内版本" bordered={false}>
              {QRCodeUrl.domestic ? (<QRCode className="QRCode-qrCode" value={QRCodeUrl.domestic} size={200}/>) : (<LoadingOutlined />)}
            </Card>
          </Col>
          <Col span={12}>
            <Card title="海外版本" bordered={false}>
              {QRCodeUrl.oversea ? (<QRCode className="QRCode-qrCode" value={QRCodeUrl.oversea} size={200}/>) : (<LoadingOutlined />)}
            </Card>
          </Col>
        </Row>
      </div>

    </Modal>

    {/* <Zhilian
      showZhilianScreen={showZhilianScreen}
      selectPhones={toupingPhones}
      selectPhonesInfo={toupingPhonesInfo}
      zhilianClose={zhilianClose}
      pageType={"bc"}
    /> */}

    <Touping
      showZhilianScreen={allTouPingParams[0].show}
      zIndex={allTouPingParams[0].zIndex}
      selectPhones={[allTouPingParams[0].id]}
      selectPhonesInfo={allTouPingParams[0].info}
      toupingClose={toupingClose}
      updatemaxIndex={updatemaxIndex}
      pageType={"bc"}
      currentUserinfo={currentUserinfo}
      curClientid={curClientid}
      sessionid={sessionid}
    />

    <Updateparam
      exchangParam_bc={exchangParam_bc}
      curCloudPhoneNum={curCloudPhoneNum}
      deviceInfo={deviceInfo}
      handleExCancel={handleExCancel}
      pageType={"bc"}
    />

    <ParamDetail
      showParamDetail = {showParamDetail}
      curDeviceInfo = {curDeviceInfo}
      close = {close}
      pageType={"bc"}
    />

  </Content>


);
}
export default App;