import { message } from 'antd';
// import { history, connect } from 'umi';
// import { stringify } from 'querystring';


const checkLogin = (data) => {

    if (typeof data == 'string') {
        data = JSON.parse(data);
    }
    if (data.code && (data.code == 10010 || data.code == 10011 || data.code == 10012 || data.code == 10013 )) {
        localStorage.removeItem('token');
        localStorage.removeItem("UserInfo");
        message.error(data.msg);
        window.location.href='/#/login';
        // if (window.location.href.indexOf('user/login') == -1) {
            // history.replace({
            //     pathname: '/user/login',
            //     search: stringify({
            //         redirect: window.location.href,
            //     }),
            // });
        // }
        return false;
    } else if (data.code && data.code == 10008) {
        if(data.data == 8)
            window.location.href='/#/register/info';
        if(data.data == 9)
            window.location.href='/#/register/info';
    } else if (data.code === 0) {
        return true;
    } else if (data.code) {
        message.error(data.msg);
        return false;
    }
}

export default checkLogin