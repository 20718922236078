import React, { useEffect,useState } from 'react';
// import SparkMD5 from 'spark-md5';
import { Modal, Result, Button, Form, Input, Upload, message, Progress } from 'antd';
import axios from 'axios';
import { uploadTmpUrl } from '../service';
import CryptoJs from 'crypto-js';
import checkLogin from '../../../request/checkLogin.js'

const App = (props) => {
  const [form] = Form.useForm();
  const { showModel, curClientid, close, edit, onSubmit} = props;

  const [obsData , setObsData] = useState()


  // 进度条
  const [percent, setpercent] = useState(0);
  const [resourceName,setresourceName] = useState(null)
  const [fileInfo,setFileInfo] = useState({})
  const [percentShow,setPercentShow] = useState(false)
  
  useEffect(() => {
    if (form && !showModel) {
      form.resetFields();
      setPercentShow(false)
    }

  }, [props.showModel]);
 
  const handleSubmit =  async () => {
    const values = await form.validateFields();
    console.log('values ------------ ', values)
    onSubmit(values,fileInfo)
    
  };
 

     // 上传前的检验,指定上传类型，判断大小等
  const beforeUpload = (file, fileList) => {
    // console.log(file?.name)
    let str = file?.name
    // console.log(str.substring(str.lastIndexOf(".")+1))
    return new Promise((resolve, reject) => {
      if (file?.size > 1024 * 1024 * 1024 * 5) {
          message.error("请上传5G以内的文件");
          return reject(false);
      }else if(str.substring(str.lastIndexOf(".")+1) !='mp4'){
        message.error("请上传mp4的文件");
        return reject(false);
      }
       
      return resolve(true);
    });
  };
  // 重点-上传方法
  const uploadFile = async (res,data) => {
    console.log('res ----------',res);

    var reopt = {
      method : 'PUT',
      url : res.signatureUrl,
      withCredentials: false, 
      headers : res.requestHeaders || {},
      // headers: {
      //   'content-Type': '',
      //   // 'content-Type': 'multipart/form-data ',
      // }, 

      validateStatus: function(status){
              return status >= 200;
      },
      maxRedirects : 0,
      responseType : 'text',
      data : data.file,
      onUploadProgress: (data) => {
          let { total, loaded } = data;
          let percent = parseInt(loaded / total * 100) > 99 ? 100 : parseInt(loaded / total * 100);
          setpercent(percent)
      }

    };
    console.log('reopt --------- ', reopt)

    axios.request(reopt).then(function (response) {
        if(response.status < 300){                     
              console.log('Creating object using temporary signature succeed.');
              form.setFieldValue("uploadresult", "ok");
        }else{
          message.error("上传失败: " + response.status);
          console.log('Creating object using temporary signature failed!');
          console.log('status:' + response.status);
        }
        console.log(response.data);
        console.log('\n');
    }).catch(function (err) {
      message.error("上传失败！");
      console.log('Creating object using temporary signature failed22222222222222!');
      console.log(err);
    });
  }

  const upload = async (data) => {
    const file = data.file;
      setPercentShow(true)
      const { size, type, name ,lastModified} = file 
      const strfile = name+size+type+lastModified;
      // const spark = new SparkMD5()
      // spark.append(strfile)
      // const hash = spark.end() // 获取到的文件md5值
      const hash = CryptoJs.MD5(strfile).toString();
      var durl = hash+'.mp4';
      console.log(durl);

      try {
        uploadTmpUrl({objectKey:durl}).then((res) => {
          console.log('uploadTmpUrl---------', res);
        var flag = checkLogin(res.data)
        if (flag) {
          uploadFile(res.data.data, data);

          setFileInfo({size:size,resourceUrl:res.data.data.fileUrl,fileSign:hash});
          // console.log(!resourceName)
          // if(!resourceName) setresourceName(name);
        }
      });
      } catch (error) {
        // message.error(error);
      }



      // let lodeapp=await axios({
      //     method: 'POST',
      //     url: params.endpoint,
      //     headers: {
      //       'content-Type': 'multipart/form-data ',
      //     }, 
      //     data: fd,
      //     onUploadProgress: (data) => {
      //         let { total, loaded } = data;
      //         let percent = parseInt(loaded / total * 100) > 99 ? 100 : parseInt(loaded / total * 100);
      //         setpercent(percent)
      //     }
      // });
  }

    const uploadProps = {
      name: "file",
      accept: '.mp4',
      beforeUpload: beforeUpload,
      multiple: false, 
      customRequest: upload,  //  重新定义上传方法
      onChange: (info) => {
        console.log('------------info-------------',info)
        if (info.file.status === "done") {
          
        } else if (info.file.status === "error") {
          message.error(`${info.file.name} 上传失败.`);
        } else if (info.file.status === "removed") {
         
        }
        
      },
    };


  const getModalContent = () => {
  
    return (
      <Form {...formLayout} form={form} >
        <Form.Item
          name="name"
          label="素材名称"
          rules={[
            {
              required: true,
              message: '请输入素材名称',
            },
          ]}
          
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="uploadresult"
          label="上传素材"
          rules={[
            {
              required: true,
              message: '请上传素材',
            },
          ]}
        >
          <Upload {...uploadProps}>
            <Button className='m-button-blue'>上传</Button>
          </Upload>
          <Progress percent={percent} style={{width:'75%',top:-30,left:80,display:percentShow?'block':'none'}}/>
          <div style={{color:'#ccc',fontSize:'12px',lineHeight: '20px',paddingTop:10,width:"110%"}}>提示：上传视频素材只支持mp4格式，单次上传最大支持5G。</div>
            
        </Form.Item>
       
      </Form>
    );
  };

  const formLayout = {
    labelCol: {
      span: 5,
    },
  
    wrapperCol: {
      span: 17,
    },
  };
  
  const modalFooter = {
    okText: edit ? '同步' : '保存',
    cancelText: '取消',
    onOk: handleSubmit,
  };

  return (
    <div>
      <Modal
        forceRender={true}
        title={edit ? '同步素材' : '新增素材'}
        width={500}
        centered
        destroyOnClose
        open={showModel}
        {...modalFooter}
        onCancel={close}

      >
        {getModalContent()}
      </Modal>
       
    </div>
  );
};

export default App;
