import http from "../../../request/http";
import CryptoJs from 'crypto-js';

// 直播舱参数详情
export async function paramDetail_bc(params) {
  return http('live/broadcast/cloudphone/param/detail/search', {
    method: 'POST',
    data: { ...params },
  });
}

// 养号舱参数详情
export async function paramDetail_stay(params) {
  return http('yang/broadcast/cloudphone/param/detail/search', {
    method: 'POST',
    data: { ...params },
  });
}

// 无人直播舱参数详情
export async function paramDetail_unm(params) {
  return http('nobody/live/broadcast/cloudphone/param/detail/search', {
    method: 'POST',
    data: { ...params },
  });
}
