import React,{useState,useEffect}  from 'react';
import ProForm, { ModalForm, ProFormTextArea, ProFormSelect, } from '@ant-design/pro-form';
import { Button, message, Modal, Input, Form,Radio ,Drawer,Upload ,Space,Cascader,InputNumber} from 'antd';
import { appInstallCount} from '../service';
import checkLogin from "../../../request/checkLogin.js"

const App = props => {
  const { showInstall,closeInstall,curClientid, curappId,curappInstcount,submitInstall} = props;
  const [form] = Form.useForm();
  console.log('curappInstcount:-------', curappInstcount)
  form.setFieldValue("validCount", curappInstcount);

    const [ShowAddType, setShowAddType] = useState(true);
    // const [validCount, setValidCount] = useState("");

    const TypeonChange =(value)=>{
        // addTypeonChange(value)
        let addTypeValue = value.target.value;
        if(addTypeValue == 0)
          setShowAddType(true);
        else if(addTypeValue == 1)
          setShowAddType(false);
    }

    const close =()=>{
      form.setFieldValue("installCount","");
      form.setFieldValue("cloudPhoneNums","");
      closeInstall();
    }

    useEffect(() => { 
      if(showInstall){
        // // 获取可安装数量
        // try {
        //   var params = {appId:curappId};
        //   if(curClientid)
        //     params.primaryAccount = curClientid;
        //   appInstallCount(params).then((res) => {
        //     console.log('appInstallCount-res-----------------', res);
        //     var flag = checkLogin(res.data);
        //     if(flag){
        //       setValidCount(res.data.data);
        //       form.setFieldValue("validCount", res.data.data);
        //     }
        //     // else
        //     //   message.error(res.data.msg);
        //   });
        // } catch (error) {
        //   message(error);
        // }

      }
      
    },[showInstall]); 

  return (
    <ModalForm form={form}
    className='deviceOption'
    layout="horizontal" 
    title="安装"
    modalProps={{
      onCancel: () => close(),
      centered: true,
    }}
    width={400}
    open={showInstall}
    onFinish={async (values) => {
      console.log('values-----------------------', values)
      
      if(values.installMode == 0){
        if(!values.installCount){
          message.error("请输入安装量");
          return;
        }
      }
      else if(values.installMode == 1){
        if(!values.cloudPhoneNums){
          message.error("请输入云手机序号");
          return;
        }
        else{
          values.cloudPhoneNums = values.cloudPhoneNums.split(/[\s\n]/);
        }
      }
      submitInstall(values);
      // values.deviceNums = values.deviceNums ? values.deviceNums.split(/[\s\n]/) : [],
      // values.deviceCount = values.deviceCount ? values.deviceCount : '',
        // Modal.confirm({
        //   title: '请您确认信息',
        //   content: '安装前请您确认所选的企业',
        //   okText: '确认',
        //   cancelText: '取消',
        //   onOk: () => onFinish(values),
        // });
    }}
  >
    <ProForm.Group>
      <Form.Item 
        label="可操作云手机"
        name="validCount"
        style={{marginTop:-10,color:"rgb(63,140,255)"}}
        // initialValue={curappInstcount}
      >
        <Input  bordered={false} disabled />
      </Form.Item>
      <div style={{width:370,height:1,borderBottom:"1px solid #e8e8e8",marginLeft:-24,marginTop:-10}}></div>
      <Form.Item label='安装方式'>
        <Form.Item  name="installMode" initialValue={0} >
          <Radio.Group  style={{marginBottom:15,marginTop:6}} onChange={TypeonChange}>
            <Radio value={0}>随机安装</Radio>
            <Radio value={1}>定制安装</Radio>
          </Radio.Group>
        </Form.Item> 
        <div >
          <div  style={{ marginTop:15,display: !ShowAddType ? 'none' : 'block',marginLeft:-70,}}>
            <Form.Item 
                label="安装量"
                name='installCount'
              >
                <InputNumber min={1} max={curappInstcount} style={{borderRadius:8,width:"100%"}} />
            </Form.Item>
          </div>
          <div  style={{
              marginTop:15,
              display: !ShowAddType ? 'block' : 'none',
              marginLeft:-70
            }}>
          <ProFormTextArea label="请输入云手机序号" name="cloudPhoneNums" style={{borderColor:"#ccc"}}
            tooltip="每行一个云手机序号,单次不能超超过500"
          />
          </div> 
        </div>
      </Form.Item> 
      
    </ProForm.Group>
  </ModalForm>
  )
}

function areEqual(prevProps, nextProps) {
  /*
  如果把 nextProps 传入 render 方法的返回结果与
  将 prevProps 传入 render 方法的返回结果一致则返回 true，
  否则返回 false
  */
  if(prevProps === nextProps ) {
    return true
  }
}

export default  App
