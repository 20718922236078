import http from "../../request/http";
import CryptoJs from 'crypto-js';

// 成员列表
export const memberList = (data) =>{
    return http ({
        method:'POST',
        url:'member/page',
        data
    })
}

// 获取使用场景Options
export const memberScene = (data) =>{
    return http ({
        method:'POST',
        url:'member/scene/options',
        data
    })
}

// 获取菜单options
export const menuOptions = (data) =>{
    return http ({
        method:'POST',
        url:'member/menu/options',
        data
    })
}

// 获取账号对应客户下的云手机列表
export const memberPhone = (data) =>{
    return http ({
        method:'POST',
        url:'member/cloud/phones',
        data
    })
}

// 新增成员
export const memberAdd = (data) =>{
    if(data.password)
        data.password = CryptoJs.MD5(data.password).toString();
    return http ({
        method:'POST',
        url:'member/add',
        data
    })
}

// 修改密码
export const updatePassword = (data) =>{
    if(data.password)
        data.password = CryptoJs.MD5(data.password).toString();
    return http ({
        method:'POST',
        url:'member/update/password',
        data
    })
}

// 修改备注
export const updateRemarks = (data) =>{
    return http ({
        method:'POST',
        url:'member/update/remarks',
        data
    })
}

// 删除成员
export const memberDelete = (data) =>{
    return http ({
        method:'POST',
        url:'member/delete',
        data
    })
}

// 启用成员
export const memberEnable = (data) =>{
    return http ({
        method:'POST',
        url:'member/enable',
        data
    })
}

// 禁用成员
export const memberDisable = (data) =>{
    return http ({
        method:'POST',
        url:'member/disable',
        data
    })
}

// 获取成员详情
export const memberDetail = (data) =>{
    return http ({
        method:'POST',
        url:'member/detail',
        data
    })
}

// 修改成员信息
export const memberUpdate = (data) =>{
    return http ({
        method:'POST',
        url:'member/update',
        data
    })
}

// 获取成员的设备列表
export const memberHadPhones = (data) =>{
    return http ({
        method:'POST',
        url:'member/had/cloud/phones',
        data
    })
}
