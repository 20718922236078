import Icon,{ RedoOutlined, SyncOutlined,LoadingOutlined,ExclamationCircleOutlined} from '@ant-design/icons';
import { Layout, Input,Select,Space,Button,Modal,Form,Radio,message,Spin  } from 'antd';
import React, { Component,useState,useEffect }  from 'react';
import { getPhoneBrand, getSimCountry, getLanguage, getTimezone,paramUpdate_bc,paramUpdate_stay,paramUpdate_unm,getDeviceInfo_bc,getDeviceInfo_stay,getDeviceInfo_unm} from './service';
import {LoadingIcon} from '../icons';
import checkLogin from '../../../request/checkLogin.js'

import './index.css';
const allStatus = {1:"更新中", 2:"更新成功", 3:"更新失败"};

let waitFlushInterHandler = undefined; //add by jerry
let waitState = false; //add by jerry

const App = (props) => {
  const {exchangParam_bc, curCloudPhoneNum, deviceInfo, handleExCancel,pageType} = props;
  const [form] = Form.useForm()

  // const [deviceInfo,setDeviceInfo] = useState({});
  const [PhoneBrand,setPhoneBrand] = useState([]);
  const [PhoneModel,setPhoneModel] = useState([]);
  const [SimCountry,setSimCountry] = useState([]);
  const [Language,setLanguage] = useState([]);
  const [Timezone,setTimezone] = useState([]);
  const [ModelDisabled,setModelDisabled] = useState(false);
  const [CountryDisabled,setCountryDisabled] = useState(false);
  const [loading,setLoading] = useState(false);
  const [optionState,setOptionState ] = useState(false)
  
  /**
   * 定时获取设备状态
   * @returns {Promise<void>}
   */
  const startFlushStateTimer = () => {
    if (waitFlushInterHandler === undefined) {
      waitFlushInterHandler = setInterval(async () => {
        if (waitState) {
          waitState = false;
          clearInterval(waitFlushInterHandler);
          waitFlushInterHandler = undefined;
        } else {
          console.log('run:' + new Date().toString());
          await phoneCloudInfoFun();
        }
      }, 2000);
    }
  };

  const confirm = () => {
    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '云手机参数已更新完成，点击确认关闭页面',
      okText: '确认',
      cancelText: '取消',
      onOk: () => onClose(),
    });
  };

  const confirm_error = () => {
    Modal.error({
      title: '错误',
      content: '云手机参数更新失败！',
      okText: '关闭',
    });
  };

  /**
   * 从服务器获取当前设备状态
   * @returns {Promise<boolean>}
   */
  const phoneCloudInfoFun = async () => {
    if (!deviceInfo) return;
    // setPhoneShowType(false);
    // setOptionState(false);
    var fun = pageType=='stay'?getDeviceInfo_stay:(pageType=='bc'?getDeviceInfo_bc:getDeviceInfo_unm);
    console.log(fun)
    try {
      await fun({cloudPhoneNum: deviceInfo.cloudPhoneNum}).then((res) => {
        console.log(fun+'---------', res);
        var flag = checkLogin(res.data);
        if (flag) {
          // if (res.data.data) {
          //   let data = res.data;
          //   let obj = {
          //     brand: data.brand,
          //     brandSelect: data.brandSelect,
          //     countryIso: data.countryIso,
          //     deviceId: data.deviceId,
          //     exists: data.exists,
          //     language: data.language,
          //     model: data.model,
          //     simCountrySelect: data.simCountrySelect,
          //     timeZone: data.timeZone,
          //   };
          //   form.setFieldsValue({
          //     ...obj,
          //   });
            // phoneBrandFun(1);
            // countrySIMFun();
            // languageOptionsFun();
            // timeZoneOptionsFun();

            // setSIMShowType(phoneInfo?.simCountrySelect);
            // setPhoneShowType(phoneInfo?.brandSelect);
            // phoneBrandFun(1, phoneInfo?.brand);
            form.setFieldsValue({
              status: allStatus[res.data.data.status],
            });

            if (res.data.data.status !== 1) {
              waitState = true;

              setTimeout(() => {
                setOptionState(false);
                setLoading(false);
                if(res.data.data.status == 2)
                  confirm();
                else
                  confirm_error();
              }, 100);
            } else {
              setTimeout(() => {
                setOptionState(true);
                setLoading(true);
                form.setFieldsValue({
                  status: '更新中',
                });
              }, 100);
            }
          // } else {
          //   message.warn('参数信息更新中，请稍后查看');
          // }

          // }, 1000);

          // setSIMShowType(res.data?.simCountrySelect);
          // setPhoneShowType(res.data?.brandSelect);
          // phoneBrandFun(res.data?.brand);
        }
        return true;
      });
    } catch (error) {
      console.log(error);
      return true;
    }
  };

  const randElementFun = (arr) =>{
    // console.log(arr)
    let randElement = arr[Math.floor(Math.random() * arr.length)];
    // console.log(randElement);
    return randElement
  }

  // 获取手机品牌
  const getBrandAndModel = async (brand, select) => {
    // console.log('--------------band---------------',brand);
    try {
      await getPhoneBrand({brand:brand}).then((res) => {
        // console.log('getPhoneBrand---------', res);
      var flag = checkLogin(res.data)
      if (flag) {
        if(brand){
          setPhoneModel(res.data.data);
          if(select==0)
            form.setFieldValue("model", deviceInfo.model);
          else{
            let value =  randElementFun(res.data.data);
            form.setFieldsValue({
              model:value.value,
            });
          }
        }
        else
          setPhoneBrand(res.data.data);
      }
      else
        message.error(res.data.msg);
    });
    } catch (error) {
      message.error(error);
    }
  };

  const onChangeBrand = (e) => {
    getBrandAndModel(e, 1);
  }

  const onBrandSelect = (e) => {
    // console.log(e.target.value);
    if(e.target.value == 0){
      setModelDisabled(true);
      let value =  randElementFun(PhoneModel);
      form.setFieldValue("model", value.value);
    }
    else
      setModelDisabled(false);

    // setPhoneShowType(e.target.value);
    // if(!e.target.value){
    //   let value =  randElementFun(PhoneOptions);
    //   form.setFieldsValue({
    //     model:value?.value,
    //   });
    // }
  }

  const onChangeSIM = (e) =>{
    // console.log(e);
    if(e == 1)
      setCountryDisabled(false);
    else{
      setCountryDisabled(true);
      if(e == 0){
        let value =  randElementFun(SimCountry);
        form.setFieldsValue({
          countryIso:value?.value,
          language:value?.language,
          timeZone:value?.tz
        });
      }
      else if(e == 2)
        form.setFieldValue("countryIso", deviceInfo.countryIso);
    }
    // setSIMShowType(e)
    // if(e == 0){
    //   let value =  randElementFun(SIMOptions);
    //   form.setFieldsValue({
    //     countryIso:value?.value,
    //     language:value?.language,
    //     timeZone:value?.tz
    //   });
    // }
  }

  
  const OnchangeSIMCountry = (value, obj) =>{
    // console.log(value)
    // console.log(obj)
    form.setFieldsValue({
      timeZone:obj.tz,
      language:obj.language
    });
  }


  const onFinish = (value) =>{
    // console.log(value);
    value.cloudPhoneNum = curCloudPhoneNum;
    delete value.status;
    var fun = pageType=='stay'?paramUpdate_stay:(pageType=='bc'?paramUpdate_bc:paramUpdate_unm);
    try {
      fun(value).then((res) => {
        // console.log('paramUpdate---------', res);
      var flag = checkLogin(res.data)
      if (flag) {
        message.success("操作成功")
        setOptionState(true);
        setLoading(true)
        startFlushStateTimer();
      }
      // else
      //   message.error(res.data.msg);
    });
    } catch (error) {
      // message.error(error);
    }

  }

  const onClose = () =>{
    clearInterval(waitFlushInterHandler);
    waitFlushInterHandler = undefined;

    // setDeviceInfo({});
    // setPhoneBrand([]);
    setPhoneModel([]);
    // setSimCountry([]);
    // setLanguage([]);
    // setTimezone([]);
    setModelDisabled(false);
    setCountryDisabled(false);
    setLoading(false);

    form.setFieldValue("brand", null);
    form.setFieldValue("brandSelect", 0);
    form.setFieldValue("model", null);
    form.setFieldValue("simCountrySelect", 1);
    form.setFieldValue("countryIso", null);
    form.setFieldValue("language", null);
    form.setFieldValue("timeZone", null);
    form.setFieldValue("status", '');

  
    handleExCancel();
  }

  // 获取手机参数信息
  useEffect(() => {
    if(exchangParam_bc){
      // 获取手机品牌
      getBrandAndModel("", 0);

      // 获取SIM卡国家
      try {
        getSimCountry({id:curCloudPhoneNum}).then((res) => {
          // console.log('getSimCountry---------', res);
        var flag = checkLogin(res.data)
        if (flag) {
          setSimCountry(res.data.data);
        }
        else
          message.error(res.data.msg);
      });
      } catch (error) {
        message.error(error);
      }

      // 获取语言
      try {
        getLanguage({id:curCloudPhoneNum}).then((res) => {
          // console.log('getLanguage---------', res);
        var flag = checkLogin(res.data)
        if (flag) {
          setLanguage(res.data.data);
        }
        else
          message.error(res.data.msg);
      });
      } catch (error) {
        message.error(error);
      }

      // 获取时区
      try {
        getTimezone({id:curCloudPhoneNum}).then((res) => {
          // console.log('getTimezone---------', res);
        var flag = checkLogin(res.data)
        if (flag) {
          setTimezone(res.data.data);
        }
        else
          message.error(res.data.msg);
      });
      } catch (error) {
        message.error(error);
      }


      // console.log('----------curdeviceInfo----------', deviceInfo)
      // 获取机型
      getBrandAndModel(deviceInfo.brand, 0);
      form.setFieldValue("brand", deviceInfo.brand);
      form.setFieldValue("brandSelect", deviceInfo.brandSelect);
      // form.setFieldValue("model", deviceInfo.model);
      form.setFieldValue("simCountrySelect", deviceInfo.simCountrySelect);
      form.setFieldValue("countryIso", deviceInfo.countryIso);
      form.setFieldValue("language", deviceInfo.language);
      form.setFieldValue("timeZone", deviceInfo.timeZone);
      form.setFieldValue("status", deviceInfo.status==2?'正常':allStatus[deviceInfo.status]);
      if(deviceInfo.brandSelect == 0)
        setModelDisabled(true);
      else
        setModelDisabled(false);

      if(deviceInfo.simCountrySelect == 1)
        setCountryDisabled(false);
      else
        setCountryDisabled(true);

      if(deviceInfo.status == 1){
        setOptionState(true);
        setLoading(true)
        startFlushStateTimer();
      }
      else{
        setOptionState(false);
        setLoading(false)
      }

    }
  }, [exchangParam_bc]);

  return (
    <Modal 
        // key="changeparam"
        title='一键换参'
        open={exchangParam_bc}
        centered
        // style={{
        //   top: 15,
        // }}
        // width="350px"
        footer={[<Button  shape='round' htmlType="button"  onClick={onClose} className="m-btn-white">
        关闭
        </Button>,
        <Button type="primary" htmlType="submit" shape='round' loading = {loading}  style={{ marginLeft:'20px'}} onClick={()=>{form.submit()}}>
        确认
        </Button>] }// 设置footer为空，去掉 取消 确定默认按钮
        onCancel={onClose}>   
          <Form 
            form={form}
            labelCol={{
              span: 5,
            }}
            // initialValues={{
            //   brand:deviceInfo.brand, 
            //   brandSelect:deviceInfo.brandSelect, 
            //   model:deviceInfo.model, 
            //   simCountrySelect:deviceInfo.simCountrySelect, 
            //   countryIso:deviceInfo.countryIso, 
            //   language:deviceInfo.language, 
            //   timeZone:deviceInfo.timeZone, 
            //   status:allStatus[deviceInfo.status], 
            // }}
            onFinish={onFinish}
          >
            <Form.Item label='品牌' name="brand" >               
            <Select
              placeholder="请选择"
              className='ant-select-selector'
              options={PhoneBrand}
              onChange={onChangeBrand}
              disabled={optionState}
              showSearch
              filterOption={(input, option) =>{
                return option.label?.toLowerCase().includes(input.toLowerCase())
              } }
            />     
            </Form.Item>
            <Form.Item label='机型选择' name="brandSelect" >
            <Radio.Group onChange={onBrandSelect} disabled={optionState}>
              <Space>
              <Radio.Button value={0}>随机机型</Radio.Button>
              <Radio.Button value={1}>指定机型</Radio.Button>
              </Space>
            </Radio.Group>
            </Form.Item>
            <Form.Item label='机型' name="model" >               
            <Select
              placeholder="请选择"
              className='ant-select-selector'
              options={PhoneModel}
              disabled={ModelDisabled || optionState}
              // disabled={phoneShowType==0 || optionState}
              showSearch
              filterOption={(input, option) =>{
                return option.label?.toLowerCase().includes(input.toLowerCase())
              } } 
            />     
            </Form.Item>
            <Form.Item label='SIM卡选择' name="simCountrySelect" >
            <Select
              className='ant-select-selector'
              onChange={onChangeSIM}
              disabled={optionState}
              options={[
                {
                  "value": 0,
                  "label": "随机国家",
                },
                {
                  "value": 1,
                  "label": "指定国家",
                },
                {
                  "value": 2,
                  "label": "不重置",
                },
              ]}
            />     
            </Form.Item>
            <Form.Item label='SIM卡国家' name="countryIso" >               
            <Select
              placeholder="请选择"
              className='ant-select-selector'
              options={SimCountry}
              onSelect={OnchangeSIMCountry}
              disabled={CountryDisabled || optionState}
              showSearch
              filterOption={(input, option) =>{
                return option.label?.toLowerCase().includes(input.toLowerCase())
              } } 
            />     
            </Form.Item>
            <Form.Item label='语言' name="language" >               
            <Select
              placeholder="请选择"
              className='ant-select-selector'
              options={Language}
              disabled={optionState}
              showSearch
              filterOption={(input, option) =>{
                return option.label?.toLowerCase().includes(input.toLowerCase())
              } } 
            />     
            </Form.Item>
            <Form.Item label='时区' name="timeZone" >               
            <Select
              placeholder="请选择"
              className='ant-select-selector'
              options={Timezone}
              disabled={optionState}
              showSearch
              filterOption={(input, option) =>{
                return option.label?.toLowerCase().includes(input.toLowerCase())
              } } 
            />
            </Form.Item>
            <Form.Item
              name="status"
              label="状态"
              >
               <Input bordered={false}   readOnly  />
               
          </Form.Item>
          <div style={{ marginLeft: '200px', marginTop: '-50px' }}>
            <RedoOutlined
              style={{
                color: '#1890ff',
                transform: 'rotate(-90deg)',
                marginLeft: '40px',
                display: optionState ? 'none' : 'inline-block',
              }}
              onClick={() => {
                phoneCloudInfoFun();
              }}
            />
            <Spin spinning={optionState} style={{ marginLeft: '40px' }}></Spin>
          </div>
          <div style={{color:"rgb(170,170,170)", paddingLeft:90,marginTop:20}}>
          <div>提示：更新参数后，时区与语言需重启设备才可生效</div>
          <div style={{paddingLeft:42}}>更新失败后请重试或联系管理员</div>
          </div>
        </Form>   
    </Modal>

);
}
export default App;