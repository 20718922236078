import { Space, Button, message, Popconfirm,Dropdown,Menu,Modal } from 'antd';
import React , { useRef, useState,useEffect }from 'react'
import checkLogin from "../../../request/checkLogin.js"
import { ProList } from '@ant-design/pro-components';
import '../index.css';
import { appList,appCreate,appInstallCount,appInstall,appCoverUpdate,appUpdateApk } from '../service';
import Addmaterial from './addmaterial.jsx';
import SyncphoneModel from './syncphone.jsx';
import PhonelistModel from './appphonelist.jsx';
import Appinstall from './appinstall.jsx';
import Addapp from './addapp.jsx';
import Appupdateapk from './appupdateapk.jsx';
// import { EditIcon } from '../../components/icons';
import Icon,{ EllipsisOutlined,ReloadOutlined } from '@ant-design/icons';
import CustomProList from '../../components/CustomProList';
import { buttonBlur } from '../../../utils/global.js';


const App = props => {
  const { currentUserinfo,curClientid, actionRef_3} = props;

  const [curappId, setCurappId] = useState(false);
  const [curappInstcount, setCurappInstcount] = useState("");

  //新增App
  // const [getOption, setOption] = useState();
  // const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState(undefined);
  const [done, setDone] = useState(false);

  const [syncPhoneShow,setShowSyncPhone] = useState(false);
  const [phoneListShow,setphoneListShow] = useState(false);
  const [resourceId,setResourceId] = useState("");
  const [edit,setEditState] = useState(false);
  const [showModel,setShowModel] = useState(false);
  const [showInstall, setShowInstall] = useState(false);
  const [showAddapp, setShowAddapp] = useState(false);
  const [showUpdateApk, setShowUpdateApk] = useState(false);
  const [corpDetail, setCorpDetail] = useState({});  //app信息
  const [getBasicInfo, setBasicInfo] = useState({
    appId: '',
    enterpriseId:'',
    iaasId:'',
    installMode:0,
    groupId:'',
}); 

  const closeSyncPhone = () =>{
    setShowSyncPhone(false);
  }

  const closePhoneList = () =>{
    setphoneListShow(false);
  }

  const close = () =>{
    setShowModel(false);
  }

  const closeAddapp = () =>{
    setShowAddapp(false);
  }

  const closeInstall = () =>{
    setShowInstall(false);
    setCurappInstcount("")
  }

  const closeUpdateApk = () =>{
    setShowUpdateApk(false);
  }
  
  const isShow = (btnName)=>{
    // let name = 'app:'+ btnName
    // let permissions = JSON.stringify(currentUser.permissions);
    // if(permissions.indexOf(name) !== -1 || currentUser.accountType===1){
      return true
    // }else{
      // return false
    // }
  }


  const columsName = {
    'name' : {
      title:'APP名称', 
      width:'20%'
    },
    'pkgName' : {
      title:'包名', 
      width:'30%',
    },
    'appStatus' : {
      title:'状态', 
      width:'15%',
      valueEnum: {
        0: {
          text: ('未云化'),
          status: 'warning',
        },
        1: {
          text: ('云化中'),
          status: 'processing',
        },
        2: {
          text: ('云化完成'),
          status: 'success',
        },
        3: {
          text: ('云化失败'),
          status: 'error',
        },
      },
    },
    'installedCount' : {
      title:'安装数量', 
      width:'15%'
    },
    'uploadDt' : {
      title:'上传时间', 
      width:'20%'
    },
    // 'op' : '操作',
  };

  // 获取app列表
  const getAppList = async (params) =>{
    var result = {};
    params.pageNo = params.current;
    delete params.current;
    if(curClientid)
      params.primaryAccount = curClientid;

    try {
      await appList(params).then((res) => {
        // console.log('appList-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          result = res.data.data;
          // console.log('result-----', result);
          return result;
        }
        else
          message.error(res.data.msg);
      });
    } catch (error) {
      message(error);
    }
    return result;
  }

  // 点击安装
  const onInstall = async (id) =>{
    var params = {appId:id};
    if(curClientid)
      params.primaryAccount = curClientid;
    try {
      await appInstallCount(params).then((res) => {
        // console.log('appInstallCount-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          setCurappInstcount(res.data.data);
          setCurappId(id);
          setShowInstall(true);
        }
        // else
        //   message.error(res.data.msg);
      });
    } catch (error) {
      message(error);
    }
  }

  // 安装功能
  const submitInstall = async (values) =>{
    // console.log('submitInstall------values-----------------------', values)
    var params = {appId:curappId, installMode:values.installMode};
    if(curClientid)
      params.primaryAccount = curClientid;
    if(values.installMode == 0)
      params.installCount = values.installCount;
    else if(values.installMode == 1)
      params.cloudPhoneNums = values.cloudPhoneNums;
    try {
      await appInstall(params).then((res) => {
        // console.log('appInstall-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          message.success("操作成功")
          setShowInstall(false);
          actionRef_3.current.reload();
        }
        // else
        //   message.error(res.data.msg);
      });
    } catch (error) {
      message(error);
    }
  }

  const handleDone = () => {
    // setAddBtnblur();
    setDone(false);
    // setVisible(false);
  };

  const handleSubmit = async(values) => {
    // console.log('values--------------',values)
    if(!values)
      return;
    if(curClientid)
      values.primaryAccount = curClientid;
    try {
      await appCreate(values).then((res) => {
        // console.log('appCreate-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          message.success("操作成功");
          actionRef_3.current.reload();
          closeAddapp();
        }
        // else
        //   message.error(res.data.msg);
      });
    } catch (error) {
      message(error);
    }
  };
  
  // 更新Apk功能
  const updateApkFun = async(fileUrl) => {
    // console.log('fileUrl--------------',fileUrl)
    if(!fileUrl)
      return;
    var params = {appId:curappId, appUrl:fileUrl};
    if(curClientid)
      params.primaryAccount = curClientid;
    try {
      await appUpdateApk(params).then((res) => {
        // console.log('appUpdateApk-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          message.success("操作成功");
          actionRef_3.current.reload();
          // closeUpdateApk();
        }
        // else
        //   message.error(res.data.msg);
      });
    } catch (error) {
      message(error);
    }
  };

  // 新版本覆盖安装
  const coverInstallFun = async (id) => {
    if(!id) return true
    var params = {appId:id};
    if(curClientid)
      params.primaryAccount = curClientid;
    try {
      await appCoverUpdate(params).then((res) => {
        // console.log('appCoverUpdate-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          message.success("操作成功");
          actionRef_3.current.reload();
        }
        // else
        //   message.error(res.data.msg);
      });
    } catch (error) {
      message(error);
    }
  }

  return (
    <div>
        <ProList rowKey="id" 
        actionRef={actionRef_3}

        toolBarRender={() => {
          return [
              <Button type="primary" shape="round" onClick={() => {
                if(currentUserinfo.userType==99 && !curClientid)
                  message.warning("请先选择客户")
                else
                  setShowAddapp(true)
              }}>新增APP</Button>,
              // <Button type="primary" shape="circle" icon={<ReloadOutlined />} />,
              <ReloadOutlined style={{marginRight:10}} onClick={() => {actionRef_3.current.reload();}} />
          ];
        }}
        search={{
          filterType: 'light',
        }}
        pagination={{ defaultPageSize: 10, showSizeChanger: true, }}
        // dataSource={dataSource} 
        request={async (params = {})  => {
          var tableData = [];
          // console.log('params----------------', params);
          if(currentUserinfo.userType!=99 || (currentUserinfo.userType==99 && curClientid)){
            const res = await getAppList(params);
            // console.log('res--------------', res);
            if(res.rows){
              res.rows.map((v,k)=>{
                // console.log('v--------------', v);
                tableData[k] = v;
                tableData[k]['content'] = {...v};
              })
            }
            // console.log('tableData--------------', tableData);
          }
        
          return {
              data: tableData,
              // current: res.data.pageNo,
              // pageSize: res.data.pageSize,
              success: true,
              // total: res.data.totalRows,
          };
        }}
        metas={{
          name: {
              // dataIndex: 'name',
              title: 'APP名称',
          },
          // scene: {
          //   // 自己扩展的字段，主要用于筛选，不在列表中显示
          //   title: '客户类型',
          //   dataIndex: 'type',
          //   valueType: 'select',
          //   valueEnum: {
          //     8: {
          //       text: ('个人客户'),
          //       status: 'Default',

          //     },
          //     9: {
          //       text: ('企业客户'),
          //       status: 'Success',

          //     },
          //   },
          // },

          content: {
              dataIndex: 'content',
              search: false,
              render: (text) => (
                <CustomProList 
                  columsName = {columsName}
                  text = {text}
                />
              ),
          },
          actions: {
              render: (text, data) => [
                <div>
                <div style={{ color: '#00000073',textAlign:"left" }}>操作</div>
                <div style={{ color: '#000000D9',marginTop:6 }}>
                <Space>
                <Button size="small"  className='m-button-blue' disabled={data.appStatus!==2}
                onClick={(event) => {
                  buttonBlur(event);
                  onInstall(data.id);
                }}
                > 安装 </Button>
                <Button size="small"  className='m-button-green'
                onClick={(event) => {
                  buttonBlur(event);
                  setCurappId(data.id);
                  setphoneListShow(true);
                }}
                > 云手机列表 </Button>
                <Dropdown  overlay={()=>{
                  return(
                  <Menu onClick={()=>{}} style={{borderRadius:8}} >
                    <Menu.Item key="update" style = {{display:isShow('update:apk')?'block':'none'}}
                    onClick={()=>{
                      setCurappId(data.id);
                      setShowUpdateApk(true);
                    }}
                    >更新Apk</Menu.Item>
                    
                    <Menu.Item key="updateNewapp" style = {{display:isShow('cover:install')?'block':'none'}}
                    onClick={()=>{
                      Modal.confirm({
                        title: '提示',
                        content: '确认执行新版本覆盖安装？',
                        okText: '确认',
                        cancelText: '取消',
                        onOk: () => coverInstallFun(data.id),
                      });
                      
                    }}>新版本覆盖安装</Menu.Item>  
                    
                  </Menu>
                  )}}>
                  <EllipsisOutlined  style={{color:"rgb(63,140,255)",fontSize:20}}/>
                </Dropdown>
                </Space>
                </div>
                </div>
              ],
          },
        }} 
        />


    {/* <SyncphoneModel
        syncPhoneShow = {syncPhoneShow}
        closeSyncPhone = {closeSyncPhone}
        resourceId = {resourceId}
    /> */}

    <PhonelistModel
        phoneListShow = {phoneListShow}
        closePhoneList = {closePhoneList}
        curappId = {curappId}
    />

    <Appinstall
        showInstall={showInstall}
        closeInstall={closeInstall}
        curClientid={curClientid}
        curappId={curappId}
        curappInstcount={curappInstcount}
        submitInstall={submitInstall}
    />

    <Addapp
        showAddapp={showAddapp}
        closeAddapp={closeAddapp}

        done={done}
        current={current}
        // visible={visible}
        onDone={handleDone}
        // onCancel={handleCancel}
        onSubmit={handleSubmit}

    />

    <Appupdateapk
        showUpdateApk={showUpdateApk}
        closeUpdateApk={closeUpdateApk}
        onSubmit={updateApkFun}
    />


    </div>
);
        }
export default App;