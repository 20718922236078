import Icon,{ LaptopOutlined, NotificationOutlined, UserOutlined,DownloadOutlined,RedoOutlined,SearchOutlined,MenuOutlined,RollbackOutlined,SoundOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu,Input,Select,Card,Space,Col, Row ,Button,Modal,Form,Radio,message,Checkbox,Drawer,Tree,Table  } from 'antd';
import React, { Component,useState,useEffect }  from 'react';
import {memberScene,menuOptions,memberPhone,memberAdd,memberDetail,memberUpdate,memberHadPhones} from '../service';
import checkLogin from '../../../request/checkLogin.js'
import { render } from '@testing-library/react';

const { TextArea,Search  } = Input;

var apiReqBody = {};

// import './index.css';
const App = (props) => {
  const {curClientid, showDetail, closeDetail} = props;
  const [form] = Form.useForm();

  const columns = [
    {
      title: '云手机序号',
      dataIndex: 'cloudPhoneNum',
    },
    {
      title: '状态',
      dataIndex: 'deviceStatus',
      valueType: 'select',
      valueEnum: {
        1: {
          text: ('空闲'),
          status: 'Processing',
        },
        2: {
          text: ('占用中'),
          status: 'Success',
        },
        // 3: {
        //   text: ('锁定'),
        //   status: 'Default',
        // },  
        4: {
          text: ('离线'),
          status: 'Default',
        },
        5: {
          text: ('重启中'),
          status: 'Warning',
        },
        7: {
          text: ('重置中'),
          status: 'Warning',
        }, 
      },

    },
    {
      title: '节点名称',
      dataIndex: 'nodeName',
    },
    {
      title: '国家',
      dataIndex: 'icon',
      render: (text) => <img src={text} width={30} />,
    },
  ];

  const [showAssPhone,setShowAssPhone] = useState(false);
  const [phoneList, setPhoneList] = useState([]);

  const onCloseAssPhone = () => {
    setShowAssPhone(false)
  }

  // 获取成员详情
  const getMemberInfo = async () => {
    if(!curClientid)
      return;
    try {
      await memberDetail({id:curClientid}).then((res) => {
      // console.log('memberDetail---------', res);
      var flag = checkLogin(res.data)
      if (flag) {
        apiReqBody = res.data.data;
        // console.log('apiReqBody----1111111111111-----', apiReqBody);

        form.setFieldValue("mobile", apiReqBody.mobile);
        form.setFieldValue("password", "1111aaaa");
        form.setFieldValue("sceneKey", apiReqBody.sceneName);

        var menuNames = "";
        apiReqBody.menuNames.forEach((item)=>{
          if(menuNames)
            menuNames += "; ";
          menuNames += item;
        })
        form.setFieldValue("menuIds", menuNames);
      }
      else
        message.error(res.data.msg);
    });
    } catch (error) {
      message.error(error);
    }
  };

  // 页面初始化
  useEffect(() => {
    if(showDetail){
      getMemberInfo();
    }else{
      apiReqBody = {};
      setShowAssPhone(false);
      setPhoneList([]);
    }

  }, [showDetail]);

  // 获取关联云手机列表
  const getAssPhones = (scene) => {
    if(!scene)
      return;
    try {
      memberHadPhones({id:curClientid,sceneKey:scene}).then((res) => {
        // console.log('memberHadPhones---------', res);
      var flag = checkLogin(res.data)
      if (flag) {
        setPhoneList(res.data.data);
      }
      // else
        // message.error(res.data.msg);
    });
    } catch (error) {
      // message.error(error);
    }
  };

  // 点击分配云手机
  const funAssPhone = () => {
    getAssPhones(apiReqBody.sceneKey);
    setShowAssPhone(true);

  };

  return (
    <div>
    <Modal 
        // key="changeparam"
        title="成员详情"
        open={showDetail}
        width="400px"
        centered
        // style={{top:50}}
        footer={[<Button htmlType="button"  onClick={closeDetail} className="m-btn-white">
        关闭
        </Button>] }// 设置footer为空，去掉 取消 确定默认按钮
        onCancel={closeDetail}>   
          <Form form={form}
            // labelCol={{
            //   span: 5,
            // }}
            initialValues={{
            }}
            // layout="vertical"
            // onFinish={onFinish}
          >
              <Form.Item
                label="手机号"
                name="mobile"
                style={{marginBottom:10}}
            >
            <Input placeholder="" disabled bordered={false} style={{color:"black"}} />
            </Form.Item>
            <Form.Item 
              label='使用场景' 
              style={{marginBottom:10}}
            >
            <Form.Item 
              name="sceneKey"  
              style={{marginBottom:0, width:"40%", float:"left"}}
            >
            <Input placeholder="" disabled bordered={false}  style={{color:"black"}}/>
            </Form.Item>
            <Button className='m-button-blue' style={{float:"right"}} onClick={()=>{funAssPhone()}}>查看云手机列表</Button>
            </Form.Item>
            <Form.Item
                label="权限" 
                name="menuIds"
                style={{marginBottom:10}}
            >
            <Input placeholder="" disabled bordered={false}  style={{color:"black"}}/>
            </Form.Item>

          </Form>   
    </Modal>

    <Drawer
        title="云手机列表"
        placement="right"
        width={500}
        closable={false}
        onClose={onCloseAssPhone}
        // visible={visibleTree}
        open={showAssPhone}
    >
      <Table
        // rowSelection={{
        //   type: "Checkbox",
        //   ...rowSelection,
        // }}
        columns={columns}
        dataSource={phoneList}
        rowKey="cloudPhoneNum"
      />
        
    </Drawer>
  </div>
);
}
export default App;