import React , { useState,useEffect }from 'react'
import { Button,  Form, Input,message,Layout,Row,Col,Checkbox } from 'antd';
import './styles.css';
import {userLogin, getUserinfo} from './service';
import checkLogin from '../../request/checkLogin.js'
// import { useNavigate } from 'react-router-dom';
// import CryptoJS from 'crypto-js';
import { encrypt,decrypt,localStorageSet,localStorageGet } from '../../utils/global.js';

// const navigate = useNavigate();
  // navigate(-1)//适用于返回上级页面
  // navigate('/router');//也可直接加路径

const { Header, Content, Footer, Sider } = Layout;

const Login =(props)=>{
    const [form] = Form.useForm();
    const [bChecked,setChecked] = useState(false);

    useEffect(() => {
      var localLoginfo = localStorageGet('loginfo');
      // console.log('localLoginfo:----------- ', localLoginfo);
      if(localLoginfo){
        var loginfo_ = JSON.parse(decrypt(localLoginfo));
        // console.log('loginfo_--------------', loginfo_)
        form.setFieldValue('userName', loginfo_.userName);
        form.setFieldValue('password', loginfo_.password);
        setChecked(true);
      }
      else{
        setTimeout(()=>{
          form.setFieldValue('userName', "");
          form.setFieldValue('password', "");
          setChecked(false);

        }, 100)
      }
    }, []);
    
    const onChange = (e) => {
      setChecked(e.target.checked);
    };

    const onFinish = async(value)=>{
        // console.log(value);
        // console.log(bChecked);
        localStorage.removeItem('token');

        if(bChecked){
          // console.log(encrypt(JSON.stringify({"userName":value.userName, "password":value.password})))
          // console.log(JSON.stringify({"userName":value.userName, "password":value.password}))
          localStorageSet("loginfo", encrypt(JSON.stringify({"userName":value.userName, "password":value.password})));
        }
        else{
          localStorage.removeItem("loginfo");
        }
        try {
            return await userLogin(value).then((res) => {
              // console.log('userLogin-res-----------------', res);
              var flag = checkLogin(res.data)
              if (flag) {
                // props.history.push({
                //     pathname: '/home',
                //   });
                localStorage.setItem('token',res.data.data);

                try {
                  getUserinfo({}).then((res) => {
                  // console.log('getUserinfo-res-----------------', res);
                  var flag = checkLogin(res.data)
                  if (flag) {
                    const currentUserinfo = res.data.data
                    localStorageSet("UserInfo", encrypt(JSON.stringify(res.data.data)));
                    // console.log('currentUserinfo--------' ,  currentUserinfo);
                    if(currentUserinfo.userType!=99 && currentUserinfo.menuPerm.length==0)
                      message.error("您没有任何权限");
                    else if(currentUserinfo.userType==99)
                      window.location.href='/#/bcroom';
                    else
                      window.location.href='/#'+currentUserinfo.menuPerm[0];
                  }
                  else
                    message.error(res.data.msg);
                });
                } catch (error) {
                  message.error(error);
                  return {};
                }
                
              }
            });
          } catch (error) {
            
            return false;
          }
    }
  return (
    <div  id="box" >
      <Row style={{width:"100%",height:"100%", borderRadius:20}}>
        <Col span={12} style={{background:"rgb(63,140,255)", borderRadius:"20px 0 0 20px"}}>
          <div style={{textAlign:"left", paddingLeft:50,paddingTop:40}}><img src={[require("../admin/img/logo.jpg")]} alt="" width="30%" /></div>
          <img src={[require("./img/login_left.png")]} alt="" width="70%" style={{marginTop:100}} />
        </Col>
        <Col span={12} style={{background:"white", borderRadius:"0 20px 20px 0"}}>
            <h1 id= 'title'>登录</h1>
            <Form form={form}
            className='loginForm'
            name="normal_login"
            // labelCol={{span: 5}}
            // wrapperCol={{span: 16}}
            onFinish={onFinish}
            layout="vertical"
            // onFinishFailed={this.onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="手机号"
                name="userName"
                rules={[
                  {required: true, message: '请输入手机号',},
                  {pattern: /^1\d{10}$/, message: '手机号格式错误'},

                  // {max: 12,message: '用户名最多12位',},
                  // {min: 3,message: '用户名至少3位',},
                  // {pattern:/^[a-zA-Z0-9_]+$/,message: '用户名必须是英文、字母或下划线',},

                    // {
                    // required: true,
                    // message: '请输入您的手机号！',
                    // },
                ]}
            >
            <Input placeholder="请输入您的手机号" />
            </Form.Item>
            <Form.Item
                label="密码"
                name="password"
                rules={[
                  {required: true,message: '请输入密码',},
                  // {max: 20,message: '密码最多20位',},
                  // {min: 6,message: '密码至少6位',},
                  // {pattern:^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$,message: '密码必须包含字母和数字',},
                ]}
            >
            <Input.Password  placeholder="请输入登录密码" />
            </Form.Item>
            <Form.Item>
            <Checkbox  name="remember" checked={bChecked} onChange={onChange} style={{float:"left",color: "rgba(0, 0, 0, 0.5)"}}>记住我</Checkbox>
            <a href="/#/login/forgetpwd" style={{float:"right",color: "rgba(0, 0, 0, 0.5)"}}>忘记密码</a>
            </Form.Item>
            <Form.Item>
            <div>
            <Button type="primary" htmlType="submit" style={{padding:"0 40px"}}>
                登录
            </Button>
            </div>
            <div style={{marginTop:20}}><a href="/#/register">立即注册</a></div>
            </Form.Item>
        </Form>
        </Col>
        </Row>

    </div>
  )
}
export default Login
