import React, { useEffect,useState } from 'react';
// import SparkMD5 from 'spark-md5';
import { Modal, Result, Button, Form, Input, Upload, message, Progress } from 'antd';
import axios from 'axios';
// import { uploadTmpUrl } from '../service';
import CryptoJs from 'crypto-js';
import checkLogin from '../../../request/checkLogin.js'
import {getApiData} from '../../../request/api.js'
import { LoadingOutlined,PlusOutlined } from '@ant-design/icons';

const App = (props) => {
  const [form] = Form.useForm();
  const { showModel, closeAddbcapp, edit, curAppinfo, onSubmit} = props;
  const [loading, setLoading] = useState(false);
  const [imageUrl,setImageUrl] = useState('')


  // 进度条
  const [percent, setpercent] = useState(0);
  const [resourceName,setresourceName] = useState(null)
  const [fileInfo,setFileInfo] = useState({})
  const [percentShow,setPercentShow] = useState(false)
  
  useEffect(() => {
    if (form && !showModel) {
      form.resetFields();
      setPercentShow(false);
      setImageUrl("");
    }
    else if(showModel && edit){
      setImageUrl(curAppinfo.appIcon);
      form.setFieldsValue(curAppinfo);
    }
  }, [props.showModel]);
 
  const handleSubmit =  async () => {
    const values = await form.validateFields();
    console.log('values ------------ ', values)
    onSubmit(values)
    
  };
 

     // 上传前的检验,指定上传类型，判断大小等
  const beforeUpload = (file, fileList) => {
    // console.log(file?.name)
    let str = file?.name
    // console.log(str.substring(str.lastIndexOf(".")+1))
    return new Promise((resolve, reject) => {
      // if (file?.size > 1024 * 1024 * 1024 * 5) {
      //     message.error("请上传5G以内的文件");
      //     return reject(false);
      // }else if(str.substring(str.lastIndexOf(".")+1) !='mp4'){
      //   message.error("请上传mp4的文件");
      //   return reject(false);
      // }
      var ext = str.substring(str.lastIndexOf(".")+1).toLocaleLowerCase();
      console.log('ext - ', ext)
      if(ext !='jpg' && ext !='jpeg' && ext !='png'){
        message.error("请上传jpg|jpeg|png的文件");
        return reject(false);
      }
       
      return resolve(true);
    });
  };
  // 重点-上传方法
  const uploadFile = async (res,data) => {
    console.log('res ----------',res);

    var reopt = {
      method : 'PUT',
      url : res.signatureUrl,
      withCredentials: false, 
      headers : res.requestHeaders || {},
      // headers: {
      //   'content-Type': '',
      //   // 'content-Type': 'multipart/form-data ',
      // }, 

      validateStatus: function(status){
              return status >= 200;
      },
      maxRedirects : 0,
      responseType : 'text',
      data : data.file,
      onUploadProgress: (data) => {
          let { total, loaded } = data;
          let percent = parseInt(loaded / total * 100) > 99 ? 100 : parseInt(loaded / total * 100);
          setpercent(percent)
      }

    };
    console.log('reopt --------- ', reopt)

    axios.request(reopt).then(function (response) {
        if(response.status < 300){                     
              console.log('Creating object using temporary signature succeed.');
              setImageUrl(res.fileUrl);
              form.setFields([
                {
                  name:'appIcon',value:res.fileUrl,errors:null
                }
              ])
        }else{
          message.error("上传失败: " + response.status);
          console.log('Creating object using temporary signature failed!');
          console.log('status:' + response.status);
        }
        console.log(response.data);
        console.log('\n');
    }).catch(function (err) {
      message.error("上传失败！");
      console.log('Creating object using temporary signature failed22222222222222!');
      console.log(err);
    });
  }

  const upload = async (data) => {
    const file = data.file;
    console.log(file)
      setPercentShow(true)
      const { size, type, name ,lastModified} = file 
      const strfile = name+size+type+lastModified;
      // const spark = new SparkMD5()
      // spark.append(strfile)
      // const hash = spark.end() // 获取到的文件md5值
      const hash = CryptoJs.MD5(strfile).toString();
      var durl = hash+name.substring(name.lastIndexOf("."));
      console.log(durl);

      try {
        getApiData('upload','tempreqdata', {objectKey:durl}).then((res) => {
          console.log('uploadTmpUrl---------', res);
        var flag = checkLogin(res.data)
        if (flag) {
          uploadFile(res.data.data, data);

          setFileInfo({size:size,resourceUrl:res.data.data.fileUrl,fileSign:hash});
          // console.log(!resourceName)
          // if(!resourceName) setresourceName(name);
        }
      });
      } catch (error) {
        // message.error(error);
      }



      // let lodeapp=await axios({
      //     method: 'POST',
      //     url: params.endpoint,
      //     headers: {
      //       'content-Type': 'multipart/form-data ',
      //     }, 
      //     data: fd,
      //     onUploadProgress: (data) => {
      //         let { total, loaded } = data;
      //         let percent = parseInt(loaded / total * 100) > 99 ? 100 : parseInt(loaded / total * 100);
      //         setpercent(percent)
      //     }
      // });
  }

  const uploadProps = {
    name: "file",
    accept: '.png, .jpg, .jpeg',
    listType: "picture-card",
    beforeUpload: beforeUpload,
    multiple: false, 
    customRequest: upload,  //  重新定义上传方法
    onChange: (info) => {
      console.log('------------info-------------',info)
      if (info.file.status === "done") {
        
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} 上传失败.`);
      } else if (info.file.status === "removed") {
        
      }
      
    },
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );


  const getModalContent = () => {
  
    return (
      <Form {...formLayout} form={form} >
        <Form.Item
          name="appName"
          label="应用名称"
          rules={[
            {
              required: true,
              message: '请输入应用名称',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="pkgName"
          label="包名"
          rules={[
            {
              required: true,
              message: '请输入包名',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="appIcon"
          label="上传ICON"
          rules={[
            {
              required: true,
              message: '请上传ICON',
            },
          ]}
        >
          <Upload {...uploadProps}>
            {/* <Button className='m-button-blue'>上传</Button> */}
            {imageUrl ? (
                <img
                src={imageUrl}
                alt="avatar"
                style={{
                    width: '100%',
                }}
                />
            ) : (
                uploadButton
            )}
          </Upload>
          <Progress percent={percent} style={{width:'75%',top:0,left:70,display:percentShow?'block':'none'}}/>
          {/* <div style={{color:'#ccc',fontSize:'12px',lineHeight: '20px',paddingTop:10,width:"110%"}}>提示：上传视频素材只支持mp4格式，单次上传最大支持5G。</div> */}
        </Form.Item>
       
      </Form>
    );
  };

  const formLayout = {
    labelCol: {
      span: 5,
    },
  
    wrapperCol: {
      span: 17,
    },
  };
  
  const modalFooter = {
    okText: '确定',
    cancelText: '取消',
    onOk: handleSubmit,
  };

  return (
    <div>
      <Modal
        forceRender={true}
        title={edit ? '修改应用' : '新增应用'}
        width={500}
        centered
        destroyOnClose
        open={showModel}
        {...modalFooter}
        onCancel={closeAddbcapp}

      >
        {getModalContent()}
      </Modal>
       
    </div>
  );
};

export default App;
