import React , { useRef, useState,useEffect }from 'react'
import ProTable from '@ant-design/pro-table';
import { Button ,message,Layout,Descriptions,Card,Menu,Badge,Space,Dropdown,Popconfirm,Modal,Form,Input,Row,Col,Select } from 'antd';
import {memberList, memberScene,updatePassword,updateRemarks,memberDelete,memberEnable,memberDisable} from './service.js';
import {customerOptions} from '../bcroom/service.js';
import {clientScene} from '../client/service.js';
import checkLogin from "../../request/checkLogin.js"
import { EditIcon } from '../components/icons';
import Editclient from './components/editclient.jsx';
import Memberdetail from './components/memberdetail.jsx';
import CustomProList from '../components/CustomProList';
import { buttonBlur } from '../../utils/global.js';
import { encrypt,decrypt,localStorageSet,localStorageGet } from '../../utils/global.js';

// import OperationModal from './components/OperationModal'
import Icon,{ EditOutlined,EllipsisOutlined} from '@ant-design/icons';
import './index.css';

import { ProList } from '@ant-design/pro-components';
// import request from 'umi-request';

const { Header, Content, Sider } = Layout;

const User = ()=>{
  const currentUserinfo = JSON.parse(decrypt(localStorageGet('UserInfo')));

  const [form_pwd] = Form.useForm();
  const [form_remark] = Form.useForm();
  const actionRef = useRef();

  // const [canAddmember,setCanAddmember] = useState(false)
  const [showEditPassword,setShowEditPassword] = useState(false)
  const [showEditRemarks,setShowEditRemarks] = useState(false)
  const [showEditclient,setShowEditclient] = useState(false)
  const [showDetail,setShowDetail] = useState(false)
  const [curClientid,setCurClientid] = useState("")
  const [curRemarks,setCurRemarks] = useState("")
  const [loading_checklist,setChecklistloading] = useState(false);
  const [primaryAccount,setPrimaryAccount] = useState("")
  const [showSelectClient, setShowSelectClient] = useState("none");
  const [clientList, setClientList] = useState([]);
  const [password,setpassword] = useState('');

  const columsName = {
    'mobile' : {
      title:'手机号', 
      width:'20%'
    },
    'sceneName' : {
      title:'使用场景', 
      width:'20%',
    },
    'loginFlag' : {
      title:'状态', 
      width:'20%',
      valueEnum: {
        "-1": {
          text: '禁用',
          status: 'error',
        },
        1: {
          text: '启用',
          status: 'success',
        },
      },
    },
    'createDt' : {
      title:'创建时间', 
      width:'20%'
    },
    'remarks' : {
      title:'备注', 
      width:'20%'
    },
    // 'op' : '操作',
  };

  const [sceneList,setSceneList] = useState([]);
  const [allSceneList,setAllSceneList] = useState([]);

  // 页面初始化
  useEffect(() => {
    if(currentUserinfo.userType == 99)
      setShowSelectClient("block");
    // else
    //   setCanAddmember(true);

    getScene();
    getAllScene();
      
    try {
      customerOptions({}).then((res) => {
      // console.log('customerOptions-res-----------------', res);
      var flag = checkLogin(res.data)
      // console.log('flag: ', flag);
      if (flag) {
        setClientList(res.data.data)
      }
      // else
      //   message.error(res.data.msg);
    });
    } catch (error) {
      // message.error(error);
    }

  }, []);
  
  const closeModal = () => {
    setCurClientid("");
    setShowEditclient(false);
    setShowDetail(false);
    setShowEditPassword(false);
    setShowEditRemarks(false);
    form_pwd.resetFields();
    actionRef.current.reload();
  };

  // 获取使用场景Options
  const getScene = async () => {
    if(!showEditclient)
      return;
    var params = {};
    if(primaryAccount)
      params.primaryAccount = primaryAccount;

    try {
      await memberScene(params).then((res) => {
        // console.log('memberScene---------', res);
      var flag = checkLogin(res.data)
      if (flag) {
        var list = [];
        res.data.data.forEach((item)=>{
          list.push({value:item.sceneKey, label:item.sceneName})
        })
        setSceneList(list);
        // console.log('-------------11111111111111---------', sceneList)
      }
      // else
      //   message.error(res.data.msg);
    });
    } catch (error) {
      message.error(error);
    }
  };

  // 获取使用场景Options
  const getAllScene = async () => {
    try {
      await clientScene({}).then((res) => {
        // console.log('clientScene---------', res);
      var flag = checkLogin(res.data)
      if (flag) {
        var list = {};
        res.data.data.forEach((item)=>{
          list[item.sceneKey] = {text:item.sceneName}
        })
        setAllSceneList(list);
      }
      // else
      //   message.error(res.data.msg);
    });
    } catch (error) {
      message.error(error);
    }
  };

  const getMemberList = async (params) =>{
    params.pageNo = params.current;
    delete params.current;
    var result = {};
    if(primaryAccount)
      params.primaryAccount = primaryAccount;
    try {
      await memberList(params).then((res) => {
        // console.log('getMemberList-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          // console.log('222222222222222222222222');
          result = res.data.data;
          // console.log('result-----', result);
          return result;
        }
        // else
        //   message.error(res.data.msg);
      });
    } catch (error) {
      // message(error);
    }
    return result;
  }
  

  const passwordSetting =(e)=>{
    setpassword(e.target.value)
  }
  // const validatePassword = (rule, value, callback) => {
  //   if (value === '') {
  //       callback(new Error('请输入密码'));
  //   } else {
  //   callback();
  //   }
  // };

  const validatePass = (rule, value, callback)=>{
    if (value === '') {
        // callback(new Error('请再次输入密码'));
        return new Promise((resolve, reject) => {
            reject('请再次输入密码')
          });
      } else if (value !== password) {
        // callback(new Error('两次输入密码不一致!'));
        return new Promise((resolve, reject) => {
            reject('两次输入密码不一致!')
          });
      } else {
        return Promise.resolve()
      }
  }

  const onEditPassword = (value) =>{
    // console.log('value---------',value)
    var params = {id:curClientid, password:value.password};
    try {
      updatePassword(params).then((res) => {
        // console.log('updatePassword-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          closeModal();
          message.success("修改成功");
        }
        // else
          // message.error(res.data.msg);
      });
    } catch (error) {
      // message(error);
    }
  }

  const onEditRemark = (value) =>{
    // console.log('value---------',value)
    value.id = curClientid;
    try {
      updateRemarks(value).then((res) => {
        // console.log('updateRemarks-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          closeModal();
          actionRef.current.reload();
          message.success("修改成功");
        }
        // else
          // message.error(res.data.msg);
      });
    } catch (error) {
      // message(error);
    }
  }
  
  const setClientStatus = async (id,status) => {
    if(!id)
      return;
    const fun = status==1 ? memberDisable : memberEnable;
    try {
      await fun({id:id}).then((res) => {
        // console.log('setClientStatus-res-----------------', fun, res);
        var flag = checkLogin(res.data);
        if(flag){
          actionRef.current.reload();
          message.success("操作成功");
        }
        else
          message.error(res.data.msg);
      });
    } catch (error) {
      message(error);
    }
  }
  
  const delClient = async (id) => {
    if(!id)
      return;
    try {
      await memberDelete({id:id}).then((res) => {
        // console.log('memberDelete-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          actionRef.current.reload();
          message.success("删除成功");
        }
        else
          message.error(res.data.msg);
      });
    } catch (error) {
      message(error);
    }
  }
  
  const onChangClient = (e) => {
    // console.log(e);
    // getDeviceList({userId:e});
    setPrimaryAccount(e);
    // setCanAddmember(true);
    actionRef.current.reload();
  }

  return (
    <Content
    className="site-layout-background"
    style={{
      padding: 10,
      margin: 0,
      // minHeight: "100%",
    }}
    >
    {/* <div className='titleClass'>成员管理</div> */}
    <Row align="middle">
      <Col span={16} className='titleClass'>
        成员管理
      </Col>
      <Col span={4} className='selectClient' style={{display:showSelectClient}}>
        选择客户 
      </Col>
      <Col span={4} style={{display:showSelectClient}}>
        <Select
          placeholder="请选择"
          className='ant-select-selector'
          options={clientList}
          onChange={onChangClient}
        />
    </Col>
    </Row>

    <div>

    <ProList rowKey="id" 
        toolBarRender={() => {
          return [
              <Button key="btn_add" type="primary" shape="round"
              onClick={() => {
                if(currentUserinfo.userType==99 && !primaryAccount)
                  message.warning("请先选择客户")
                else{
                  setCurClientid("")
                  setShowEditclient(true);
                }
              }}>新增成员</Button>,
          ];
        }} 
        search={{
          filterType: 'light',
        }}
        pagination={{ defaultPageSize: 10, showSizeChanger: true, }}
        loading={loading_checklist}
        actionRef={actionRef}
        request={async (params)  => {
          setChecklistloading(true);
          var tableData = [];
          if(currentUserinfo.userType!=99 || (currentUserinfo.userType==99 && primaryAccount)){
            // console.log('params----------------', params);
            const res = await getMemberList(params);
            // console.log('res--------------', res);
            if(res.rows){
              res.rows.map((v,k)=>{
                // console.log('v--------------', v);
                tableData[k] = v;
                tableData[k]['content'] = {...v};
              })
            }
          }
          // console.log('tableData--------------', tableData);
          setChecklistloading(false);
          return {
              data: tableData,
              // current: res.data.pageNo,
              // pageSize: res.data.pageSize,
              success: true,
              // total: res.data.totalRows,
          };
        }}
        metas={{
          mobile: {
              // dataIndex: 'mobile',
              title: '手机号',
          },
          sceneKey: {
            // 自己扩展的字段，主要用于筛选，不在列表中显示
            title: '使用场景',
            // dataIndex: 'sceneKey',
            valueType: 'select',
            valueEnum: allSceneList,
            // {
            //   "LIVE_BOARD": {
            //     text: ('直播舱'),
            //     status: 'Default',

            //   },
            //   "BREEDING_BOARD": {
            //     text: ('养号舱'),
            //     status: 'Success',

            //   },
            // },
          },
          loginFlag: {
            // 自己扩展的字段，主要用于筛选，不在列表中显示
            title: '状态',
            valueType: 'select',
            valueEnum: {
              "-1": {
                text: ('禁用'),
                status: 'Default',

              },
              1: {
                text: ('启用'),
                status: 'Success',

              },
            },
          },

          content: {
              dataIndex: 'content',
              search: false,
              render: (text) => (
                <CustomProList 
                  columsName = {columsName}
                  text = {text}
                />
              ),
          },
          actions: {
              render: (text, data) => [
                <div>
                <div style={{ color: '#00000073',textAlign:"left" }}>操作</div>
                <div style={{ color: '#000000D9',marginTop:6 }}>
                <Space>
                <EditIcon 
                  onClick={() => {
                    setCurClientid(data.id);
                    setShowEditPassword(true);
                  }}
                  style = {{color:'#5da3f2',margin:"0 15px 0 0"}}

                />
                <Button size="small" className='m-button-blue'
                onClick={(event) => {
                  buttonBlur(event);
                  setCurClientid(data.id);
                  setShowDetail(true);
                }}
                > 详情 </Button>
                <Button size="small"  className='m-button-green'
                onClick={(event) => {
                  buttonBlur(event);
                  setCurClientid(data.id);
                  setShowEditclient(true);
                }}
                > 修改 </Button>
                <Dropdown overlay={()=>{
                  return(
                  <Menu onClick={()=>{}} style={{borderRadius:8}} >
                    <Menu.Item 
                      // style={{display:isShow('status:update')?'block':'none'}}
                      onClick={()=>{
                        setCurClientid(data.id);
                        setCurRemarks(data.remarks);
                        form_remark.setFieldValue("remarks", data.remarks);
                        setShowEditRemarks(true);
                      }}>修改备注
                    </Menu.Item> 
                    <Menu.Item 
                      // style={{display:isShow('status:update')?'block':'none'}}
                      onClick={()=>setClientStatus(data.id, data.loginFlag)}
                    >{data.loginFlag==1?'禁用':'启用'}
                    </Menu.Item> 
                    <Popconfirm
                        title="确认删除该成员？"
                        onConfirm={()=>{
                          delClient(data.id);
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                      <Menu.Item 
                          // style={{display:isShow('del')?'block':'none'}}
                      >删除</Menu.Item> 
                    </Popconfirm>
                    
                  </Menu>
                  )}}>
                  <EllipsisOutlined  style={{color:"rgb(63,140,255)",fontSize:20}}/>
                </Dropdown>
                </Space>
                </div>
                </div>
              ],
          },
        }} 
        />


    </div>

    <Editclient
      primaryAccount = {primaryAccount}
      curClientid = {curClientid}
      showEditclient = {showEditclient}
      closEditclient = {closeModal}
    />

    <Memberdetail
      curClientid = {curClientid}
      showDetail = {showDetail}
      closeDetail = {closeModal}
    />

  <Modal 
      key="editpassword"
      title='修改密码'
      open={showEditPassword}
      width="350px"
      centered
      footer={[<Button className="m-btn-white" onClick={closeModal}>
      取消
      </Button>,
      <Button type="primary" htmlType="submit" shape='round'  style={{ marginLeft:'20px'}} onClick={()=>{form_pwd.submit()}}>
      确认
      </Button>] }// 设置footer为空，去掉 取消 确定默认按钮
      onCancel={closeModal}>   
        <Form form={form_pwd} layout="vertical" onFinish={onEditPassword}>
          <Form.Item
                label="输入新密码"
                name="password"
                rules={[
                  // { validator: validatePassword, trigger: 'blur' },
                  {required: true,message: '请输入新密码',},
                  {pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,message: '密码为必须包含字母和数字的6-20位字符',},
                ]}
            >
            <Input.Password  placeholder="" onChange={passwordSetting} />
            </Form.Item>
            <Form.Item
                label="再次输入新密码"
                name="passwordNew"
                rules={[
                    { validator: validatePass, trigger: 'blur' }
                ]}
            >
                <Input.Password />
            </Form.Item>
        </Form>   
  </Modal>

  <Modal 
      key="editremark"
      title='修改备注'
      open={showEditRemarks}
      width="350px"
      centered
      footer={[<Button className="m-btn-white" onClick={closeModal}>
      取消
      </Button>,
      <Button type="primary" htmlType="submit" shape='round'  style={{ marginLeft:'20px'}} onClick={()=>{form_remark.submit()}}>
      确认
      </Button>] }// 设置footer为空，去掉 取消 确定默认按钮
      onCancel={closeModal}>
        <Form form={form_remark} layout="vertical" defaultValue={{"remarks":curRemarks}} onFinish={onEditRemark}>
          <Form.Item
                label="输入备注"
                name="remarks"
            >
            <Input.TextArea  placeholder="" />
            </Form.Item>
        </Form>   
  </Modal>

    </Content>

  )
}


export default  User;
