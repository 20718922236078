import React, { useRef, useState,useEffect } from 'react';
import { Modal,Button,Descriptions } from 'antd';
import ProDescriptions from '@ant-design/pro-descriptions';
import { paramDetail_bc, paramDetail_stay, paramDetail_unm} from './service';
import checkLogin from '../../../request/checkLogin.js'

const App = (props) => {
  const {showParamDetail, curDeviceInfo, close, pageType} = props;
  const [loading, setloading] = useState(false)
  const [refresh, setRefresh] = useState(0)
  const actionRefParam = useRef();

  const detailsColumns =[
    {
      title: '厂商',
      key: 'manufacturer',
      dataIndex: 'manufacturer',
    },{
        title: '品牌',
        key: 'brand',
        dataIndex: 'brand',
    },{
        title: '机型',
        key: 'model',
        dataIndex: 'model',
    },{
      title: 'androidId',
      key: 'androidId',
      dataIndex: 'androidId',
    // },{
    //   title: '序列号',
    //   key: 'serialNo',
    //   dataIndex: 'serialNo',
    },{
        title: 'SIM卡国家',
        key: 'countryIso',
        dataIndex: 'countryIso',
    },{
      title: '语言',
      key: 'language',
      dataIndex: 'language',
    },{
      title: '时区',
      key: 'timeZone',
      dataIndex: 'timeZone',
    },{
      title: 'imei',
      key: 'imei',
      dataIndex: 'imei',
    // },{
    //   title: 'imsi',
    //   key: 'imsi',
    //   dataIndex: 'imsi',
    },{
      title: '运营商',
      key: 'operatorName',
      dataIndex: 'operatorName',
    },
    // {
    //   title: '运营商代码',
    //   key: 'numeric',
    //   dataIndex: 'numeric',
    // },
  ];

  useEffect(() => {
    setRefresh(0);
    // if(showParamDetail && actionRefParam){
    //   setTimeout(()=>{
    //     actionRefParam.current?.reload()
    //   },100)
    // }
  }, [showParamDetail]);

  return (
    <Modal 
        // key="changeparam"
        title='参数详情'
        open={showParamDetail}
        centered
        destroyOnClose={true}
        // style={{
        //   top: 15,
        // }}
        // width="350px"
        footer={[<Button  shape='round' htmlType="button"  onClick={close} className="m-btn-white">
        关闭
        </Button>,
        <Button type="primary" htmlType="submit" shape='round'  style={{ marginLeft:'20px'}} onClick={()=>{
          setRefresh(1)
          setTimeout(()=>{
            actionRefParam.current?.reload()
          },100)
        }}>
        刷新
        </Button>] }// 设置footer为空，去掉 取消 确定默认按钮
        onCancel={close}>
    
      <ProDescriptions
        actionRef={actionRefParam}
        column={1}
        bordered={true}
        // loading={loading}
        request={async () => {
          // setloading(true);
          var fun = pageType=='stay'?paramDetail_stay:(pageType=='bc'?paramDetail_bc:paramDetail_unm);
          const res = await fun({cloudPhoneNum:curDeviceInfo.cloudPhoneNum,refresh:refresh});
          var flag = checkLogin(res.data)
          if (flag) {
            return {
              data: res.data.data,
              success: true,
            };
          }
          // setloading(false);
          // return Promise.resolve({
          //   success: true,
          //   data: {
          //     "exists": true,
          //     "deviceId": "0A5820218C93A96A4E339125B309A983",
          //     "manufacturer": "samsung",
          //     "brand": "samsung",
          //     "model": "SM-G5510",
          //     "serialNo": "b8a0e0bf",
          //     "android": "1e69b6a228c9292f",
          //     "imei": "243553253117928",
          //     "imsi": "413026558737345",
          //     "operatorName": "DIALOG",
          //     "numeric": "41302",
          //     "countryIso": "LK",
          //     "language": "en-NR(英语，瑙鲁)",
          //     "timeZone": "Pacific/Nauru(瑙鲁/亚伦)"
          //   },
          // });
        }}
        
        columns={detailsColumns}
      />

    </Modal>
  )
};
export default App;