import Icon, { FolderOpenFilled } from '@ant-design/icons';
import Home from '../page/home/index.jsx';
import Bcroom from '../page/bcroom/index.jsx';
import Stayroom from '../page/stayroom/index.jsx';
import Unmroom from '../page/unmroom/index.jsx';
import Oplist from '../page/oplist/index.jsx';
import Client from '../page/client/index.jsx';
import Member from '../page/member/index.jsx';
import Account from '../page/client/account.jsx';
import Maccount from '../page/maccount/index.jsx';
// import ClientSvg from '../page/client/img/1671720466_7.svg';

const HomeSvg = () => (
<svg t="1671631994349" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1340" width="1.2em" height="1.2em"><path d="M384 106.666667H170.666667c-34.133333 0-64 29.866667-64 64v213.333333c0 34.133333 29.866667 64 64 64h213.333333c34.133333 0 64-29.866667 64-64V170.666667c0-34.133333-29.866667-64-64-64zM384 576H170.666667c-34.133333 0-64 29.866667-64 64v213.333333c0 34.133333 29.866667 64 64 64h213.333333c34.133333 0 64-29.866667 64-64v-213.333333c0-34.133333-29.866667-64-64-64zM853.333333 106.666667h-213.333333c-34.133333 0-64 29.866667-64 64v213.333333c0 34.133333 29.866667 64 64 64h213.333333c34.133333 0 64-29.866667 64-64V170.666667c0-34.133333-29.866667-64-64-64zM853.333333 576h-213.333333c-34.133333 0-64 29.866667-64 64v213.333333c0 34.133333 29.866667 64 64 64h213.333333c34.133333 0 64-29.866667 64-64v-213.333333c0-34.133333-29.866667-64-64-64z" fill="currentColor" p-id="1341"></path></svg>
    );
const HomeIcon = props => (
    <Icon component={HomeSvg} {...props} />
    );

const BcroomSvg = () => (
<svg t="1671707935385" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="89004" width="1.2em" height="1.2em"><path d="M869.445818 162.909091h-210.781091l58.577455-107.682909c8.564364-15.709091 4.002909-37.655273-10.775273-48.942546-15.732364-8.96-34.653091-0.558545-45.032727 16.779637L584.634182 162.909091h-165.469091L342.085818 22.016c-10.356364-17.082182-29.137455-24.552727-44.753454-15.592727-15.057455 11.566545-19.618909 33.675636-10.775273 49.780363L344.994909 162.909091H149.410909C67.095273 162.909091 0 229.329455 0 315.159273v549.911272C0 950.900364 67.095273 1024 149.410909 1024h720.034909C954.670545 1024 1024 953.809455 1024 865.070545V315.019636C1024 229.189818 951.761455 162.909091 869.445818 162.909091z m-140.753454 474.554182c-5.655273 3.025455-22.365091 12.939636-49.989819 29.346909l-1.792 1.093818c-33.419636 19.851636-79.290182 47.127273-134.260363 79.104-30.673455 16.663273-58.693818 33.466182-79.685818 46.010182-14.498909 8.541091-29.416727 17.501091-32.465455 18.594909l-2.769454 0.954182-2.071273 2.210909c-5.515636 5.655273-12.055273 8.541091-22.272 8.541091-31.883636 0-54.295273-25.064727-54.295273-58.274909V418.234182c0-33.908364 23.505455-61.463273 54.295273-61.463273 9.797818 0 19.060364 2.746182 29.160727 7.982546 2.769455 1.396364 15.755636 9.239273 34.257455 19.991272l0.488727 0.418909c19.456 11.287273 44.939636 26.181818 72.145454 41.890909 96.139636 54.830545 172.753455 99.351273 188.346182 109.102546l0.256 0.139636c15.872 9.518545 26.228364 29.346909 26.507637 50.711273-0.023273 21.364364-10.24 41.192727-25.856 50.455273z" p-id="89005" fill="currentColor"></path></svg>
    );
const BcroomIcon = props => (
    <Icon component={BcroomSvg} {...props} />
    );

const StayroomSvg = () => (
<svg t="1671633120361" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="28774" width="1.2em" height="1.2em"><path d="M746.666667 85.333333c64.8 0 117.333333 52.533333 117.333333 117.333334v618.666666c0 64.8-52.533333 117.333333-117.333333 117.333334H277.333333c-64.8 0-117.333333-52.533333-117.333333-117.333334V202.666667c0-64.8 52.533333-117.333333 117.333333-117.333334h469.333334zM597.333333 778.666667H426.666667a32 32 0 0 0 0 64h170.666666a32 32 0 0 0 0-64z m149.333334-629.333334H277.333333a53.333333 53.333333 0 0 0-53.333333 53.333334v480h576V202.666667a53.333333 53.333333 0 0 0-53.333333-53.333334z" fill="currentColor" p-id="28775"></path></svg>
    );
const StayroomIcon = props => (
    <Icon component={StayroomSvg} {...props} />
    );

const MemberSvg = () => (
<svg width="1.2em" height="1.2em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M14.027 8.03308C14.7517 10.4277 13.8183 13.0131 11.7294 14.3971C13.4634 15.0352 14.9621 16.1837 16.027 17.6906C16.19 17.9215 16.2104 18.2237 16.0801 18.4744C15.9497 18.725 15.6902 18.8823 15.4071 18.8823L1.75828 18.8829C1.47518 18.8829 1.21565 18.7256 1.08524 18.4749C0.954832 18.2243 0.975266 17.9221 1.13823 17.6912C2.20309 16.184 3.702 15.0353 5.43628 14.3971C3.34738 13.0131 2.41395 10.4277 3.13865 8.03308C3.86334 5.63846 6.07507 4 8.58282 4C11.0906 4 13.3023 5.63846 14.027 8.03308ZM21.4726 7.30772C22.6404 9.8439 21.7852 12.8506 19.456 14.3971C21.1901 15.0352 22.6888 16.1837 23.7536 17.6906C23.9166 17.9215 23.9371 18.2237 23.8067 18.4743C23.6763 18.725 23.4169 18.8823 23.1338 18.8823L18.8951 18.8829C18.6006 18.8829 18.3326 18.7128 18.208 18.4465C17.6254 17.2007 16.8052 16.0799 15.7931 15.1467C15.778 15.1327 15.7643 15.118 15.7506 15.1032C15.4859 14.8622 15.2093 14.6345 14.9219 14.4211C14.6069 14.1874 14.5228 13.7527 14.728 13.4189C16.2618 10.921 16.1176 7.74356 14.364 5.39396C14.2149 5.19423 14.174 4.93413 14.2545 4.69844C14.335 4.46275 14.5266 4.28168 14.7669 4.21426C17.4607 3.45957 20.3047 4.77155 21.4726 7.30772Z" fill="currentColor"/>
</svg>
    );
const MemberIcon = props => (
    <Icon component={MemberSvg} {...props} />
    );

const UnmroomSvg = () => (
<svg t="1671724576539" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="325351" width="1.2em" height="1.2em"><path d="M607.808091 707.944851a383.87265 383.87265 0 1 0-411.511481 0A344.973555 344.973555 0 0 0 0.009728 991.242867 31.989388 31.989388 0 0 0 30.975455 1024h743.945196a31.989388 31.989388 0 0 0 31.221643-32.757133 344.461725 344.461725 0 0 0-198.334203-283.298016z m-29.686152-147.663012a249.005392 249.005392 0 0 1-175.045928 83.428322h-8.701114a255.9151 255.9151 0 0 1-169.415796-84.963813 32.629175 32.629175 0 1 1 50.15936-41.714161 193.471816 193.471816 0 0 0 124.118823 62.443284c45.808803 2.303236 91.617606-19.961378 136.146834-64.23469a31.86143 31.86143 0 0 1 45.041057 45.041058z" fill="currentColor"  p-id="325352"></path></svg>
    );
const UnmroomIcon = props => (
    <Icon component={UnmroomSvg} {...props} />
    );

const ClientSvg = () => (
<svg t="1671725195731" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="350313" width="1.2em" height="1.2em"><path d="M736 853.333333a32 32 0 0 1 0 64h-448a32 32 0 0 1 0-64h448z m106.666667-746.666666A96 96 0 0 1 938.666667 202.666667v469.333333a96 96 0 0 1-96 96h-661.333334A96 96 0 0 1 85.333333 672v-469.333333A96 96 0 0 1 181.333333 106.666667z m-84.053334 243.968a32.085333 32.085333 0 0 0-45.269333 0.064l-127.168 126.890666-126.442667-126.826666-2.432-2.197334a32.106667 32.106667 0 0 0-42.944 2.133334l-149.013333 148.693333-2.197333 2.432a31.786667 31.786667 0 0 0 2.24 42.666667l2.453333 2.176c12.586667 10.197333 31.104 9.429333 42.816-2.24l126.272-126.037334 126.464 126.848 2.410667 2.197334c12.586667 10.304 31.210667 9.6 42.965333-2.133334l149.888-149.589333 2.197333-2.432a31.786667 31.786667 0 0 0-2.24-42.645333z" fill="currentColor" p-id="350314"></path></svg>
    );
const ClientIcon = props => (
    <Icon component={ClientSvg} {...props} />
    );

/**
 *   isPublic:页面是否为公开页面；
 *   hideInMenu:是否在左侧菜单栏隐藏；
 */
const menuList=[
{
    title:'首页',
    path:'/home',
    component:<Home/> ,
    icon:<HomeIcon />,
    isPublic:true ,   //公开页面
},
{
    title:'直播舱',
    path:'/bcroom',
    component:<Bcroom/> ,
    icon:<BcroomIcon />,
    show: true,
},
{
    title:'养号舱',
    path:'/stayroom',
    component:<Stayroom/> ,
    icon:<StayroomIcon />,
    show: true,
},
{
    title:'无人直播舱',
    path:'/unmroom',
    component:<Unmroom/> ,
    icon:<UnmroomIcon />,
    show: true,
},
{
    title:'成员管理',
    path:'/member',
    component:<Member/> ,
    icon:<MemberIcon />,
    show: true,
},
{
    title:'操作记录',
    path:'/oplist',
    component:<Oplist/> ,
    icon:<FolderOpenFilled style={{fontSize:"1.2em"}} />,
    show: true,
},
{
    title:'运营管理',
    path:'/client',
    component:<Client/> ,
    icon:<ClientIcon />,
    show: true,
    // children: [
    //     {
    //         title:'客户管理',
    //         path:'/client',
    //         component:<Client/> ,
            
    //     }
    // ]
},
{
    title:'账号管理',
    path:'/client/account',
    component:<Account/> ,
    icon:<FolderOpenFilled />,
    show: false,
},
{
    title:'登录账号管理',
    path:'/maccount',
    component:<Maccount/> ,
    icon:<FolderOpenFilled />,
    show: false,
},
]

export default menuList;