import React from 'react';
import ReactDOM from 'react-dom/client';
import 'antd/dist/antd.min.css';
import './index.css';


import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';//引入样式
// import SiderDemo from './Home/myIndex';//引入自己写的组件(class)
// import registerServiceWorker from './registerServiceWorker';
 
// ReactDOM.render(<SiderDemo />, document.getElementById('root'));
// registerServiceWorker();
// //registerServiceWorker就是为react项目注册了一个service worker，
// // 用来做资源的缓存，这样你下次访问时，就可以更快的获取资


// import React from 'react';
// import ReactDOM from 'react-dom';
// import SiderDemo from './Navi/Navi'
// // import registerServiceWorker from './registerServiceWorker';
// import reportWebVitals from './reportWebVitals';

// import './index.css';

// ReactDOM.render(<SiderDemo />, document.getElementById('root'));
// // registerServiceWorker();
// reportWebVitals();
