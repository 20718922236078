import { Col, Row ,Button ,Popconfirm,Modal,Divider,message,Image,Space,Dropdown,Menu,Table,Select } from 'antd';
import React , { useRef, useState,useEffect }from 'react'
import checkLogin from "../../../request/checkLogin.js"
import {getApiData} from "../../../request/api.js"
import { ProList,ProTable } from '@ant-design/pro-components';
import './index.css';
import { EditIcon } from '../../components/icons';
import CustomProList from '../../components/CustomProList';
import { buttonBlur } from '../../../utils/global.js';
import { getReviewedList,getReviewDetail,reviewPass,reviewNopass } from '../service';
import Addbcapp from './addbcapp.jsx';
import Icon,{ EditOutlined,EllipsisOutlined} from '@ant-design/icons';

const App = props => {
  const { actionRef} = props;
  const actionRef_phonelist = useRef();

  const [showAddbcapp, setShowAddbcapp] = useState(false);
  const [edit, setEdit] = useState(false);
  const [curAppinfo, setCurAppinfo] = useState(true);

  const [showAssPhone, setShowAssPhone] = useState(false);
  const [isAssPhone, setisAssPhone] = useState(false);
  const [phoneList, setphoneList] = useState([]);
  const [selectedRowKeys,setSelectedPhoneKeys] = useState([]);
  const [clientList, setClientList] = useState({});
  const [selectedClient, setselectedClient] = useState("");

  const closeAddbcapp = () => {
    setShowAddbcapp(false);
  };

  const closeAssPhone = () => {
    setShowAssPhone(false);
  };

  const columsName = {
    'appName' : {
      title:'应用名称', 
      width:'20%'
    },
    'pkgName' : {
      title:'包名', 
      width:'25%'
    },
    'appIcon' : {
      title:'ICON', 
      width:'15%',
      render: (text) => <img src={text} height={30} />,
    },
    'createDt' : {
      title:'创建时间', 
      width:'20%'
    },
    'createBy' : {
      title:'创建人', 
      width:'20%'
    },
    // 'op' : '操作',
  };

  const device_columns = [
    {
      title:'选择客户', 
      width:'20%',
      valueType:'select',
      dataIndex: 'primaryAccount',
      valueEnum: clientList,
      hideInTable:true,
    },
    {
      title: '云手机编号',
      dataIndex: 'cloudPhoneNum',
      hideInSearch: true,
    },
    {
      title: '设备号',
      dataIndex: 'deviceNum',
    },
    {
      title: '国家/地区',
      dataIndex: 'nodeName',
      hideInSearch: true,
    },
    {
      title: '设备商',
      dataIndex: 'iaas',
      hideInSearch: true,
    },
  ];

  useEffect(() => {
    // 获取客户列表
    try {
      getApiData('currency','customerOptions',{}).then((res) => {
        var flag = checkLogin(res.data);
        if(flag){
          var allclient = {};
          for(var i=0; i<res.data.data.length; i++)
            allclient[res.data.data[i].value] = {'text':res.data.data[i].label}
          setClientList(allclient);
        }
        else
          message.error(res.data.msg);
      });
    } catch (error) {
      message(error);
    }
  }, []);

  useEffect(() => {
    setselectedClient(null)
    // setphoneList([])
    actionRef_phonelist.current.reset();
  }, [showAssPhone]);
 
  // 获取App列表
  const getAppList = async (params) =>{
    params.pageNo = params.current;
    delete params.current;
    var result = {};
    try {
      await getApiData('broadcastApp','list',params).then((res) => {
        // console.log('getReviewedList-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          // console.log('222222222222222222222222');
          result = res.data.data;
          // console.log('result-----', result);
          return result;
        }
        else
          message.error(res.data.msg);
      });
    } catch (error) {
      message(error);
    }
    return result;
  }
 
  // 新增/修改应用
  const addAppFun = async (values) =>{
    var fun = 'create';
    if(edit){
      fun = 'edit';
      values.appId = curAppinfo.appId;
    }
    try {
      await getApiData('broadcastApp', fun, values).then((res) => {
        // console.log('reviewPass-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          message.success(edit?"操作成功":"新增成功")
          closeAddbcapp();
          actionRef.current.reload();
        }
      });
    } catch (error) {
    //   message(error);
    }
  }

  // 删除用户
  const delAppFun = async (appId) =>{
    if(!appId)
        return;
    try {
      await getApiData('broadcastApp','delete',{appId:appId}).then((res) => {
        // console.log('reviewNopass-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          actionRef.current.reload();
          message.success("删除成功")
        }
      });
    } catch (error) {
    //   message(error);
    }
  }
  
  // 选择客户
  const onChangClient = (e) => {
    setselectedClient(e);
    setSelectedPhoneKeys([]);
    // 获取云手机列表
    var fun = 'associated';
    if(isAssPhone){
      fun = 'unassociated';
    }
    try {
      getApiData('broadcastApp', fun, {primaryAccount:e,appId:curAppinfo.appId}).then((res) => {
        var flag = checkLogin(res.data);
        if(flag){
          setphoneList(res.data.data.rows)
        }
      });
    } catch (error) {
    //   message(error);
    }
  }

  // 获取云手机列表
  const getPhoneList = async (params) =>{
    params.pageNo = params.current;
    delete params.current;
    params.appId = curAppinfo.appId;
    var result = {};
    try {
      await getApiData('broadcastApp', isAssPhone?'unassociated':'associated', params).then((res) => {
        // console.log('getReviewedList-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          // console.log('222222222222222222222222');
          result = res.data.data;
          // console.log('result-----', result);
          return result;
        }
        else
          message.error(res.data.msg);
      });
    } catch (error) {
      message(error);
    }
    return result;
  }

  // 关联/取消关联云手机
  const assPhoneFun = () => {
    var fun = 'cancelassociate';
    if(isAssPhone){
      fun = 'associate';
    }
    try {
      getApiData('broadcastApp', fun, {appId:curAppinfo.appId,cloudPhoneNums:selectedRowKeys}).then((res) => {
        var flag = checkLogin(res.data);
        if(flag){
          setShowAssPhone(false);
          message.success("操作成功")
        }
      });
    } catch (error) {
    //   message(error);
    }
  }

  // 选择云手机
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedPhoneKeys(selectedRowKeys);
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },

    getCheckboxProps: (record) => ({
      // disabled: record.name === 'Disabled User',
      // // Column configuration not to be checked
      // name: record.name,
    }),
  };

  return (

        <div>

        <ProList rowKey="id" 
        toolBarRender={() => {
          return [
            <Button type="primary" shape="round" onClick={()=>{setEdit(false);setShowAddbcapp(true)}}>新增应用</Button>,
        ];
        }} 
        search={{
          filterType: 'light',
        }}
        pagination={{ defaultPageSize: 10, showSizeChanger: true, }}
        // loading={loading_checklist}
        actionRef={actionRef}
        // dataSource={dataSource} 
        request={async (params = {})  => {
          // setChecklistloading(true);
          // console.log('params----------------', params);
          const res = await getAppList(params);
          // console.log('res--------------', res);
          var tableData = [];
          if(res.rows){
            res.rows.map((v,k)=>{
              // console.log('v--------------', v);
              tableData[k] = v;
              tableData[k]['content'] = {...v};
            })
          }
        // console.log('tableData--------------', tableData);
        // setChecklistloading(false);
        return {
            data: tableData,
            // current: res.data.pageNo,
            // pageSize: res.data.pageSize,
            success: true,
            // total: res.data.totalRows,
        };

        }}
        metas={{
          appName: {
              // dataIndex: 'name',
              title: '应用名称',
          },
          pkgName: {
              // dataIndex: 'name',
              title: '包名',
          },

          content: {
              dataIndex: 'content',
              search: false,
              render: (text) => (
                <CustomProList 
                  columsName = {columsName}
                  text = {text}
                />
              ),
          },
          actions: {
              render: (text, data) => [
                <div>
                <div style={{ color: '#00000073',textAlign:"center" }}>操作</div>
                <div style={{ color: '#000000D9',marginTop:6 }}>
                <Space>
                <EditIcon 
                  onClick={() => {
                    setEdit(true);
                    setCurAppinfo(data);
                    setShowAddbcapp(true);
                  }}
                  style = {{color:'#5da3f2',margin:"0 15px 0 0"}}

                />
                <Button size="small" className='m-button-blue'
                onClick={(event) => {
                  buttonBlur(event);
                  setisAssPhone(true);
                  setCurAppinfo(data);
                  setShowAssPhone(true);
                }}
                > 关联云手机 </Button>
                <Button size="small"  className='m-button-green'
                onClick={(event) => {
                  buttonBlur(event);
                  setisAssPhone(false);
                  setCurAppinfo(data);
                  setShowAssPhone(true);
                }}
                > 云手机列表 </Button>
                <Dropdown overlay={()=>{
                  return(
                  <Menu onClick={()=>{}} style={{borderRadius:8}} >
                    <Popconfirm
                        title="确认删除该应用？"
                        onConfirm={()=>{
                          delAppFun(data.appId);
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                      <Menu.Item 
                      >删除</Menu.Item> 
                    </Popconfirm>
                    
                  </Menu>
                  )}}>
                  <EllipsisOutlined  style={{color:"rgb(63,140,255)",fontSize:20}}/>
                </Dropdown>
                </Space>

                </div>
                </div>
              ],
          },
        }} 
        />

    <Addbcapp
      showModel = {showAddbcapp}
      closeAddbcapp = {closeAddbcapp}
      edit = {edit}
      curAppinfo = {curAppinfo}
      onSubmit = {addAppFun}
    />

    <Modal
        title={isAssPhone?"选择关联云手机":"已关联云手机"}
        width={600}
        forceRender={true}
        open={showAssPhone}
        centered
        destroyOnClose
        onCancel={closeAssPhone}
        onOk={()=>{setTimeout(assPhoneFun, 100) }}
        okText={isAssPhone?"关联":"取消关联"}
    >
      <ProTable
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        actionRef={actionRef_phonelist}
        pagination={{ defaultPageSize: 10, showSizeChanger: true, }}
        rowKey="cloudPhoneNum"
        search={{
          filterType: 'light',
        }}
        size="small"
        // params={{ enterpriseId: enterpriseId,appId:liveInfo?.id}}
        request={async (params = {}) => {
          var tableData = [];
          if(params.primaryAccount){
            const res = await getPhoneList(params);
            console.log(res)
            if(res.rows){
              tableData = res.rows;
            }
          }
          return {
            data: tableData,
            // current: res.data.pageNo,
            // pageSize: res.data.pageSize,
            success: true,
            // total: res.data.totalRows,
          };
        }}
        columns={device_columns}
        options={{ density: false, fullScreen: false }}
      />
    </Modal>

  </div>
);
}
export default App;