import React from 'react'

export default function index(props) {
    const click = (e) => {
        // let history = useHistory();
        //   console.log(e);
        //   console.log(e.key);
          props.history.push({
            pathname: '/materialCloud/operateHistory',
           
          });
      }
  return (
    <div onClick={click}>欢迎登录！</div>
  )
}
