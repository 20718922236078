import React from 'react';
import { Result  } from 'antd';
import './styles.css';

const Register =(props)=>{
    
  return (
    <div id='box_register' style={{background:"white", borderRadius:20}}>
    <Result
    status="success"
    title="入驻申请提交成功"
    subTitle="您的入驻申请预计在72小时内完成审核，请耐心等待"
    extra={[
    ]}
  />
  </div>
  )
}
export default Register
