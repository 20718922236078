import http from "../../request/http";
import CryptoJs from 'crypto-js';

export const getCountryList = (data) =>{
    return http ({
        method:'POST',
        url:'currency/sim/country/options',
        // data
    })
}

export const getZoneList = (data) =>{
    return http ({
        method:'POST',
        url:'currency/time/zone/options',
        // data
    })
}

export const BcroomAppUrl = (data) =>{
    return http ({
        method:'POST',
        url:'live/broadcast/app/url',
        data
    })
}
// 云手机列表
export const BcPhoneList = (data) =>{
    return http ({
        method:'POST',
        url:'yang/broadcast/page',
        data
    })
}

export const getCustomerList = (data) =>{
    return http ({
        method:'POST',
        url:'live/broadcast/page',
        data
    })
}
// 获取云手机节点国家地区Options
export const NodeCityOptions = (data) =>{
    return http ({
        method:'POST',
        url:'currency/node/city/options',
        data
    })
}

// 查询客户Options
export const customerOptions = (data) =>{
    return http ({
        method:'POST',
        url:'currency/customer/options',
        data
    })
}

// 云手机基本信息修改
export const baseinfoUpdate = (data) =>{
    return http ({
        method:'POST',
        url:'yang/broadcast/cloudphone/baseinfo/update',
        data
    })
}

// 直连
export const directConnect_stay = (data) =>{
    return http ({
        method:'POST',
        url:'yang/broadcast/direct/connect/'+data.cloudPhoneNum,
        data
    })
}

// 投屏
export const projectScreen = (data) =>{
    return http ({
        method:'POST',
        url:'yang/broadcast/projection/screen/'+data.cloudPhoneNum,
        data
    })
}

// 断开直连
export const directDisconnect_stay = (data) =>{
    return http ({
        method:'POST',
        url:'yang/broadcast/direct/disconnect/'+data.cloudPhoneNum,
        data
    })
}

// 获取云手机参数信息
export const getDeviceInfo = (data) =>{
    return http ({
        method:'POST',
        url:'yang/broadcast/cloudphone/info/search/'+data.cloudPhoneNum,
        data
    })
}

// 获取云手机参数信息
export const phoneSyncMaterial = (data) =>{
    return http ({
        method:'POST',
        url:'yang/broadcast/cloudphone/sync/material',
        data
    })
}

// 获取文件上传临时数据
export const uploadTmpUrl = (data) =>{
    return http ({
        method:'POST',
        url:'upload/temp/request/data',
        data
    })
}

// 创建素材
export const materialCreate = (data) =>{
    return http ({
        method:'POST',
        url:'yang/material/create',
        data
    })
}

// 素材列表
export const materialList = (data) =>{
    return http ({
        method:'POST',
        url:'yang/material/page',
        data
    })
}

// 删除云素材
export const materialDelete = (data) =>{
    return http ({
        method:'POST',
        url:'yang/material/delete',
        data
    })
}

// 查询可同步云手机列表
export const syncPhonesList = (data) =>{
    return http ({
        method:'POST',
        url:'yang/material/valid/sync/cloud/phones',
        data
    })
}

// 同步云手机
export const syncCloudPhones = (data) =>{
    return http ({
        method:'POST',
        url:'yang/material/sync/cloud/phone',
        data
    })
}

// 查询已同步云手机列表
export const syncedCloudPhonesList = (data) =>{
    return http ({
        method:'POST',
        url:'yang/material/synced/cloud/phones',
        data
    })
}

// 重新同步
export const againSyncPhone = (data) =>{
    return http ({
        method:'POST',
        url:'yang/material/again/sync/cloud/phone',
        data
    })
}

// APP列表
export const appList = (data) =>{
    return http ({
        method:'POST',
        url:'yang/app/page',
        data
    })
}

// 创建APP
export const appCreate = (data) =>{
    return http ({
        method:'POST',
        url:'yang/app/create',
        data
    })
}

// 查询APP可安装数量
export const appInstallCount = (data) =>{
    return http ({
        method:'POST',
        url:'yang/app/valid/install/count',
        data
    })
}
// APP安装
export const appInstall = (data) =>{
    return http ({
        method:'POST',
        url:'yang/app/install',
        data
    })
}
// APP查询应用已安装的云手机列表
export const appInstalledPhones = (data) =>{
    return http ({
        method:'POST',
        url:'yang/app/installed/cloud/phones',
        data
    })
}
// APP重新安装
export const appAgainInstall = (data) =>{
    return http ({
        method:'POST',
        url:'yang/app/again/install',
        data
    })
}
// APP卸载应用
export const appUninstall = (data) =>{
    return http ({
        method:'POST',
        url:'yang/app/uninstall',
        data
    })
}
// APP应用覆盖更新
export const appCoverUpdate = (data) =>{
    return http ({
        method:'POST',
        url:'yang/app/cover/update',
        data
    })
}
// APP应用覆盖更新
export const appUpdateApk = (data) =>{
    return http ({
        method:'POST',
        url:'yang/app/update/apk',
        data
    })
}
// 验证token
export const materialValidate = (data) =>{
    return http ({
        method:'POST',
        url:'yang/broadcast/upload/material/validate',
        data
    })
}
// 上传素材上报
export const materialReport = (data) =>{
    return http ({
        method:'POST',
        url:'yang/broadcast/upload/material/report',
        data
    })
}
