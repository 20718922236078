import React, { useState, useEffect } from 'react';
import { Button,message } from 'antd';
import { smsRegSend,smsResetPwdSend } from './service';
// import checkLogin from '../../request/checkLogin.js'

let timeChange;

const Btn = (props) => {
  const {mobile,status,type} = props;
  // console.log('-----------mobile-----------', mobile)
  // console.log('-----------status-----------', status)
  const waitTime = 60;

  const [time, setTime] = useState(waitTime);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnContent, setBtnContent] = useState('获取验证码');

  useEffect(() => {
    clearInterval(timeChange);
  }, []);

  useEffect(() => {
    if (time > 0 && time < waitTime) {
      setBtnContent(`${time}s后重新获取`);
    } else {
      clearInterval(timeChange);
      setBtnDisabled(false);
      setTime(waitTime);
      setBtnContent('获取验证码');
    }
  }, [time]);

  const getPhoneCaptcha = () => {
    var fun = ""
    if(type == 'reg')
      fun = smsRegSend;
    else if(type == 'resetpwd')
      fun = smsResetPwdSend;
    if(!mobile || !fun)
      return;
    
    // 发送验证码
    try {
      fun({mobile:mobile}).then((res) => {
      console.log(fun+'-res-----------------', res);
      // var flag = checkLogin(res.data)
      // console.log('flag: ', flag);
      if (res.data.code === 0) {
        message.success("验证码发送成功");

        // 按钮倒计时( 注意，不要使用 setTime(t-1) ： 闭包问题会导致time一直为59)
        timeChange = setInterval(() => setTime(t => --t), 1000);
        setBtnDisabled(true);
      }
      else
        message.error(res.data.msg);
    });
    } catch (error) {
      message.error(error);
    }
  };
  return (
        <Button disabled={btnDisabled||!status} onClick={getPhoneCaptcha}  type='link' style={{width:"30%",float:"right",boxShadow:"none",background:"rgb(244,249,253)"}}> 
          {btnContent}
        </Button>
  );
};

export default Btn;

// import React, { useState, useEffect, useRef } from 'react';
// import {Button} from 'antd';
 
// let timeChange: any;
// function Timebutton(props: any) {
//   const { name, num, onClick, toStart } = props;
//   const [time, setTime] = useState(num);
//   const [btnDisabled, setBtnDisabled] = useState(false);
//   const [btnContent, setBtnContent] = useState(name);
 
 
//   useEffect(() => {
//     if (toStart) {
//       getClick();
//     }
//   }, [toStart]);
 
//   useEffect(() => {
//     clearInterval(timeChange);
//     return () => clearInterval(timeChange);
//   }, []);
 
//   useEffect(() => {
//     if (time > 0 && time < num) {
//       setBtnContent(`${time}${name}`);
//     } else {
//       clearInterval(timeChange);
//       setBtnDisabled(false);
//       setTime(num);
//       setBtnContent(name);
//     }
//   }, [time]);
 
//   const getClick = () => {
//     setBtnDisabled(true);
//     timeChange = setInterval(() => setTime((t: number) => --t), 1000);
//   };
//   return (
//     <Button type="primary" size="small" ghost disabled={btnDisabled} onClick={() => { onClick() }}>
//       {btnContent}
//     </Button>
//   );
// };

// export default Timebutton;
// // // 控制按钮执行倒计时事件
// // const [toStart, settoStart] = useState(false);
 
// // //组件使用
// // <Timebutton name={'命中详情'} num={4} onClick={() => {
// //           console.log('3333');
// //         }} toStart={toStart} />
 
 