import React,{useState, useEffect} from 'react';
import { Button,  Form, Input,message,Layout,Row,Col,Checkbox, Space,Radio,Steps  } from 'antd';
import './styles.css';
import {userRegister} from './service';
import checkLogin from '../../request/checkLogin.js'
import Timebutton from "./timebutton.jsx"

const { Header, Content, Footer, Sider } = Layout;

const Register =(props)=>{
    // console.log('props', props)
    const [form] = Form.useForm();
    const mobile = Form.useWatch('mobile', form);
    const password = Form.useWatch('password', form);
    const [mobileStatus,setMobileStatus] = useState(false);

    const validateMobile = (rule, value, callback) => {
      // console.log(rule)
      // console.log(value)
      // console.log(callback)
      setMobileStatus(false)
      if (value === '' || value==undefined) {
          callback(new Error('请输入手机号'));
      } else {
         const reg = /^1[3-9]\d{9}$/; //正则校验
        if(reg.test(value)){
          setMobileStatus(true)
          callback();
        } else{
          callback('手机号格式错误');
        } 
     }
    };

    const validatePass = (rule, value, callback)=>{
      if (value === '' || value==undefined) {
          // callback(new Error('请再次输入密码'));
          return new Promise((resolve, reject) => {
              reject('请再次输入密码')
            });
        } else if (value !== password) {
          // callback(new Error('两次输入密码不一致!'));
          return new Promise((resolve, reject) => {
              reject('两次输入密码不一致!')
            });
        } else {
          return Promise.resolve()
        }
    }

    const onFinish = async(value)=>{
        console.log(value)
        localStorage.removeItem('token');
        if(value.password != value.password2)
          message.error("2次密码输入不一致。请重新输入密码")
        else{
          try {
              delete value.password2;
              return await userRegister(value).then((res) => {
                console.log('userRegister-res-----------------', res);
                var flag = checkLogin(res.data)
                console.log('flag: ', flag);
                if (flag) {
                  console.log(props)
                  // props.history.push({
                  //     pathname: '/home',
                  //   });
                  
                  localStorage.setItem('token',res.data.data);
                  window.location.href='/#/register/info';
                  // if(value.userType == 8)
                  //   window.location.href='/#/register/person';
                  // else if(value.userType == 9)
                  //   window.location.href='/#/register/corp';
                }
              });
            } catch (error) {
              
              return false;
            }

        }
    }

  return (
  
    <div id='box_register'>
      <div style={{width:"100%",height:"100%"}} >
        <div style={{background:"rgb(63,140,255)", borderRadius:20,width:"21%",height:"100%",float:"left"}}>
          <div style={{textAlign:"left", paddingLeft:20,paddingTop:30}}><img src={[require("../admin/img/logo.jpg")]} alt="" width="60%" /></div>
          <div style={{color:"white", fontSize:"1.6em",fontWeight:550, marginTop:50, marginLeft:30, textAlign:"left"}}>注册账号</div>
          <Steps style={{marginTop:50,paddingLeft:20,color:"white"}}
            direction="vertical"
            size='small'
            // progressDot
            current={0}
            items={[
              {
                title: '验证手机号',
                // status: 'finish'
              },
              {
                title: '企业入驻信息',
                // status: 'finish'
              },
              {
                title: '个人信息',
                // status: 'process'
              },
              {
                title: '提交成功',
                // status: 'wait'
              },
            ]}
          />
        </div>
        <Layout style={{background:"white", borderRadius:20,width:"77%",height:"100%",float:"right"}} >
        <Layout style={{background:"transparent"}}>
            <h1 id= 'title'>注册账号</h1>
            <Form form={form}
            className='loginForm'
            name="basic"
            // labelCol={{span: 5}}
            // wrapperCol={{span: 16}}
            initialValues={{userType:8}}
            onFinish={onFinish}
            layout="vertical"
            // onFinishFailed={this.onFinishFailed}
            autoComplete="off"
            // onValuesChange={(changedValues, allValues)=>{
            //   console.log('------------11111111111111111---------------------')
            //   console.log(changedValues)
            //   console.log(allValues)
            // }}
            // onFieldsChange={(changedFields, allFields)=>{
            //   console.log('--------------222222222222222222-------------------')
            //   console.log(changedFields)
            //   console.log(allFields)
            // }}
        >
            <Form.Item
                label="手机号"
                name="mobile"
                rules={[
                  { validator: validateMobile, trigger: 'blur' },
                  // {required: true, message: '请输入手机号',},
                  // {pattern: /^1\d{10}$/, message: '手机号格式错误'},
                ]}
            >
            <Input placeholder="请输入手机号" />
            </Form.Item>
            <Form.Item
                label="验证码"
            >
            <Form.Item
                name="code"
                rules={[
                    {required: true, message: '请输入验证码',},
                ]}
                 style={{width:"65%",float:"left",marginBottom:0}} 
            >
            <Input placeholder="请输入验证码"/>
            </Form.Item>

            <Timebutton
              mobile={mobile}
              status={mobileStatus}
              type="reg"
            />

            </Form.Item>
            <Form.Item
                label="设置密码"
                name="password"
                rules={[
                  {required: true,message: '请输入密码',},
                  {pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,message: '密码为必须包含字母和数字的6-20位字符',},
                ]}
            >
            <Input.Password  placeholder="设置密码" />
            </Form.Item>
            <Form.Item
                label="再次输入密码"
                name="password2"
                rules={[
                  { validator: validatePass, trigger: 'blur' }
              ]}
          >
            <Input.Password  placeholder="再次输入密码"/>
            </Form.Item>
            <Form.Item name="userType">
            <Radio.Group >
              <Radio value={8} >个人客户</Radio>
              <Radio value={9}>企业客户</Radio>
            </Radio.Group>
            </Form.Item>
            <Form.Item>
            <div>
            <Button type="primary" htmlType="submit" style={{padding:"0 30px"}}>
                立即注册
            </Button>
            </div>
            <div style={{marginTop:20}}><a href="/#/login">已有账号，马上登录</a></div>
            </Form.Item>
        </Form>
        </Layout>
        <Footer style={{background:"transparent",borderTop: "1px solid rgba(0, 0, 0, 0.06)"}}>
          {/* <Divider style={{width:"100%",color:"red"}} /> */}
        </Footer>
        </Layout>
        </div>

    </div>
  )
}
export default Register
