import Icon,{ LaptopOutlined, NotificationOutlined, UserOutlined,DownloadOutlined,DesktopOutlined,MenuOutlined,PictureOutlined,EllipsisOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu,Input,Select,Card,Space,Col, Row ,Button ,Table,Tabs,Dropdown,Popconfirm } from 'antd';
import React , { useRef, useState,useEffect }from 'react'
import checkLogin from "../../request/checkLogin.js"
import { ProList } from '@ant-design/pro-components';
import './index.css';

const EditSvg = () => (
  <svg t="1672495278000" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="67691" width="16" height="16"><path d="M652.4 156.6125a112.5 112.5 0 1 1 155.925 161.15625L731.375 394.71875 572.3 235.5875l79.5375-79.5375 0.5625 0.5625zM333.63125 792.40625v0.1125H174.5v-159.1875l358.03125-357.975 159.075 159.13125-357.975 357.91875zM62 849.5h900v112.5H62v-112.5z" fill="#1296db" p-id="67692"></path></svg>);
  
const EditIcon = props => (
  <Icon component={EditSvg} {...props} />
  );

const { Header, Content, Sider } = Layout;
const { TabPane } = Tabs;
const ClientSvg = () => (
  <svg t="1672205728506" class="icon" viewBox="64 64 896 896" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5427" width="1em" height="1em"><path d="M504.2 618c127.6-0.2 212.6-167.3 212.6-257.2 0-89.9-0.4-249.8-212.6-249.8S291.6 270.9 291.6 360.8s85 257.4 212.6 257.2z m361.9 126.8C812 703.3 655.8 634.3 655.8 634.3L554.4 817.9l-15-43.7 27.7-55.2-55.5-55.2-55.5 55.2 27.8 55.2L466 819l-98.6-184.6s-156.1 69-210.3 110.5C102.9 786.3 79 912.3 79 912.3h866.7s-15.8-118.6-79.6-167.5z" fill="currentColor" p-id="5428"></path></svg>
      );
const ClientIcon = props => (
    <Icon component={ClientSvg} {...props} />
    );

  const resonseData =  {
    "code": 0,
    "msg": "succeed",
    "ts": 1672299150840,
    "data": {
        "pageNo": 1,
        "pageSize": 10,
        "totalPage": 1,
        "totalRows": 7,
        "rows": [
        {
          key: '1',
          userName: 'test001',
          pkgname: 'com.xxx.xxx.kuaipan',
          num: '5',
          time: '2022-12-20 09:50:50',
          content:{
            userName: 'zhaoli01',
            status: 1,
            ctime: '2022-12-20 09:50:50',
            createUser: 'a1',
          }
        },
        {
          key: '2',
          userName: 'test002',
          pkgname: 'com.xxx.xxx.kuaipan',
          status: 0,
          num: '3',
          time: '2022-12-20 09:50:50',
          content:{
            userName: 'test002',
            status: 0,
            ctime: '2022-12-20 09:50:50',
            createUser: 'a2',
          }
        }
      ]
    }
  };
  const dataSource = resonseData.data.rows;

    const columns = [
      {
        title: 'APP名称',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '包名',
        dataIndex: 'pkgname',
        key: 'pkgname',
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: '安装数量',
        dataIndex: 'num',
        key: 'num',
      },
      {
        title: '上传时间',
        dataIndex: 'time',
        key: 'time',
      },
      {
        title: '操作',
        dataIndex: 'op',
        key: 'op',
      },
    ];
    
  
const App = props => {
  const [userInfo,setUserInfo] = useState({})

  const columsName = {
    'userName' : '用户名',
    'status' : '状态',
    'ctime' : '创建时间',
    'createUser' : '创建人',
    'op' : '操作',
  }

  const tabClicked = key => {
    console.log(key);
    for(var i=1; i<=3; i++)
    {
      var span = document.getElementById("tab_pane_"+i);
      if(i == key)
        span.className = "tab_pane_active";
      else
        span.className = "tab_pane";
    }
  };
  return (
        <Content
          className="site-layout-background"
          style={{
            padding: 10,
            margin: 0,
            minHeight: "100%",
          }}
        >
      <div className='titleClass' style={{width:100,minHeight: 30,marginBottom:10,fontSize:24,fontWeight:600,fontFamily:"PingFangSC-Regular"}}>运营管理</div>
      
      <Tabs defaultActiveKey="1" size='large' onTabClick={tabClicked} >
      <TabPane tab={<span id="tab_pane_1" className='tab_pane_active'><ClientIcon />客户管理</span>} key="1" >

        <div>

        <ProList rowKey="userId" 
        headerTitle="查看账号"
        toolBarRender={() => {
          return [
              <Button key="3" type="primary" shape="round">创建账号</Button>,
          ];
        }} 
        search={{
          filterType: 'light',
        }}
        pagination={{ defaultPageSize: 10, showSizeChanger: true, }}
        // dataSource={dataSource} 
        request={async (params = {})  => {
          // const res = await anchorList(params);
          // var flag = checkLogin(res)
          console.log('params----------------', params);
          var res = resonseData;
          var flag = true;
          if (flag) {
            return {
                data: res.data.rows,
                // current: res.data.pageNo,
                // pageSize: res.data.pageSize,
                success: true,
                // total: res.data.totalRows,
            };
          }
        }}
        metas={{
          title: {
              // dataIndex: 'name',
              title: '用户名',
          },
          status: {
            // 自己扩展的字段，主要用于筛选，不在列表中显示
            title: '状态',
            valueType: 'select',
            valueEnum: {
              0: {
                text: ('冻结'),
                status: 'Default',

              },
              1: {
                text: ('启用'),
                status: 'Success',

              },
            },
          },

          content: {
              dataIndex: 'content',
              search: false,
              render: (text) => (
                <div key="label" style={{ display: 'flex', justifyContent: 'space-around' }}>
                  {
                    Object.keys(text).map((key, index) => (
                      // console.log(key, index, text[key], columsName[key]);
                      <div key={key} style={{width:"25%"}}>
                        <div style={{ color: '#00000073' }}>{columsName[key]}</div>
                        <div style={{ color: '#000000D9',marginTop:6 }}>{text[key]}</div>
                      </div>
                    ))
                  }
                </div>
              ),
          },
          actions: {
              render: (text, data) => [
                <div>
                <div style={{ color: '#00000073',textAlign:"left" }}>操作</div>
                <div style={{ color: '#000000D9',marginTop:6 }}>
                <Space>
                <EditIcon  key="edit"
                  onClick={() => {
                    // setEdit(true);
                    // setUserInfo(data)
                    // setShowModal(true);
                  }}
                  style = {{color:'#5da3f2',margin:"0 15px 0 0"}}

                />
                <Button size="small"  key="detail" className='m-button-blue'
                onClick={() => {
                  // setUserInfo(data);
                  // userDelFun(data)
                }}
                > 修改 </Button>
                <Dropdown key="actionGroup" overlay={()=>{
                  return(
                  <Menu onClick={()=>setUserInfo(data)} style={{borderRadius:8}} >
                    <Menu.Item key="cancel" 
                      // style={{display:isShow('status:update')?'block':'none'}}
                      onClick={()=>{
                          // accountFun(recode)
                      }}>{data.status?'禁用':'启用'}</Menu.Item> 
                       <Popconfirm
                              title="确认删除该成员？"
                              onConfirm={()=>{
                                  // accountDeleteFun(data)
                              }}
                              okText="Yes"
                              cancelText="No"
                          >
                          <Menu.Item key="updateNewapp" 
                              // style={{display:isShow('del')?'block':'none'}}
                          >删除</Menu.Item> 
                      </Popconfirm>
                    
                  </Menu>
                  )}}>
                  <EllipsisOutlined  style={{color:"rgb(63,140,255)",fontSize:20}}/>
                </Dropdown>
                </Space>
                </div>
                </div>
              ],
          },
        }} 
        />

        {/* <Table dataSource={dataSource} columns={columns} />; */}

        </div>
        </TabPane>
      
      </Tabs>  

        </Content>

);
        }
export default App;