import { Space, Button, message,Modal } from 'antd';
import ProTable from '@ant-design/pro-table';
import React , { useRef, useState,useEffect }from 'react'
import checkLogin from "../../../request/checkLogin.js"
// import { ProList } from '@ant-design/pro-components';
import '../index.css';
// import { syncPhonesList,syncCloudPhones} from '../service';
import { getApiData } from '../../../request/api';
// import { EditIcon } from '../../components/icons';

const App = props => {
  const {syncPhoneShow, closeSyncPhone, resourceId, from} = props;

  const [selectedRowKeys,setSelectedRowKeys] = useState([])

  // 获取可同步手机列表
  const getPhoneList = async (params) =>{
    console.log('-----resourceId---------------', resourceId)
    var result = {};
    if(!resourceId)
      return result;
    params.pageNo = params.current;
    delete params.current;
    params.resourceId = resourceId;
    console.log('------getPhoneList---params--------', params);
    try {
      await getApiData(from, 'syncPhonesList', params).then((res) => {
        console.log(from+'-syncPhonesList-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          result = res.data.data;
          return result;
        }
      });
    } catch (error) {
      message(error);
    }
    return result;
  }

  // 同步功能
  const syncDevicesFun = async () =>{
    if(!resourceId)
     return ;

    console.log(selectedRowKeys);
    if(selectedRowKeys?.length === 0 || !selectedRowKeys) {
      message.error('请选择云手机');
      return;
    };
    try {
      return await getApiData(from, 'syncCloudPhones', {resourceId:resourceId, cloudPhoneNums:selectedRowKeys}).then((res) => {
        console.log(from+'-syncCloudPhones-res-----------------', res);
        var flag = checkLogin(res.data)
        if (flag) {
          closeSyncPhone();
          // actionRef.current.reload()
          message.success('操作成功');
        }
      });
    } catch (error) {
      return false;
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys)
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.status === 0,
      // Column configuration not to be checked
      // name: record.name,
    }),
  };

  // useEffect(() => {
  //   console.log('1111111111111111111')
  //   // 获取用户信息
    
  // }, []);

  const columns = [
    {
      title: '云手机序号',
      dataIndex: 'cloudPhoneNum',
      hideInSearch: true,
    },
    {
      title: '状态',
      dataIndex: 'deviceStatus',
      valueType: 'select',
      hideInSearch: true,
      valueEnum: {
        1: {
          text: ('空闲'),
          status: 'Processing',
        },
        2: {
          text: ('占用中'),
          status: 'Success',
        },
        // 3: {
        //   text: ('锁定'),
        //   status: 'Default',
        // },  
        4: {
          text: ('离线'),
          status: 'Default',
        },
        5: {
          text: ('重启中'),
          status: 'Warning',
        },
        7: {
          text: ('重置中'),
          status: 'Warning',
        }, 
      },

    },
    {
      title: '节点名称',
      dataIndex: 'nodeName',
      hideInSearch: true,
    },
    {
      title: '国家',
      dataIndex: 'icon',
      hideInSearch: true,
      render: (text) => <img src={text} width={30} />,
    },
  ];

  return (
    <Modal
    visible={syncPhoneShow}
    title="选择同步云手机"
    onCancel={closeSyncPhone}
    width={600}
    centered
    // style={{
    //   top: 15,
    // }}
    destroyOnClose={true}
    footer={[
      <Button key="back" onClick={closeSyncPhone}  className="m-btn-white">
        关闭
      </Button >,
      <Button key="ok" type='primary' onClick={syncDevicesFun} >
        同步
      </Button >
    ]}
>
      <ProTable
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        pagination={{ defaultPageSize: 10 }}
        rowKey="cloudPhoneNum"
        search={{
          filterType: 'light',
        }}
        size="small"
        

        // params={{ enterpriseId: enterpriseId,  resourceId: devicesDetail?.resourceId}}
        request={async (params = {}) => {
          const res = await getPhoneList(params);
          return {
            data: res.rows,
            current: res.pageNo,
            pageSize: res.pageSize,
            success: true,
            total: res.totalRows,
          };
        }}
        columns={columns}
        // options={{ density: false, fullScreen: false }}
        options={false}
      />
      
</Modal>
);
        }
export default App;