import http from "./http";
// import CryptoJs from 'crypto-js';
const api_inter = {
    'upload':{
        'tempreqdata': 'upload/temp/request/data',   // 获取文件上传临时数据
    },
    'currency':{
        'deviceStatus': 'currency/cloudphone/status/search',   // 查询云手机状态
        'customerOptions': 'currency/customer/options',   // 查询客户Options
        'switchAppShow': 'currency/switch/client/app/show',   // 开关app是否展示云手机
    },
    'stayroom':{
        'materialCreate': 'yang/material/create',   // 创建素材
        'materialList': 'yang/material/page',   // 素材列表
        'materialDelete': 'yang/material/delete',   // 删除云素材
        'syncPhonesList': 'yang/material/valid/sync/cloud/phones',    // 查询可同步云手机列表
        'syncCloudPhones': 'yang/material/sync/cloud/phone',   // 同步云手机
        'syncedCloudPhonesList': 'yang/material/synced/cloud/phones',   // 查询已同步云手机列表
        'againSyncPhone': 'yang/material/again/sync/cloud/phone',   // 重新同步
    },
    'unmroom':{
        'materialCreate': 'nobody/live/material/create',
        'materialList': 'nobody/live/material/page',
        'materialDelete': 'nobody/live/material/delete',
        'syncPhonesList': 'nobody/live/material/valid/sync/cloud/phones',    // 查询可同步云手机列表
        'syncCloudPhones': 'nobody/live/material/sync/cloud/phone',   // 同步云手机
        'syncedCloudPhonesList': 'nobody/live/material/synced/cloud/phones',// 查询已同步云手机列表
        'againSyncPhone': 'nobody/live/material/again/sync/cloud/phone',   // 重新同步
        'materialPlay': 'nobody/live/material/play',   // 素材开启播放
        'materialStop': 'nobody/live/material/stop',   // 素材终止播放
    },
    'broadcastApp':{
        'list': 'broadcast/app/list',   //查询直播舱配置应用列表
        'create': 'broadcast/app/create',   //创建直播舱配置的应用
        'edit': 'broadcast/app/edit',   //修改直播舱配置的应用
        'delete': 'broadcast/app/delete',   //删除直播舱配置的应用
        'unassociated': 'broadcast/app/unassociated/cloud/phones',   //查询可关联的云手机
        'associated': 'broadcast/app/associated/cloud/phones',   //查询已关联的云手机
        'associate': 'broadcast/app/associate/cloud/phones',   //关联云手机
        'cancelassociate': 'broadcast/app/cancel/associate/cloud/phone',   //取消关联云手机
    }
}

export const getApiData = (page, fun, data) =>{
    return http ({
        method:'POST',
        url:api_inter[page][fun],
        data
    })
}
