import { Space, Button, message, Popconfirm } from 'antd';
import React , { useRef, useState,useEffect }from 'react'
import checkLogin from "../../../request/checkLogin.js"
import { ProList } from '@ant-design/pro-components';
import '../index.css';
// import { materialCreate,materialList,materialDelete } from '../service';
import { getApiData } from '../../../request/api';
import Addmaterial from './addmaterial.jsx';
import SyncphoneModel from './syncphone.jsx';
import PhonelistModel from './resphonelist.jsx';
// import { EditIcon } from '../../components/icons';
import { buttonBlur } from '../../../utils/global.js';

const App = props => {
  const { currentUserinfo,curClientid, actionRef_2, from} = props;

  const [syncPhoneShow,setShowSyncPhone] = useState(false);
  const [phoneListShow,setphoneListShow] = useState(false);
  const [resourceId,setResourceId] = useState("");
  const [showModel,setShowModel] = useState(false);

  const closeSyncPhone = () =>{
    setShowSyncPhone(false);
  }

  const closePhoneList = () =>{
    setphoneListShow(false);
  }

  const close = () =>{
    setShowModel(false);
  }

// createDt: "2023-01-18 10:28:36"
// customerName: "ld"
// id: 11
// loginFlag: 1
// mobile: "13811111111"
// type: 8

  const columsName = {
    'name' : {
      title:'素材名称', 
      width:'25%'
    },
    'size' : {
      title:'素材大小', 
      width:'20%',
      render:(text,data)=>{
        let num =parseFloat(parseInt(text)/1024/1024).toFixed(2) +"M";
        return <span>{num||''}</span>
      }
    },
    'createDt' : {
      title:'上传时间', 
      width:'30%'
    },
    'creator' : {
      title:'上传人', 
      width:'25%'
    },
    // 'op' : '操作',
  };

  const getMaterialList = async (params) =>{
    var result = {};
    params.pageNo = params.current;
    delete params.current;
    if(curClientid)
      params.primaryAccount = curClientid;

    try {
      await getApiData(from, 'materialList', params).then((res) => {
        console.log(from+'_materialList-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          result = res.data.data;
          console.log('result-----', result);
          return result;
        }
      });
    } catch (error) {
      message(error);
    }
    return result;
  }

  // 添加素材功能
  const onSubmit = async (values,fileInfo) =>{
    if(!values['name'] || !fileInfo)
      return;
    var params = fileInfo;
    params.name = values.name;
    if(curClientid)
      params.primaryAccount = curClientid;

    try {
      await getApiData(from,'materialCreate',params).then((res) => {
        console.log(from+'_materialCreate-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          message.success("操作成功");
          actionRef_2.current.reload();
          close();
        }
        // else
        //   message.error(res.data.msg);
      });
    } catch (error) {
      message(error);
    }
  }

  // 删除素材功能
  const resourceDeleteFun = async (resourceId) =>{
    if(!resourceId)
      return;

    try {
      await getApiData(from, 'materialDelete', {resourceId:resourceId}).then((res) => {
        console.log(from+'_materialDelete-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          message.success("操作成功")
          actionRef_2.current.reload();
        }
        // else
        //   message.error(res.data.msg);
      });
    } catch (error) {
      message(error);
    }
  }

  // useEffect(() => {
    
  // }, []);

  return (
    <div>
        <ProList rowKey="resourceId" 
        actionRef={actionRef_2}
        toolBarRender={() => {
          return [
              <Button type="primary" shape="round" onClick={() => {
                if(currentUserinfo.userType==99 && !curClientid)
                  message.warning("请先选择客户")
                else
                  setShowModel(true)
                // setGroup(true)
              }}>新增素材</Button>,
          ];
        }} 
        search={{
          filterType: 'light',
        }}
        pagination={{ defaultPageSize: 10, showSizeChanger: true, }}
        // dataSource={dataSource} 
        request={async (params = {})  => {
          var tableData = [];
          if(currentUserinfo.userType!=99 || (currentUserinfo.userType==99 && curClientid)){
            console.log('params----------------', params);
            const res = await getMaterialList(params);
            console.log('res--------------', res);
            if(res.rows){
              res.rows.map((v,k)=>{
                console.log('v--------------', v);
                tableData[k] = v;
                tableData[k]['content'] = {...v};
              })
            }
              console.log('tableData--------------', tableData);
          }

          return {
              data: tableData,
              // current: res.data.pageNo,
              // pageSize: res.data.pageSize,
              success: true,
              // total: res.data.totalRows,
          };
        }}
        metas={{
          name: {
              // dataIndex: 'name',
              title: '素材名称',
          },
          // scene: {
          //   // 自己扩展的字段，主要用于筛选，不在列表中显示
          //   title: '客户类型',
          //   dataIndex: 'type',
          //   valueType: 'select',
          //   valueEnum: {
          //     8: {
          //       text: ('个人客户'),
          //       status: 'Default',

          //     },
          //     9: {
          //       text: ('企业客户'),
          //       status: 'Success',

          //     },
          //   },
          // },

          content: {
              dataIndex: 'content',
              search: false,
              render: (text) => (
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  {
                    // createDt: "2023-01-18 10:28:36"
                    // customerName: "ld"
                    // id: 11
                    // loginFlag: 1
                    // mobile: "13811111111"
                    // type: 8
                    Object.keys(columsName).map((key, index) => (
                      // console.log(key, text[key], columsName[key]),
                      // console.log(columsName[key]['valueEnum'][text[key]]),
                      // console.log(typeof(text[key])),
                      <div key={key} style={{width:columsName[key]['width']}}>
                        <div style={{ color: '#00000073' }}>{columsName[key]['title']}</div>
                        <div style={{ color: '#000000D9',marginTop:6,wordWrap:"break-word" }}>{columsName[key]['valueEnum']!=undefined ? columsName[key]['valueEnum'][text[key]]['text'] : (columsName[key]['render'] ? columsName[key]['render'](text[key]) : text[key])}</div>
                      </div>
                    ))
                  }
                </div>
              ),
          },
          actions: {
              render: (text, data) => [
                // console.log('-----------text------------------'),
                // console.log(text),
                // console.log(data),
                <div>
                <div style={{ color: '#00000073',textAlign:"left" }}>操作</div>
                <div style={{ color: '#000000D9',marginTop:6 }}>
                <Space>
                <Button size="small"  className='m-button-blue'
                onClick={(event) => {
                  console.log('-----------event---------',event)
                  buttonBlur(event);
                  setResourceId(data.resourceId);
                  setShowSyncPhone(true);
                }}
                > 同步云手机 </Button>
                <Button size="small"  className='m-button-green'
                onClick={(event) => {
                  buttonBlur(event);
                  setResourceId(data.resourceId);
                  setphoneListShow(true);
                }}
                > 云手机列表 </Button>
                <Popconfirm
                    key='delFrame'
                    title="确认删除该素材？"
                    onConfirm={()=>{
                      resourceDeleteFun(data.resourceId)
                    }}
                    okText="Yes"
                    cancelText="No"
                >
                <Button size="small"  key="del" className='m-button-red'
                    // style = {{display:isShow('del')?'block':'none'}}
                    >删除</Button>
                </Popconfirm>
                </Space>
                </div>
                </div>
              ],
          },
        }} 
        />

    <Addmaterial
        showModel = {showModel}
        curClientid = {curClientid}
        close = {close}
        edit = {false}
        onSubmit = {onSubmit}
    />

    <SyncphoneModel
        syncPhoneShow = {syncPhoneShow}
        closeSyncPhone = {closeSyncPhone}
        resourceId = {resourceId}
        from = {"unmroom"}
    />

    <PhonelistModel
        phoneListShow = {phoneListShow}
        closePhoneList = {closePhoneList}
        resourceId = {resourceId}
        from = {"unmroom"}
    />
    </div>
);
        }
export default App;