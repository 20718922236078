import http from "../../../request/http";
import CryptoJs from 'crypto-js';

export const getDeviceInfo_bc = (data) =>{
    return http ({
        method:'POST',
        url:'live/broadcast/cloudphone/info/search/'+data.cloudPhoneNum,
        data
    })
}

export const getDeviceInfo_stay = (data) =>{
    return http ({
        method:'POST',
        url:'yang/broadcast/cloudphone/info/search/'+data.cloudPhoneNum,
        data
    })
}

export const getDeviceInfo_unm = (data) =>{
    return http ({
        method:'POST',
        url:'nobody/live/broadcast/cloudphone/info/search/'+data.cloudPhoneNum,
        data
    })
}

export async function getPhoneBrand(params) {
  return http('currency/brand/options', {
    method: 'POST',
    data: { ...params },
  });
}

export async function getSimCountry(params) {
  return http('currency/sim/country/options/'+params.id, {
    method: 'POST',
    // data: { ...params },
  });
}

export async function getLanguage(params) {
  return http('currency/language/options/'+params.id, {
    method: 'POST',
    // data: { ...params },
  });
}

export async function getTimezone(params) {
  return http('currency/time/zone/options/'+params.id, {
    method: 'POST',
    data: { ...params },
  });
}
// 直播舱一键换参
export async function paramUpdate_bc(params) {
  return http('live/broadcast/cloudphone/param/update', {
    method: 'POST',
    data: { ...params },
  });
}
// 养号舱一键换参
export async function paramUpdate_stay(params) {
  return http('yang/broadcast/cloudphone/param/update', {
    method: 'POST',
    data: { ...params },
  });
}
// 无人直播舱一键换参
export async function paramUpdate_unm(params) {
  return http('nobody/live/broadcast/cloudphone/param/update', {
    method: 'POST',
    data: { ...params },
  });
}
