import { Col, Row ,Button ,Popconfirm,Modal,Divider,message,Image } from 'antd';
import React , { useRef, useState,useEffect }from 'react'
import checkLogin from "../../../request/checkLogin.js"
import { ProList } from '@ant-design/pro-components';
import './index.css';
import { getReviewedList,getReviewDetail,reviewPass,reviewNopass } from '../service';
// import { EditIcon } from '../components/icons';
import CustomProList from '../../components/CustomProList';
import { buttonBlur } from '../../../utils/global.js';

const App = props => {
  // const actionRef = useRef();
  const { actionRef} = props;

  const [loading_checklist,setChecklistloading] = useState(false);
  const [exchangParam_bc, setExModalOpen] = useState(false);
  const [clientInfo, setClientInfo] = useState({
    userId:"",
    enterpriseName:"",
    licenseNum:"",
    licenseUrl:"",
    contacts:"",
    email:"",
    mobile:"",
  });

  const showExModal = () => {
    setExModalOpen(true);
  };
  const handleExCancel = () => {
    setExModalOpen(false);
  };


  const columsName = {
    'id' : {
      title:'企业ID', 
      width:'15%'
    },
    'enterpriseName' : {
      title:'企业名称', 
      width:'15%'
    },
    'createDt' : {
      title:'创建时间', 
      width:'20%'
    },
    'reviewStatus' : {
      title:'审核状态', 
      width:'15%',
      valueEnum: {
        0: {
          text: '待审核',
          status: 'processing',
        },
        1: {
          text: '通过',
          status: 'success',
        },
        2: {
          text: '不通过',
          status: 'error',
        },
      },
    },
    'reviewedDt' : {
      title:'审核时间', 
      width:'20%'
    },
    'reviewedBy' : {
      title:'审核人', 
      width:'15%'
    },
    // 'op' : '操作',
  };

  const getCheckList = async (params) =>{
    params.pageNo = params.current;
    delete params.current;
    var result = {};
    try {
      await getReviewedList(params).then((res) => {
        // console.log('getReviewedList-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          // console.log('222222222222222222222222');
          result = res.data.data;
          // console.log('result-----', result);
          return result;
        }
        else
          message.error(res.data.msg);
      });
    } catch (error) {
      message(error);
    }
    return result;
  }
  

  const getCheckInfo = async (enterpriseId) =>{
    if(!enterpriseId)
        return;
    try {
      await getReviewDetail({enterpriseId:enterpriseId}).then((res) => {
        // console.log('getReviewDetail-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          // console.log('222222222222222222222222');
          setClientInfo(res.data.data);
          showExModal();
        }
      });
    } catch (error) {
    //   message(error);
    }
  }

  const passClient = async (userId) =>{
    if(!userId)
        return;
    try {
      await reviewPass({userId:userId}).then((res) => {
        // console.log('reviewPass-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          message.success("操作成功")
          handleExCancel();
          actionRef.current.reload();
        }
      });
    } catch (error) {
    //   message(error);
    }
  }

  const notpassClient = async (userId) =>{
    if(!userId)
        return;
    try {
      await reviewNopass({userId:userId}).then((res) => {
        // console.log('reviewNopass-res-----------------', res);
        var flag = checkLogin(res.data);
        if(flag){
          message.success("操作成功")
          handleExCancel();
          actionRef.current.reload();
        }
      });
    } catch (error) {
    //   message(error);
    }
  }
  
  return (

        <div>

        <ProList rowKey="id" 
        toolBarRender={() => {
        }} 
        search={{
          filterType: 'light',
        }}
        pagination={{ defaultPageSize: 10, showSizeChanger: true, }}
        loading={loading_checklist}
        actionRef={actionRef}
        // dataSource={dataSource} 
        request={async (params = {})  => {
          setChecklistloading(true);
          // console.log('params----------------', params);
          const res = await getCheckList(params);
          // console.log('res--------------', res);
          var tableData = [];
          if(res.rows){
            res.rows.map((v,k)=>{
              // console.log('v--------------', v);
              tableData[k] = v;
              tableData[k]['content'] = {...v};
            })
          }
        // console.log('tableData--------------', tableData);
        setChecklistloading(false);
        return {
            data: tableData,
            // current: res.data.pageNo,
            // pageSize: res.data.pageSize,
            success: true,
            // total: res.data.totalRows,
        };

        }}
        metas={{
          enterpriseName: {
              // dataIndex: 'name',
              title: '企业名称',
          },
          reviewStatus: {
            // 自己扩展的字段，主要用于筛选，不在列表中显示
            title: '审核状态',
            dataIndex: 'reviewStatus',
            valueType: 'select',
            valueEnum: {
              0: {
                text: '待审核',
                status: 'Default',
              },
              1: {
                text: '通过',
                status: 'Success',
              },
              2: {
                text: '不通过',
                status: 'Error',
              },
            },
          },

          content: {
              dataIndex: 'content',
              search: false,
              render: (text) => (
                <CustomProList 
                  columsName = {columsName}
                  text = {text}
                />
              ),
          },
          actions: {
              render: (text, data) => [
                <div>
                <div style={{ color: '#00000073',textAlign:"center" }}>操作</div>
                <div style={{ color: '#000000D9',marginTop:6 }}>
                <Button size="small"  className='m-button-blue'
                onClick={(event) => {
                  buttonBlur(event);
                  getCheckInfo(data.id)
                //   showExModal();
                }}
                > 查看详情 </Button>

                </div>
                </div>
              ],
          },
        }} 
        />

      <Modal 
        title='企业信息'
        open={exchangParam_bc}
        // style={{
        //   top: 10,
        // }}
        centered
        // width="350px"
        footer={null}
        onCancel={handleExCancel}>

        <div style={{lineHeight:"2.5em"}}>
          <div style={{fontWeight:550}}>基本信息</div>
          <Row>
            <Col span={8} className='detal_title'>单位名称</Col>
            <Col span={16}>{clientInfo.enterpriseName}</Col>
          </Row>
          <Row>
            <Col span={8} className='detal_title'>统一社会信用代码</Col>
            <Col span={16}>{clientInfo.licenseNum}</Col>
          </Row>
          <Row>
            <Col span={8} className='detal_title'>营业执照</Col>
            <Col span={16}><Image src={clientInfo.licenseUrl} width="60%" /></Col>
          </Row>
        </div>
        <Divider />
        <div style={{lineHeight:"2.5em"}}>
          <div style={{fontWeight:550}}>联系人信息</div>
          <Row>
            <Col span={8} className='detal_title'>联系人姓名</Col>
            <Col span={16}>{clientInfo.contacts}</Col>
          </Row>
          <Row>
            <Col span={8} className='detal_title'>联系人邮箱</Col>
            <Col span={16}>{clientInfo.email}</Col>
          </Row>
          <Row>
            <Col span={8} className='detal_title'>联系人手机号</Col>
            <Col span={16}>{clientInfo.mobile}</Col>
          </Row>
        </div>
        <div style={{width:"100%",margin:"30px 0 15px", textAlign:"center",display:clientInfo.reviewStatus==0?"block":"none"}}>
        <Popconfirm
            title="确认不通过吗？"
            onConfirm={()=>{
               notpassClient(clientInfo.userId);
            }}
            okText="Yes"
            cancelText="No"
        >
          <Button  shape='round'  className="m-btn-white">不通过</Button>
        </Popconfirm>
        <Popconfirm
            title="确认通过吗？"
            onConfirm={()=>{
               passClient(clientInfo.userId);
            }}
            okText="Yes"
            cancelText="No"
        >
        <Button type="primary" shape='round'  style={{ marginLeft:'50px'}} >通过</Button>
        </Popconfirm>
        </div>

    </Modal>
    </div>


);
        }
export default App;