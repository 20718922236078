import { Input,Select,Button,Modal,Form,message} from 'antd';
import React, { useEffect }  from 'react';
// import {customerAdd} from '../service';
// import checkLogin from '../../../request/checkLogin.js'

const App = (props) => {
  const {showAddclient, closAddclient, addClient} = props;
  const [form] = Form.useForm();

  // 页面初始化
  useEffect(() => {
    setTimeout(form.setFieldsValue(
      {
        mobile:"",
        password:"",
        customerName:"",
        userType:8,
      }), 100);
    
  }, [showAddclient]);

  return (
    <Modal 
        // key="changeparam"
        title={'新增客户'}
        open={showAddclient}
        width="400px"
        // style={{top:50}}
        centered
        footer={[<Button htmlType="button"  onClick={closAddclient} className="m-btn-white">
        关闭
        </Button>,
        <Button type="primary"  style={{ marginLeft:'20px'}} onClick={()=>{form.submit()}}>
        确认
        </Button>] }// 设置footer为空，去掉 取消 确定默认按钮
        onCancel={closAddclient}>   
          <Form form={form}
            labelCol={{
              span: 5,
            }}
            layout="horizontal"
            onFinish={addClient}
          >
              <Form.Item
                label="手机号"
                name="mobile"
                rules={[
                  {required: true, message: '请输入手机号',},
                  {pattern: /^1[3-9]\d{9}$/, message: '手机号格式错误'},
                ]}
            >
            <Input placeholder="请输入手机号" />
            </Form.Item>
            <Form.Item
                label="密码"
                name="password"
                rules={[
                  {required: true,message: '请输入密码',},
                  {pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,message: '密码为必须包含字母和数字的6-20位字符',},
                ]}
            >
            <Input.Password  placeholder="请输入密码" />
            </Form.Item>
            <Form.Item
                label="客户名称"
                name="customerName"
                rules={[
                  {required: true,message: '请输入客户名称',},
                ]}
            >
            <Input  placeholder="请输入客户名称" />
            </Form.Item>
            <Form.Item 
              label='客户类型'
              name="userType"
              // style={{marginBottom:10}}
            >
              <Select
                className='ant-select-selector'
                placeholder="请选择客户类型"
                options={[{label:"个人客户",value:8},{label:"企业客户",value:9}]}
              />
            </Form.Item>

          </Form>   
    </Modal>

);
}
export default App;