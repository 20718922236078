import React, { useEffect,useState } from 'react';
import { Modal, Result, Button, Form, DatePicker, Input, Select, Upload,Progress, message } from 'antd';
// import SparkMD5 from 'spark-md5';
// import axios from 'axios';
// import { checkLogin } from '@/utils/global';
// import {taskRegistration, fileMerge,filegameInfo} from '../service';
import axios from 'axios';
import { uploadTmpUrl } from '../service';
import CryptoJs from 'crypto-js';
import checkLogin from '../../../request/checkLogin.js'



const { Dragger } = Upload;
const { Option } = Select;
var open =false;

const App = (props) => {
  const [form] = Form.useForm();
  // const [appData, setAppData] = React.useState({pkgName:'',appUrl:'',version:'',versionCode:''});
  // const [fileSize, setFileSize] = React.useState('');
  // const [getPercent, setPercent] = React.useState(0);
  // const [percentShow, setpercentShow] = React.useState(false);

  const [fileInfo,setFileInfo] = useState({})
  const [percent, setpercent] = useState(0);
  const [percentShow,setPercentShow] = useState(false)

  const { showAddapp, closeAddapp, done, current, onDone, onSubmit } = props;
 
  // useEffect(() => {
  //   if (form && !showAddapp) {
  //     form.resetFields();
  //   }
  //   form.setFieldsValue({
  //     ...current,
  //   });
  // }, [props.visible]);

  // useEffect(() => {
    
  //   if (current) {
  //     form.setFieldsValue({
  //       ...current,
  //     });
  //   }
  // }, [props.current]);

  const handleSubmit = () => {
    if (!form) return;
    form.submit();
  };

  const handleFinish = (values) => {
    if (onSubmit) {
      onSubmit(values);
    }
  };

  const modalFooter = done
    ? {
      footer: null,
      closeAddapp: onDone,
    }
    : {
      okText: '保存',
      onOk: handleSubmit,
    };

     // 上传前的检验,指定上传类型，判断大小等
     const beforeUpload = (file, fileList) => {
      // console.log(file?.name)
      let str = file?.name
      // console.log(str.substring(str.lastIndexOf(".")+1))
      return new Promise((resolve, reject) => {
      if(str.substring(str.lastIndexOf(".")+1) !='apk'){
          message.error("请上传apk的文件");
          return reject(false);
        }
         
        return resolve(true);
      });
    };
    // 重点-上传方法
    const uploadFile = async (res,data) => {
      console.log('res ----------',res);
  
      var reopt = {
        method : 'PUT',
        url : res.signatureUrl,
        withCredentials: false, 
        headers : res.requestHeaders || {},
        // headers: {
        //   'content-Type': '',
        //   // 'content-Type': 'multipart/form-data ',
        // }, 
  
        validateStatus: function(status){
                return status >= 200;
        },
        maxRedirects : 0,
        responseType : 'text',
        data : data.file,
        onUploadProgress: (data) => {
            let { total, loaded } = data;
            let percent = parseInt(loaded / total * 100) > 99 ? 100 : parseInt(loaded / total * 100);
            setpercent(percent)
        }
  
      };
      console.log('reopt --------- ', reopt)
  
      axios.request(reopt).then(function (response) {
          if(response.status < 300){                     
                console.log('Creating object using temporary signature succeed.');
                form.setFieldValue("appUrl", res.fileUrl);
          }else{
            message.error("上传失败: " + response.status);
            console.log('Creating object using temporary signature failed!');
            console.log('status:' + response.status);
          }
          console.log(response.data);
          console.log('\n');
      }).catch(function (err) {
        message.error("上传失败！");
        console.log('Creating object using temporary signature failed22222222222222!');
        console.log(err);
      });
    }
  
    const upload = async (data) => {
      const file = data.file;
        setPercentShow(true)
        const { size, type, name ,lastModified} = file 
        const strfile = name+size+type+lastModified;
        // const spark = new SparkMD5()
        // spark.append(strfile)
        // const hash = spark.end() // 获取到的文件md5值
        const hash = CryptoJs.MD5(strfile).toString();
        var durl = hash+'.apk';
        console.log(durl);
  
        try {
          uploadTmpUrl({objectKey:durl}).then((res) => {
            console.log('uploadTmpUrl---------', res);
          var flag = checkLogin(res.data)
          if (flag) {
            uploadFile(res.data.data, data);
  
            // setFileInfo({size:size,resourceUrl:res.data.data.fileUrl,fileSign:hash});
            // console.log(!resourceName)
            // if(!resourceName) setresourceName(name);
          }
        });
        } catch (error) {
          // message.error(error);
        }
  
  
  
        // let lodeapp=await axios({
        //     method: 'POST',
        //     url: params.endpoint,
        //     headers: {
        //       'content-Type': 'multipart/form-data ',
        //     }, 
        //     data: fd,
        //     onUploadProgress: (data) => {
        //         let { total, loaded } = data;
        //         let percent = parseInt(loaded / total * 100) > 99 ? 100 : parseInt(loaded / total * 100);
        //         setpercent(percent)
        //     }
        // });
    }
  
      const uploadProps = {
        name: "file",
        accept: '.apk',
        beforeUpload: beforeUpload,
        multiple: false, 
        customRequest: upload,  //  重新定义上传方法
        onChange: (info) => {
          console.log('------------info-------------',info)
          if (info.file.status === "done") {
            
          } else if (info.file.status === "error") {
            message.error(`${info.file.name} 上传失败.`);
          } else if (info.file.status === "removed") {
           
          }
          
        },
      };
  
  const getModalContent = () => {
    if (done) {
      return (
        <Result
          status="success"
          title="操作成功"
          subTitle="一系列的信息描述，很短同样也可以带标点。"
          extra={
            <Button type="primary" onClick={onDone}>
              知道了
            </Button>
          }
        />
      );
    }
    const formLayout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 16,
      },
    };
    
    return (
      <Form {...formLayout} form={form} onFinish={handleFinish}
      >
        <Form.Item
          name="appName"
          label="app名称"
          rules={[
            {
              required: true,
              message: '请输入app名称',
            },
          ]}
        >
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item
          name="direction"
          label="选择横竖屏"
          rules={[
            {
              required: true,
              message: '选择横竖屏',
            },
          ]}
        >
           <Select
           placeholder='请选择'
            width="md"
            options={[
              {
                value: 0,
                label: '竖屏',
              },
              {
                value: 1,
                label: '横屏',
              },
            ]}
            allowClear={false}
          />
        </Form.Item>
        <Form.Item
          label='APK文件'
          name="appUrl"
          rules={[
            {
              required: true,
              message: '请上传APK文件',
            },
          ]}
        >  
          <Dragger   style={{width:'100px',height:'15px'}}  maxCount={1}
            {...uploadProps}
          >
            上传APk包
          </Dragger>
          <Progress  style={{display:percentShow?'block':'none',top:-40,left:110,width:"70%"}}
          percent={percent} 
          //  strokeColor={{
          //     '0%': getPercent==100?'#87d068':'#108ee9',
          //     '100%':getPercent==100?'#87d068': '#4169E1',
          // }}
      
          // format={percent => fileSize+'/'+`${percent}%`}
          // strokeWidth={6}
          />
        </Form.Item>
      </Form>
    );
  };

  return (
    <Modal
      title={done ? null : `${current ? '修改APP应用' : '新增APP应用'}`}
      forceRender = {true}
      width={500}
      centered
      onCancel={()=>{
        // setpercentShow(false);
        // setAppData({})
        open =false;
        closeAddapp()
      }}
      bodyStyle={
        done
          ? {
            padding: '72px 0',
          }
          : {
            padding: '28px 0 0',
          }
      }
      destroyOnClose={true}
      open={showAddapp}
      {...modalFooter}
    >
      {getModalContent()}
    </Modal>
  );
};

function areEqual(prevProps, nextProps) {
  /*
  如果把 nextProps 传入 render 方法的返回结果与
  将 prevProps 传入 render 方法的返回结果一致则返回 true，
  否则返回 false
  */
  if(prevProps === nextProps ) {
    return true
  }
}
export default App;


