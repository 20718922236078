import React, { useEffect,useState,useRef } from 'react';
// import SparkMD5 from 'spark-md5';
import { Modal, Button, Form, Upload, message, Progress } from 'antd';
import axios from 'axios';
import { materialValidate,materialReport } from '../service';
// import CryptoJs from 'crypto-js';
import checkLogin from '../../../request/checkLogin.js'
// import http from "../../../request/http_upload";
// import useWebsocket from '../../../utils/websocket.jsx'

let waitFlushInterHandler = undefined; //add by jerry
let waitState = false; //add by jerry
let synprogress = 0;
let randnum = 50;
let fileSize = 0;

// const ahref = window.location.href.split('/');
// console.log(ahref)
// var uploadurl = "upload";
// var wsurl = "ws";
// if(ahref[0] == 'https:'){
//   uploadurl = "uploads";
//   wsurl = "wss";
// }

const App = (props) => {
  const [form] = Form.useForm();
  const { showModel, curDeviceInfo, close, edit} = props;

  // const [obsData , setObsData] = useState()
  let verify = true;

  // const { wsData, readyState, closeWebSocket, reconnect } = useWebsocket({
  //     url: 'up_websocket', // 此参数为websocket地址
  //     verify // 此参数控制是否有权限，请求该方法
  //   })
  const ws = useRef(null);
  // const ws = useRef<WebSocket | null>(null)
  // console.log('ws:---------- ',typeof(ws), ws);
  const stateArr = [
    {key: 0, value: '正在连接中'},
    {key: 1, value: '已经连接并且可以通讯'},
    {key: 2, value: '连接正在关闭'},
    {key: 3, value: '连接已关闭或者没有连接成功'},
  ]
  //  socket 状态
  const [readyState, setReadyState] = useState({ key: 0, value: '正在连接中' })
  // socket 数据
  // const [wsData, setMessage] = useState({})

  // 进度条
  const [percent, setpercent] = useState(0);
  const [progesstext, setprogesstext] = useState("");
  const [synpercent, setsynpercent] = useState(0);
  const [synprogesstext, setsynprogesstext] = useState("");
  // const [resourceName,setresourceName] = useState(null)
  // const [fileInfo,setFileInfo] = useState({})
  const [percentShow,setPercentShow] = useState(false)
  const [synpercentShow,setSynpercentShow] = useState(false)
  const [canUpload,setCanUpload] = useState(false)
  
  useEffect(() => {
    if (!showModel) {
      // form.resetFields();
      setPercentShow(false);
      setSynpercentShow(false);
      setpercent(0);
      setprogesstext("");
      setsynpercent(0);
      setsynprogesstext("");
      ws.current?.close();
    } else{
      try {
        // var uploadurl = curDeviceInfo.uploadUrl.split('/');
        // ws.current = new WebSocket("wss://"+uploadurl[2]+"/websocket");
        ws.current = new WebSocket(curDeviceInfo.wsUrl);
        // ws.current = new WebSocket("wss://xjp.cloudctrl.kuaipantech.com:3443/websocket")
        // ws.current = new WebSocket("ws://localhost:3030/websocket")
        // console.log('ws2:---------- ',typeof(ws), ws);

        // ws.current = new WebSocket(url)
        console.log(ws.current)
        ws.current.onopen = () => {
            console.log('wb -----onopen--------')
            setReadyState(stateArr[ws.current?.readyState ?? 0])
            ws.current?.send(localStorage.getItem('token'))
            setCanUpload(true)
        }
        ws.current.onclose = () => {
            console.log('wb -----onclose--------')
            setReadyState(stateArr[ws.current?.readyState ?? 0])
        }
        ws.current.onerror = (e) => {
            console.log('wb -----onerror--------')
            console.log(e)
            setReadyState(stateArr[ws.current?.readyState ?? 0])
            message.error("服务器错误")
        }
        ws.current.onmessage = (e) => {
            // console.log('websocket -----onmessage--------', e)
            // setMessage({...JSON.parse(e.data)})
            recvSynProgress(e.data);
        }

      } catch (error) {
          console.log(error)
      }
    }

  }, [showModel]);

  // useEffect(() => {
  //   console.log('readyState -------- ', readyState)
  //   // 不在白名单人员之间不执行后续操作，不需要可以删除
  //   if (!verify) {
  //         return
  //   }
  //   // 接受到socket数据， 进行业务逻辑处理
  //   if (Object.keys(wsData).length !== 0) {
  //       console.log('wsData --------- ', wsData)
  //   }
  //   // 如果是已关闭且是当前页面自动重连
  //   if ((readyState.key === 3 || readyState.key === 0) && showModel) {
  //     console.log('reconnect!!!');
  //     reconnect()
  //   }
  //   // 不是当前页面 清空 webSocket 此处为优化代码使用的，不需要可以直接删除。
  //   if (!showModel) {
  //     console.log("closeWebSocket")
  //     closeWebSocket()
  //   }
  // }, [wsData, readyState, showModel, verify])
 

  // const handleSubmit =  async () => {
  //   const values = await form.validateFields();
  //   console.log('values ------------ ', values)
  //   onSubmit(values,fileInfo)
    
  // };

  /**
   * 定时获取设备状态
   * @returns {Promise<void>}
   */
   const startFlushStateTimer = (size) => {
    if (waitFlushInterHandler === undefined) {
      waitFlushInterHandler = setInterval(async () => {
        if (waitState) {
          clearInterval(waitFlushInterHandler);
          waitFlushInterHandler = undefined;
          waitState = false;
          synprogress = 0;
          randnum = 50;
        } else {
          // 200M需4s
          var step = parseInt(50*50*1024*1024/size);
          synprogress = synprogress + Math.floor(Math.random() * step);
          var rn = Math.floor(Math.random() * (95 - 50 + 1)) + 50;
          if(rn > randnum)
            randnum = rn;
          console.log('randnum----------', randnum)
          if(synprogress > randnum){
            synprogress = randnum;
            // waitState = true;
          }
          setsynpercent(synprogress);
        }
      }, 500);
    }
  };

  const recvSynProgress = (msg) => {
    console.log('fileSize: ', fileSize)
    // console.log('msg -------- ', typeof(msg), msg)
    var data = JSON.parse(msg);
    // console.log('data.code -------- ', data.code)
    if(data.code == 2001){
      // 文件上传完成
      // startFlushStateTimer(fileSize);
    }
    if(data.code == 2005){
      // 同步中
      setsynprogesstext("同步中...")
      var size = data.data;
      var progress = Math.ceil(100*size/fileSize);
      if(progress == 100)
        progress = 99;
      console.log('progress: ', progress)
      setsynpercent(progress);
    }
    else if(data.code == 2002){
      // 同步失败
      waitState = true;
      // synprogress = 0;
      setsynprogesstext("同步失败")
      materialReport({cloudPhoneNum:curDeviceInfo.cloudPhoneNum,status:1});
    }
    else if(data.code == 2006){
      // 同步完成
      waitState = true;
      setsynpercent(100);
      setsynprogesstext("同步成功");
      // message.success('操作成功');
      materialReport({cloudPhoneNum:curDeviceInfo.cloudPhoneNum,status:0});
    }
  }

     // 上传前的检验,指定上传类型，判断大小等
  const beforeUpload = (file, fileList) => {
    // console.log(file)
    let str = file?.name
    fileSize = file?.size
    // console.log(str.substring(str.lastIndexOf(".")+1))
    return new Promise((resolve, reject) => {
      var fileext = str.substring(str.lastIndexOf(".")+1).toLowerCase();
        
      if(fileext !=='mp4' && fileext !=='png' && fileext !=='jpeg' && fileext !=='jpg' && fileext !=='gif'){
        message.error("请上传mp4或png jpeg jpg gif的文件");
        return reject(false);
      }
      const maxsize = {
        'mp4' : {size: 1024 * 1024 * 1024 * 5, msg:"请上传5G以内的视频"},
        'gif' : {size: 1024 * 1024 * 100, msg:"请上传100M以内的动图"},
        'other' : {size: 1024 * 1024, msg:"请上传1M以内的图片"},
      }
      console.log('maxsize - ', maxsize[fileext])
      var filter = maxsize[fileext] !== undefined ? maxsize[fileext] : maxsize['other'];
      if (file?.size > filter.size) {
          message.error(filter.msg);
          return reject(false);
      }
      else
        return resolve(true);
    });
  };

    // 自定义上传操作
    const customRequest = async ({ file }) => {
      console.log('file--------', file)
      fileSize = file.size;
      setPercentShow(true);
      setSynpercentShow(false);
      setpercent(0);
      setprogesstext("");
      setsynpercent(0);
      setsynprogesstext("");
      // 验证token
      // try {
      //   await materialValidate({cloudPhoneNum:curDeviceInfo.cloudPhoneNum}).then( async (res) => {
      //     console.log('materialValidate-res-----------------', res);
      //     var flag = checkLogin(res.data);
      //     if(flag){
            // const list = cloneDeep(fileList)
            const formData = new FormData()

            // 图片资源转换成base64
            // const reader = new FileReader()
            // reader.onload = function (res) {
            //   console.log(res.target.result)
            // }
            // reader.readAsDataURL(file)
          
            formData.append('file', file)
            formData.append('cloudPhoneNum', curDeviceInfo.cloudPhoneNum);
            formData.append('innerIp', curDeviceInfo.innerIp);
            formData.append('token', localStorage.getItem('token'));

            // fetch('/upload', {
            //   method: 'POST',
            //   body: formData,
            // })
            axios.defaults.headers={'Access-Control-Allow-Origin':'*',}
            axios.post(
              curDeviceInfo.uploadUrl,
              // "https://xjp.cloudctrl.kuaipantech.com:3443/clouddvs/upload/",
              // "/upload",
              formData,
              {
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                onUploadProgress: (data) => {
                  // console.log('progress - data --------- ', data);
                  let { total, loaded } = data;
                  if (data.event.lengthComputable) {
                    let percent = parseInt(loaded / total * 100) > 99 ? 100 : parseInt(loaded / total * 100);
                    if(progesstext==='上传失败')
                      setpercent(0);
                    else
                      setpercent(percent);
                    // console.log('percent 2 - ',percent)
                    if(percent>0 && percent<100)
                      setprogesstext("上传中...")
                    else if(percent===100){
                      setprogesstext("上传成功")
                      setSynpercentShow(true);
                      // startFlushStateTimer(file.size);
                      setsynprogesstext("开始同步")
                    }
                  }
                }
              })
            .then((res2) => {
              // setFileList([]);
              console.log('res2 ---------- ', res2)
              var flag = checkLogin(res2.data);
              if(flag){
                // waitState = true;
                // setsynpercent(100);
                // setsynprogesstext("同步成功");
                // message.success('操作成功');
              }
              else if(res2.data.code === 4001){
                waitState = true;
                // synprogress = 0;
                setsynprogesstext("同步失败")
                // message.error(res2.data.msg);
                materialReport({cloudPhoneNum:curDeviceInfo.cloudPhoneNum,status:1});
              }
            })
            .catch((error) => {
              console.log(error)
              // setPercentShow(false);
              setSynpercentShow(false);
              // setpercent(0);
              setprogesstext("上传失败");
              setsynpercent(0);
              setsynprogesstext("");
              waitState = true;
              message.error('操作失败！- '+error.response.data.error);
              materialReport({cloudPhoneNum:curDeviceInfo.cloudPhoneNum,status:1});
            })
            .finally(() => {
              // setUploading(false);
            });
    }

    const uploadProps = {
      // action: '/upload',
      customRequest: customRequest,
      accept: '.mp4, .png, .jpg, .jpeg, .gif',
      beforeUpload: beforeUpload,
      onChange({ file, fileList }) {
          // console.log(file, fileList);
        if (file.status !== 'uploading') {
        }
      },
      // progress: {
      //   strokeColor: {
      //     '0%': '#108ee9',
      //     '100%': '#87d068',
      //   },
      //   strokeWidth: 5,
      //   format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
      // },
      // defaultFileList: [],
      // showUploadList: {
      //   showDownloadIcon: true,
      //   downloadIcon: 'Download',
      //   // showRemoveIcon: true,
      //   // removeIcon: <StarOutlined onClick={(e) => console.log(e, 'custom removeIcon event')} />,
      // },
    };

  const getModalContent = () => {
  
    return (
      <Form {...formLayout} form={form} >
        {/* <Form.Item
          name="name"
          label="素材名称"
          rules={[
            {
              required: true,
              message: '请输入素材名称',
            },
          ]}
          
        >
          <Input />
        </Form.Item> */}
        <Form.Item
          name="uploadresult"
          label="上传素材"
          rules={[
            {
              required: true,
              message: '请上传素材',
            },
          ]}
        >
          <Upload {...uploadProps}>
            <Button className='m-button-blue' disabled={!canUpload}>上传</Button>
          </Upload>
          <Progress percent={percent} style={{width:'60%',top:-30,left:80,display:percentShow?'block':'none'}}/>
          <div style={{color:progesstext==="上传失败"?"red":"rgb(63,140,255)",width:'15%',marginTop:-48,float:"right",fontSize:"10px",display:percentShow?'block':'none'}}>{progesstext}</div>
          <Progress percent={synpercent} style={{width:'60%',top:-20,left:80,display:synpercentShow?'block':'none'}}/>
          <div style={{color:synprogesstext==="同步失败"?"red":"rgb(63,140,255)",width:'15%',marginTop:-40,float:"right",fontSize:"10px",display:synpercentShow?'block':'none'}}>{synprogesstext}</div>
          <div style={{color:'#ccc',fontSize:'12px',lineHeight: '20px',paddingTop:10,width:"110%"}}>提示：视频素材只支持mp4格式，单次上传大小不超过5G。</div>
          <div style={{color:'#ccc',fontSize:'12px',lineHeight: '20px',paddingTop:10,width:"110%",marginLeft:"3em"}}>图片素材支持png jpeg jpg gif格式；</div>
          <div style={{color:'#ccc',fontSize:'12px',lineHeight: '20px',paddingTop:10,width:"110%",marginLeft:"3em"}}>单张图片大小不超过1M，单张动图大小不超过100M。</div>

        </Form.Item>
       
      </Form>
    );
  };

  const formLayout = {
    labelCol: {
      span: 4,
    },
  
    wrapperCol: {
      span: 20,
    },
  };
  
  // const modalFooter = {
  //   // okText: edit ? '同步' : '保存',
  //   okButtonProps: {display:"none"},
  //   cancelText: '关闭',
  //   // onOk: handleSubmit,
  // };

  return (
    <div>
      <Modal
        forceRender={true}
        title={edit ? '同步素材' : '新增素材'}
        width={500}
        centered
        destroyOnClose
        open={showModel}
        maskClosable={false}
        // {...modalFooter}
        footer={[
          <Button className="m-btn-white" onClick={close}>
            关闭
          </Button>
        ]}
        onCancel={close}

      >
        {getModalContent()}
      </Modal>
       
    </div>
  );
};

export default App;
