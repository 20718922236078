import http from "../../request/http";
import CryptoJs from 'crypto-js';

// 操作记录列表
export const operateLog = (data) =>{
    return http ({
        method:'POST',
        url:'sys/operate/log/page',
        data
    })
}
